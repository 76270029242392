import { isNotAuthorized, isNotSomeAuthorization } from './util/role.utils';

export default [
  {
    name: 'Visão geral',
    icon: 'nc-icon nc-layout-11',
    path: '/overview',
    disabled: false
  },
  {
    name: 'Transações',
    icon: 'fas fa-exchange-alt',
    disabled: false,
    collapsed: false,
    children: [
      {
        name: 'Pagamentos de Tít. e Conv.',
        icon: 'fas fa-barcode',
        path: '/payment/pay',
        disabled: false,
      },
      {
        name: 'Transf. entre contas',
        icon: 'fas fa-exchange-alt',
        path: '/transfer/tev',
        disabled: false,
      },
      {
        name: 'Depósito por Boleto',
        icon: 'fas fa-file-invoice-dollar',
        path: '/deposit/boleto',
        disabled: false,
      },
      {
        name: 'Recebimento via PIX',
        icon: 'fas fa-arrow-up',
        path: '/pix/depositpix',
        disabled: false,
      },
      {
        name: 'Transferência via PIX',
        icon: 'fas fa-arrow-up',
        path: '/pix/paymentpix',
        disabled: false,
      },
    ]
  },
  {
    name: 'Relatórios',
    icon: 'fas fa-chart-line',
    disabled: false,
    collapsed: false,
    children: [
      {
        name: 'Extrato',
        icon: 'nc-icon nc-paper',
        path: '/transactions/byperiod',
        disabled: false
      },
      {
        name: 'Pix de Entrada Gateway',
        icon: 'nc-icon nc-paper',
        path: '/transactions/pix/in',
        disabled: true
      },
      {
        name: 'Pix de Saída Gateway',
        icon: 'nc-icon nc-paper',
        path: '/transactions/pix/out',
        disabled: true
      },
      {
        name: 'Webhook',
        icon: 'nc-icon nc-paper',
        path: '/transactions/webhook',
        disabled: true
      },
      {
        name: 'Parceiro Pix',
        path: '/transactions/pixpartner',
        disabled: true
      }
    ]
  },
  {
    name: 'Máquina de Cartão',
    icon: 'fas fa-calculator',
    path: '/maintenance/maintenance/requestmachine',
    collapsed: false,
    disabled: true, //isNotSomeAuthorization('CARD_TERMINAL_REQUEST', 'CARD_TERMINAL_REQUESTED_LIST', 'CARD_TERMINAL_USER_LIST'),
    children: [
      {
        name: 'Realizar Pedido',
        path: '/card-terminal/request',
        disabled: false,//isNotAuthorized('CARD_TERMINAL_REQUEST')
      },
      {
        name: 'Consultar Meu Plano',
        path: '/card-terminal/consultplan',
        disabled: false
      },
      {
        name: 'Relatório Financeiro',
        path: '/card-terminal/consulttransactions',
        disabled: false
      }
    ]
  },
  {
    name: 'Habilitar Aplicativo',
    icon: 'fa-solid fa-mobile-screen-button',
    path: '/user/device',
    disabled: isNotSomeAuthorization(['REGISTER_DEVICE'])
  },
  {
    name: 'Suporte',
    icon: 'fa-solid fa-circle-info',
    path: '/support/support',
    disabled: true
  },
  {
    name: 'Verificar Conta',
    icon: 'nc-icon nc-single-02',
    path: '/user/profile',
    disabled: localStorage.getItem('docsOk') == 'true'
  },
  {
    name: 'BO. Cadastros',
    icon: 'nc-icon nc-briefcase-24',
    disabled: isNotSomeAuthorization(['BO_LIST_ACCOUNT']),
    children: [
      {
        name: 'Contas',
        path: '/bo/account/search/legal',
        disabled: isNotAuthorized('BO_LIST_ACCOUNT')
      },
      {
        name: 'Extrato',
        path: '/bo/statement/search',
        disabled: isNotAuthorized('BO_LIST_STATEMENT')
      }
    ]
  },
  {
    name: 'BO. ROLES',
    icon: 'fas fa-user-lock',
    disabled: isNotSomeAuthorization(['BO_ROLES']),
    children: [
      {
        name: 'Inserir Role',
        path: '/bo/roles/registerrole',
        disabled: isNotAuthorized('INSERT_ROLE')
      },
      {
        name: 'Inserir Pack',
        path: '/bo/roles/registerrolepack',
        disabled: isNotAuthorized('INSERT_ROLE_PACK')
      },
      {
        name: 'Gerenciar Usuários',
        path: '/bo/roles/manageusers',
        disabled: isNotAuthorized('MANAGE_USERS_ROLE')
      },
      {
        name: 'Gerenciar Packs',
        path: '/bo/roles/managepacks',
        disabled: isNotAuthorized('MANAGE_PACKS_ROLE')
      },
      {
        name: 'Gerenciar Roles',
        path: '/bo/roles/manageroles',
        disabled: isNotAuthorized('MANAGE_ROLES')
      }
    ]
  },
  {
    name: 'BACKOFFICE',
    icon: 'nc-icon nc-atom',
    disabled: isNotSomeAuthorization(['BO_LIST_GLOBAL_OP_LIMITS']),
    children: [
      {
        name: 'Limites Globais',
        path: '/bo/operation-limit/edit',
        disabled: isNotAuthorized('BO_LIST_GLOBAL_OP_LIMITS')
      },
      {
        name: 'Recargas Via TED',
        path: '/bo/credit-ted/manageCreditTed',
        disabled: isNotAuthorized('BO_GET_LIST_CREDIT_TED')
      }
    ]
  },
  {
    name: 'BO. MAQ. CARTAO',
    icon: 'fas fa-calculator',
    disabled: isNotSomeAuthorization(['BO_CARD_TERMINALS']),
    children: [
      {
        name: 'Gerenciar Solicitações',
        path: '/bo/cardterminal/managerequest',
        disabled: isNotAuthorized('BO_MANAGE_REQ_CARD_TERMINALS')
      },
      {
        name: 'Inserir Máquina',
        path: '/bo/cardterminal/registercardterminal',
        disabled: isNotAuthorized('BO_UPDATE_CARD_TERMINAL')
      },
      {
        name: 'Gerenciar Máquinas',
        path: '/bo/cardterminal/managecardterminal',
        disabled: isNotAuthorized('BO_UPDATE_CARD_TERMINAL')
      },
      {
        name: 'Gerenciar Marcas',
        path: '/bo/cardterminal/managebrands',
        disabled: isNotAuthorized('BO_UPDATE_CARD_TERMINAL_BRAND')
      },
      {
        name: 'Gerenciar Modelos',
        path: '/bo/cardterminal/managemodels',
        disabled: isNotAuthorized('BO_UPDATE_CARD_TERMINAL_MODEL')
      },
      {
        name: 'Gerenciar Planos',
        path: '/bo/cardterminal/manageplans',
        disabled: isNotAuthorized('BO_UPDATE_CARD_TERMINAL_PLANS')
      },
      {
        name: 'Gerenciar Bandeiras',
        path: '/bo/cardterminal/managebanner',
        disabled: isNotAuthorized('BO_UPDATE_CARD_TERMINAL_BANNER')
      }
    ]
  },
  {
    name: 'BO. FINANCEIRO',
    icon: 'fas fa-chart-bar',
    disabled: isNotSomeAuthorization(['BO_FINANCIAL', 'BO_COMMERCIAL_FINANCIAL', 'BO_FINANCIAL_ONLINE']),
    children: [
      {
        name: 'Vendas Online Sintético',
        path: '/bo/financial/online/useronlinereport',
        disabled: isNotSomeAuthorization(['BO_FINANCIAL_ONLINE', 'BO_COMMERCIAL_FINANCIAL'])
      },
      {
        name: 'Vendas Online Analítico',
        path: '/bo/financial/online/salesReport',
        disabled: isNotSomeAuthorization(['BO_COMMERCIAL_FINANCIAL', 'BO_FINANCIAL_ONLINE'])
      },
      {
        name: 'Vendas Máq. Usuário',
        path: '/bo/cardterminal/financial/userreport',
        disabled: isNotAuthorized('BO_CARD_TERM_USER_REPORT')
      },
      {
        name: 'Relatório de Lucros',
        path: '/bo/financial/profitreport',
        disabled: isNotAuthorized('BO_PROFIT_REPORT')
      },
      {
        name: 'Recarga Manual de PIX',
        path: '/bo/financial/pixcharge',
        disabled: isNotAuthorized('BO_PIX_CHARGE')
      },
      {
        name: 'Transf. PIX Celcoin',
        path: '/bo/financial/pixtransferinternal',
        disabled: isNotAuthorized('BO_PIX_CHARGE')
      }
    ]
  },
  {
    name: 'BO. PAGAMENTOS',
    icon: 'fas fa-money',
    disabled: isNotSomeAuthorization(['BO_DISTRIBUTED_VALUES']),
    children: [
      {
        name: 'Distribuir Máquinas',
        path: '/bo/cardterminal/distributevalues',
        disabled: isNotAuthorized('BO_DISTRIBUTED_VALUES')
      },
    ]
  },
  {
    name: 'BO. COMERCIAL',
    icon: 'fa-solid fa-briefcase',
    disabled: isNotSomeAuthorization(['BO_COMMERCIAL']),
    children: [
      {
        name: 'Gerenciar Vendedores',
        path: '/bo/commercial/manage',
        disabled: isNotAuthorized('BO_COMMERCIAL_SALES_PERSON')
      },
      {
        name: 'Gerenciar Gestores',
        path: '/bo/commercial/manager/manage',
        disabled: isNotAuthorized('BO_COMMERCIAL_ADD_MANAGER')
      },
      {
        name: 'Gerenciar Regiões',
        path: '/bo/commercial/region/manage',
        disabled: isNotAuthorized('BO_COMMERCIAL_ADD_REGION')
      },
      {
        name: 'Gerenciar Níveis',
        path: '/bo/commercial/level/manage',
        disabled: isNotAuthorized('BO_COMMERCIAL_ADD_LEVEL')
      },
      {
        name: 'Gerenciar Carteira',
        path: '/bo/commercial/comercialportifolio',
        disabled: isNotAuthorized('BO_COMMERCIAL_SALES_PERSON')
      },
      {
        name: 'Dashboard',
        path: '/bo/commercial/dashboard',
        disabled: isNotAuthorized('BO_COMMERCIAL_EDIT_CLIENT')
      },
      {
        name: 'Relatório de Pagamentos',
        path: '/bo/commercial/commissions',
        disabled: isNotAuthorized('BO_COMMERCIAL_COMMISSIONS')
      },
      {
        name: 'Relatório de Vendas Analítico',
        path: '/bo/commercial/salesreport',
        disabled: isNotAuthorized('BO_COMMERCIAL_SALES_REPORT')
      },
      {
        name: 'Relatório de Vendas Sintético',
        path: '/bo/commercial/useronlinereport',
        disabled: isNotAuthorized('BO_COMMERCIAL_SALES_REPORT')
      }
    ]
  },
  {
    name: 'BO. Aarin',
    icon: 'fa-solid fa-user-check',
    disabled: isNotSomeAuthorization(['BO_AARIN']),
    children: [
      {
        name: 'Cadastros',
        path: '/bo/aarin',
        disabled: isNotAuthorized('BO_AARIN')
      }
    ]
  },
]
