<template>
  <navbar v-model="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-icon btn-round" @click="minimizeSidebar">
          <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" style="color:#e1bb80;"></i>
          <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" style="color:#e1bb80;"></i>
        </button>
      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button @click.native="toggleSidebar">

        </navbar-toggle-button>
      </div>
      <a class="navbar-brand" style="color:#e1bb80;" href="/"><b v-if="this.name"> |</b> {{this.nickname()}}</a>
    </div>

    <template slot="navbar-menu">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link btn-magnify" title="Visão Geral"
             @click="$router.push('/')" href="#">
            <i class="nc-icon nc-layout-11" style="color:#e1bb80;"></i>
            <p>
              <span class="d-lg-none d-md-block">Visão Geral</span>
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn-magnify" title="Configurar Perfil"
             @click="$router.push('/user/profile')" href="#">
            <i class="fas fa-cogs" style="color:#e1bb80;"></i>
            <p>
              <span class="d-lg-none d-md-block">Configurar Perfil</span>
            </p>
          </a>
        </li>
        <drop-down icon="nc-icon nc-bell-55" tag="li"
                   position="right"
                   direction="none"
                   class="nav-item btn-rotate dropdown notification"
                   :hide-on-click="false"
                   ref="notif">
          <a slot="title"
             title="Notificações"
             slot-scope="{isOpen}"
             class="nav-link dropdown-toggle"
             data-toggle="dropdown"
             aria-haspopup="true"
             :aria-expanded="isOpen"
             @click="clickNotification">
            <i :class="'nc-icon nc-bell-55 ' + (notReadNotificationCount>0 ? 'text-danger' : '')" style="color:#e1bb80;"></i>
            <span v-if="notReadNotificationCount > 0" class="badge"> {{notReadNotificationCount}}</span>
            <p>
              <span class="d-lg-none d-md-block">Notificações</span>
            </p>
          </a>
          <div style="background-color: #eff0ea;border: 10px solid #eff0ea;border-radius: 10px;">
            <h6>Notificações</h6>
            <div class="card card-stats" :style="item.notificationStatus === 'READ' ? 'background-color: whitesmoke' : ''" v-for="(item, index) in notificationsItems">
              <div class="row">
                <div class="timeline-heading col" style="margin: 15px 0px 0px 10px;">
                  <span :class="'p-badge badge-pill ' + (item.notificationMsgType === 'WARNING' ? 'badge-warning' : 'badge-primary')">
                    <b>{{item.messageTitle}}</b>
                  </span>
                </div>
                <div class="col-3" style="float: left !important;">
                  <p-button v-on:click="deleteNotificationWs(item, index)"
                            aria-label="add button" style="margin-right: 10px;float: right;"
                            type="danger" round icon size="5x">
                    <i class="far fa-trash-alt"></i>
                  </p-button>
                </div>
              </div>
              <div class="timeline-body" style="margin: 3px 10px 10px 10px;">
                <p class="" style="width: 35em;white-space: inherit;font-size: 12px;" :value="item">{{item.content}}</p>
              </div>
              <div class="row">
                <div class="timeline-heading col" style="margin: 10px 10px 10px 10px">
                  <h6 slot="footer" style="font-size: 13px;color: #9a9a9a;">
                    <i class="fas fa-clock-o"></i> {{item.notificationDate}}
                  </h6>
                </div>
                <div class="col" style="margin: 5px 15px 0px 0px;" v-if="item.linkNotification != null">
                  <a style="float: right; !important;" :href="item.linkNotification" target="_blank">Ver Mais...</a>
                </div>
              </div>
            </div>
            <div class="card card-stats" v-if="notificationsItems.length == 0">
              <div class="timeline-body" style="margin: 10px 10px 10px 10px;">
                <p class="" style="width: 35em;white-space: inherit;font-size: 12px;">Não existem notificações.</p>
              </div>
            </div>
          </div>
          <div class="timeline-body pagination-info" style="margin: 3px 10px 10px 10px;">
            <p style="width: 35em;white-space: inherit;font-size: 12px;">EXIBINDO DO {{paginationNotif.fromNumber}} AO {{paginationNotif.toNumber}}, DE {{paginationNotif.totalNumber}} REGISTROS.</p>
          </div>
          <div class="timeline-body" style="margin: 3px 10px 10px 10px;">
            <p-pagination class="pull-right" v-model="paginationNotif.currentPage" :per-page="paginationNotif.perPage"
                          :total="paginationNotif.totalNumber" :click="this.changeNotificationPage">
            </p-pagination>
          </div>
        </drop-down>
        <li class="nav-item">
          <a class="nav-link btn-magnify" title="Sair" href="" @click="logout">
            <i class="fas fa-sign-out-alt fa-lg" style="color:#e1bb80;"></i>
            <p style="margin-left: 5px">
              <span class="d-lg-none d-md-block">Sair</span>
            </p>
          </a>
        </li>
      </ul>
    </template>
  </navbar>
</template>
<script>
import {Navbar, NavbarToggleButton, StatsCard} from 'src/components/UIComponents'
  import {isNullOrEmpty} from "src/util/core.utils";
  import {callWs, failWs} from "../../../ws.service";
  import PPagination from 'src/components/UIComponents/Pagination.vue'

  export default {
    components: {
      Navbar,
      NavbarToggleButton,
      PPagination
    },
    data() {
      return {
        name: "",
        activeNotifications: false,
        showNavbar: false,
        notificationsItems: [],
        idNotificationMsgAux: "",
        notReadNotificationCount: 0,
        paginationNotif: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
      }
    },
    mounted() {
      this.name = localStorage.getItem("name")
      if (this.name == null || this.name == 'null') {
        this.name = ''
      }
      this.callNotificationWs()
    },
    methods: {
      nickname() {
        if (isNullOrEmpty(this.name)) {
          return ''
        }
        let split = this.name.split(' ')
        if (split.length == 1) {
          return split[0]
        }
        return split[0] + ' ' + split[split.length - 1]
      },
      logout() {
        localStorage.clear()
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown() {
        this.activeNotifications = false
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false)
      },
      minimizeSidebar() {
        this.$sidebar.toggleMinimize()
      },
      toggleNavbar() {
        this.showNavbar = !this.showNavbar
      },
      changeNotificationPage(){
        this.callNotificationWs(true)
      },
      callNotificationWs(forceDropDown) {
        callWs("/notification/get-notification",
          "POST", null, true, null,
          {notificationStatus: null,
            notificationMsgType: null,
            pageNumber: (this.paginationNotif.currentPage - 1),
            pageSize: this.paginationNotif.perPage},
          response => this.loadNotifications(response,forceDropDown),
          this.failNotification)
      },
      failNotification(error) {
        return
      },
      loadNotifications(response, forceDropDown) {
        let notifications = response.data.notifications
        if (notifications.length <= 0) {
          return
        }
        this.notReadNotificationCount = 0
        notifications.forEach(n => {
           if(n.notificationStatus === 'NOT_READ'){
             this.notReadNotificationCount++
           }
        })
        this.notificationsItems = notifications
        this.activeNotifications = true

        this.paginationNotif.totalNumber = response.data.totalNumber
        this.paginationNotif.pageMaxNumber = response.data.pageMaxNumber
        if (this.paginationNotif.totalNumber > 0) {
          this.paginationNotif.fromNumber = ((this.paginationNotif.perPage * (this.paginationNotif.currentPage - 1)) + 1)
          this.paginationNotif.toNumber = ((this.paginationNotif.fromNumber + notifications.length) - 1)
        } else {
          this.paginationNotif.fromNumber = 0
          this.paginationNotif.toNumber = 0
        }

        if(forceDropDown){
          // console.log(this.$refs)
          this.$refs.notif.isOpen = true
          // this.$refs.notif.$emit('change', true)
        }
      },
      deleteNotificationWs(event, index) {
        this.notificationsItems.splice(index, 1)
        this.updateNotificationsList(event.id, "IGNORE")
      },
      clickNotification(){
        this.updateNotificationRead()
        this.callNotificationWs()
      },
      updateNotificationRead(){
        this.notificationsItems.forEach(value => {
          this.idNotificationMsgAux = ""
          if(value.id == null){
            this.insertNotificationsGlobal(value)
          } else if(value.notificationStatus != 'READ'){
            this.updateNotificationsList(value.id, "READ")
          } else {
            return
          }
        });
      },
      updateNotificationsList(id, status){
        callWs("/notification/update-notification",
          "POST", null, true, null,
          {idNotification: id, notificationStatus: status, pushStatus: null},
          this.updateNotificationOk,
          null)
      },
      insertNotificationsGlobal(value){
        callWs("/notification/insert-global-notification",
          "POST", null, true, null,
          {idNotificationMsg: value.notificationMsgId, expirationDate: value.expirationDate},
          response => {this.insertNotificationOk(response, value)},
          null)
      },
      insertNotificationOk(response, value){
        value.id = response.data
        value.notificationStatus = "READ"
      },
      updateNotificationOk(response){
        return
      }
    }
  }

</script>
<style scoped>
  .notification {
    position: relative;
    display: inline-block;
  }

  .notification .badge {
    position: absolute;
    top: -2px;
    right: 35px;
    padding: 3px 6px;
    border-radius: 50%;
    background: red;
    color: white;
  }
</style>
