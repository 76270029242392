<template>
  <div class="dzs-transactions">
    <div class="row">
      <div class="col-md-12">
        <card>
          <h4 slot="header" class="card-title">
            Pix de Saída Gateway por Período
            <span v-if="isBackoffice">(<span v-if="username != null" style="font-weight: bolder"> @{{ username }}</span> )</span>
          </h4>
          <div class="row">
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Início</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsStart" @input="validatePeriod"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Fim</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsEnd"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <fg-input type="text" label="ID da transação" v-model="idTransaction"></fg-input>
            </div>
            <div class="col-md-2">
              <fg-input type="text" label="Chave do Pix" v-model="keyPix"></fg-input>
            </div>
            <div class="col-md-1">
              <fg-input label="Exibir">
                <el-select
                  class="select-default"
                  v-model="pagination.perPage"
                  placeholder="Por Página">
                  <el-option
                    class="select-default"
                    v-for="item in [50, 100, 200]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <p-button @click.prevent="search" style="margin-top: 24px">
                <i class="fas fa-search-dollar"></i> BUSCAR
              </p-button>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12">
        <div class="row">

          <div class="col">
            <div class="card card-stats">
              <div class="card-body">
                <div class="row">
                  <div class="col-5 col-md-4">
                    <div class="icon-big text-center text-primary">
                      <i class="fa-brands fa-pix"></i>
                    </div>
                  </div>
                  <div class="col-7 col-md-8">
                    <div class="numbers">
                      <p class="card-category ">Número de Pix Criados</p>
                      <p class="card-title ">{{ this.numberPix }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <hr>
                <div class="stats" style="color: rgb(12, 7, 45); font-size: 13px;"> Valor Total: R$
                  {{ toMoney(this.valuePix) }}
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card card-stats">
              <div class="card-body">
                <div class="row">
                  <div class="col-5 col-md-4">
                    <div class="icon-big text-center text-primary">
                      <i class="fa-solid fa-circle-check"></i>
                    </div>
                  </div>
                  <div class="col-7 col-md-8">
                    <div class="numbers">
                      <p class="card-category ">Número de Pix Processados</p>
                      <p class="card-title ">{{ this.numberPixProcessed }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <hr>
                <div class="stats" style="color: rgb(12, 7, 45); font-size: 13px;"> Valor Total: R$
                  {{ toMoney(this.valuePixProcessed) }}
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card card-stats">
              <div class="card-body">
                <div class="row">
                  <div class="col-5 col-md-4">
                    <div class="icon-big text-center text-primary">
                      <i class="fa-solid fa-circle-exclamation"></i>
                    </div>
                  </div>
                  <div class="col-7 col-md-8">
                    <div class="numbers">
                      <p class="card-category ">Número de Pix Estornados</p>
                      <p class="card-title ">{{ this.numberPixChargeback }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <hr>
                <div class="stats" style="color: rgb(12, 7, 45); font-size: 13px;"> Valor Total: R$
                  {{ toMoney(this.valuePixChargeback) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" v-if="timelineItems.length>0"
           style="margin: 0 auto">
        <card>
          <div class="card-body row">
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="timelineItems"
                        style="width: 100%">
                <el-table-column :min-width="80" label="ID da Transação">
                  <template slot-scope="item">
                    <span>
                      <b>{{ item.row.id }}</b>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="30" label="Valor (R$)">
                  <template slot-scope="item">
                    <span>
                      <span>
                        R$ {{ toMoney(item.row.value) }}
                      </span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="30" label="Status">
                  <template slot-scope="item">
                    <span>
                      {{ item.row.status }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="40" label="Realizado Em">
                  <template slot-scope="item">
                    <span>
                      <span>
                        {{ item.row.createdDate }}
                      </span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="50" label="Destinatário">
                  <template slot-scope="item">
                    <span>
                      <span>
                        {{ item.row.destinationAccountOwnerName }} - {{ item.row.destinationAccountOwnerSocialNumber }}
                      </span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="30" label="IP do Gateway">
                  <template slot-scope="item">
                    <span>
                      <span>
                        {{ item.row.ipGateway }}
                      </span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="30" label="">
                  <template slot-scope="item">
                    <span>
                      <span>
                        <p-button @click="showPayerData(item.row)"
                                  class="btn btn-info" title="Ver código Pix">
                          <i class="fas fa-file-invoice fa-2x" style="color: #0c072d;"/>
                        </p-button>
                      </span>
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-success">
              <p class="category">EXIBINDO DO {{ pagination.fromNumber }} AO {{ pagination.toNumber }}, DE
                {{ pagination.totalNumber }} REGISTROS.</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.totalNumber"
                            :click="this.search">
              </p-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {DatePicker} from 'element-ui'
import {Badge, TimeLine, TimeLineItem} from 'src/components/UIComponents'
import {callWs} from "src/ws.service"
import {formatDate, daysBetween} from "src/util/date.utils"
import {toMoney} from 'src/util/core.utils'
import swal from 'sweetalert2'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import {isNullOrEmpty} from "../../../../util/core.utils";
import ChartCard from "@/components/UIComponents/Cards/ChartCard";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    TimeLine,
    TimeLineItem,
    Badge,
    PPagination,
    ChartCard
  },
  data() {
    return {
      startDate: new Date(),
      endDate: new Date(),
      timelineItems: [],
      username: null,
      isBackoffice: false,
      idTransaction: null,
      keyPix: null,
      dateOptsStart: {
        disabledDate: this.disabledStartDate,
        shortcuts: [{
          text: 'Hoje',
          onClick(picker) {
            const date = new Date()
            picker.$emit('pick', date)
            this.endDate = date
          }
        },
          {
            text: 'Ontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
          {
            text: 'Anteontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 48)
              picker.$emit('pick', date)
              this.endDate = date
            }
          }]
      },
      dateOptsEnd: {
        disabledDate: this.disabledEndDate
      },
      pagination: {
        perPage: 50,
        currentPage: 1,
        pageMaxNumber: 1,
        fromNumber: 0,
        toNumber: 0,
        totalNumber: 0
      },
      totalNumber: 0,
      numberPix: 0,
      numberPixProcessed: 0,
      numberPixChargeback: 0,
      valuePix: 0,
      valuePixProcessed: 0,
      valuePixChargeback: 0
    }
  },
  created() {
    if (isNullOrEmpty(this.$route.params.username)) {
      this.isBackoffice = false
      this.username = localStorage.getItem("usernameDisplay")
    } else {
      this.isBackoffice = true
      this.username = this.$route.params.username
    }
  },
  computed: {
    chartData: function () {
      return {
        labels: ['Pix Não Pagos (%)', 'Pix Pagos (%)'],
        datasets: [{
          label: "Pix Pagos",
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: ['#f69090', '#6bd098'],
          borderWidth: 0,
          data: [this.calculatePercent(this.numberOfPixCreated, this.numberOfPix), this.calculatePercent(this.numberOfPixPaid, this.numberOfPix)]
        }],
      }
    }
  },
  methods: {
    toMoney,
    calculatePercent(value, relativeOf) {
      const percent = (value * 100) / relativeOf;
      return percent.toFixed(2);
    },
    search() {
      if (daysBetween(this.startDate, this.endDate) > 31) {
        swal({
          title: 'Aviso!',
          text: 'O período selecionado deve ser de no máximo 31 dias!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      } else {
        callWs('/pix/last-requested-pix-out-gateway',
          'POST', null, true, null,
          {
            username: this.username,
            startDate: formatDate(this.startDate, "DD/MM/YYYY"),
            endDate: formatDate(this.endDate, "DD/MM/YYYY"),
            idTransaction: this.idTransaction,
            keyPix: this.keyPix,
            pageNumber: this.pagination.currentPage - 1,
            pageSize: this.pagination.perPage,
          },
          this.loadTimeline,
          this.failSearch)
      }
    },
    loadTimeline(response) {
      console.log(response.data)
      this.timelineItems = response.data.list
      this.totalNumber = response.data.totalNumber,
      this.numberPix = response.data.numberPix,
      this.numberPixProcessed = response.data.numberPixProcessed,
      this.numberPixChargeback = response.data.numberPixChargeback,
      this.valuePix = response.data.valuePix,
      this.valuePixProcessed = response.data.valuePixProcessed,
      this.valuePixChargeback = response.data.valuePixChargeback,
      this.pagination.totalNumber = response.data.totalNumber
      this.pagination.pageMaxNumber = response.data.pageMaxNumber
      if (this.pagination.totalNumber > 0) {
        this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
        this.pagination.toNumber = ((this.pagination.fromNumber + this.timelineItems.length) - 1)
      } else {
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
      }
      if (this.timelineItems.length <= 0) {
        swal({
          title: 'Não encontrado',
          text: 'No período selecionado não existem transações.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return
      }
    },
    failSearch(error) {
      swal(
        {
          title: 'Erro Inesperado',
          text: 'Ocorreu um erro inesperado ao buscar os dados. Tente novamente.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        }
      )
    },
    validatePeriod() {
      this.endDate = this.startDate
    },
    disabledStartDate(date) {
      let now = new Date()
      return date > now
    },
    disabledEndDate(date) {
      let now = new Date()
      return date > now || date < this.startDate
    },
    showPayerData(transaction) {
      swal({
        title: 'Mais Informações',
        html: `
        <p><strong>Banco:</strong> ${transaction.bank}</p>
        <p><strong>Tipo Chave Pix:</strong> ${transaction.typeKey}</p>
        <p><strong>Chave Pix:</strong> ${transaction.keyPix}</p>
        `,
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-info btn-fill',
        width: '40rem'
      })
    }
  }
}
</script>
<style lang="scss">
.dzs-transactions {
  .btn.btn-info.btn-default {
    float: right;
    background: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .btn.btn-default {
    background-color: #001a3f !important;
  }

  .btn.btn-default:hover {
    background-color: #000c1e !important;
  }

  .advanced-filter {
    gap: .5rem;
  }

  .advanced-filter div {
    margin: 0 !important;
  }

  .card-footer {
    padding-bottom: 15px !important;
  }
}

.swal2-content {
  p strong{
    font-weight: bold;
  }
}
</style>
