<template>
  <div class="register-page dzs-register">
    <div class="full-page login-page section-image sb-login-page">
      <div class="container-fluid content">
        <div class="row">
          <div class="d-none d-lg-flex col-lg-6 content-wrapper">
            <div>
              <img src="/static/img/login/3RBankLogo.svg" alt="">
            </div>
          </div>
          <div class="col-lg-6 d-flex flex-column align-items-center">
            <h2 class="mb-2 text-primary">Seja bem vindo(a)</h2>
            <form @submit.prevent="submit" class="card-register-wrapper">
              <div class="card-register">
                <div>
                  <fg-input v-model="username"
                            v-validate="validations.username"
                            :error="getError('username')" name="username"
                            placeholder="Nome de @usuário" data-vv-as="Nome de usuário"></fg-input>

                  <fg-input v-model="password"
                            v-validate="validations.password" name="password" ref="password"
                            :error="getError('password')" type="password"
                            placeholder="Senha" data-vv-as="Senha"></fg-input>

                  <fg-input v-model="confirm"
                            v-validate="validations.confirm" name="confirm"
                            :error="getError('confirm')" type="password"
                            placeholder="Confirme a Senha" data-vv-as="Confirme a Senha"></fg-input>

                  <fg-input v-model="email"
                            v-validate="validations.email" name="email"
                            :error="getError('email')"
                            placeholder="E-mail" data-vv-as="E-mail"></fg-input>

                  <fg-input v-model="phone"
                            v-validate="validations.phone" name="phone"
                            :error="getError('phone')"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            placeholder="Celular" data-vv-as="Celular"></fg-input>

                  <fg-input v-model="name"
                            v-validate="validations.name" name="name"
                            :error="getError('name')"
                            placeholder="Nome / Responsável pela Empresa" data-vv-as="name"></fg-input>
                </div>
                <div>
                  <p-checkbox class="card-register__terms" v-model="acceptTerms">
                      <span>
                        Eu aceito os <a href="https://web.3rbank.net.br/static/pdf/TERMOS_E_CONDICOES.pdf" target="_blank">termos e condições</a>,
                      <a href="https://web.3rbank.net.br/static/pdf/POLITICA_DE_PRIVACIDADE_E_PROTECAO_DE_DADOS.pdf"
                         target="_blank">política de privacidade e proteção de dados</a> e o <a href="https://web.3rbank.net.br/static/pdf/CONTRATO_CONTA.pdf" target="_blank">contrato de abertura da conta</a>.
                      </span>
                  </p-checkbox>
                </div>
              </div>
              <p-button
                :disabled="!this.showSubmit"
                native-type="submit"
                class="btn btn-round btn-outline-primary">CADASTRAR
              </p-button>
            </form>
          </div>
        </div>
      </div>
      <div class="bg-wrapper">
        <img class="bg-image" src="/static/img/login/bgLogin.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import AppNavbar from './Layout/AppNavbar'
import AppFooter from './Layout/AppFooter'
import {Button, Card, InfoSection} from 'src/components/UIComponents'
import {Checkbox} from 'element-ui'
import {callWs, signup} from 'src/ws.service'
import {VueReCaptcha} from 'vue-recaptcha-v3'
import swal from 'sweetalert2'
import PSwitch from 'src/components/UIComponents/Switch.vue'

export default {
  components: {
    Card,
    AppNavbar,
    AppFooter,
    InfoSection,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    VueReCaptcha,
    PSwitch,
  },
  data() {
    return {
      codeRecommendation: '',
      username: "",
      password: "",
      confirm: "",
      email: "",
      phone: "",
      name: "",
      nicheMarket: "",
      nicheMarketOptions: [],
      anotherNicheMarket: "",
      websiteUrl: "",
      revenue: "",
      referralSource: "",
      sourceContact: "",
      acceptTerms: false,
      showSubmit: true,
      validations: {
        username: {
          min: 3,
          required: true,
          regex: /^[a-z0-9]+(\.?[a-z0-9])+$/,
          ok: false
        },
        password: {
          required: true,
          min: 8,
          ok: false
        },
        confirm: {
          required: true,
          confirmed: 'password',
          ok: false
        },
        email: {
          required: true,
          min: 5,
          email: true,
          ok: false
        },
        phone: {
          required: true,
          min: 14,
          max: 15,
          ok: false
        },
        name: {
          required: true,
          min: 3,
          max: 70,
          ok: false
        },
      },
    }
  },
  created() {
    this.createData()
  },
  mounted() {
    this.recaptcha()
  },

  methods: {
    createData() {
      this.codeRecommendation = this.$route.query.code
    },
    getError(fieldName) {
      if (this.errors.firstRule(fieldName) == "regex") {
        return "Formato Inválido! Somente letras minusculas sem acentuação [a-z], numeros [0-9] e ponto [.] são permitidos."
      }
      return this.errors.first(fieldName)
    },
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },
    submit() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          if (!this.acceptTerms) {
            swal({
              title: "Aviso",
              text: 'Você deve aceitar os Termos e Condições antes de prosseguir.',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          }
          this.showSubmit = false
          this.doRecaptcha()
        }
      })
    },
    async recaptcha() {
      await this.$recaptchaLoaded()
      this.$recaptchaInstance.showBadge()
    },
    async doRecaptcha() {
      const token = await this.$recaptcha('register')
      this.onCaptchaVerified(token)
    },
    onCaptchaVerified(recaptchaToken) {
      signup(this.username, this.password, this.email, recaptchaToken, this.successSignup, this.fail, this.codeRecommendation, this.phone, this.name)
    },
    successSignup(response) {
      if (response.data == 'OK') {
        swal({
          title: "Sucesso!",
          text: 'Foi enviado um e-mail de confirmação para você. Acesse seu e-mail e clique no botão \'Confirmar e-mail\'.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        })
        this.$router.push('/login')
      } else {
        swal({
          title: "Ops...",
          text: response.data == 'EMAIL_USED' ? 'E-mail já cadastrado!' : 'Usuário em uso, favor escolher outro.',
          buttonsStyling: true,
          timer: 5000,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        this.showSubmit = true
      }
    },
    fail(error) {
      if (error.response.status == 400) {
        swal({
          title: 'Falha!',
          text: 'Recaptcha inválido.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-danger btn-fill',
          type: 'error'
        })
      } else {
        swal({
          title: 'Serviço temporariamente indisponível!',
          text: 'Tente novamente mais tarde.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-danger btn-fill',
          type: 'error'
        })
      }

      this.showSubmit = true
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    }
  },
  beforeDestroy() {
    this.closeMenu()
  }
}
</script>
<style lang="scss">
.dzs-register {
  min-height: 100vh;
  font-family: 'Uncut Sans', sans-serif;
  display: flex;
  align-items: center;

  .full-page .content {
    padding-top: 0 !important;
    max-width: 120rem;
    margin: 0 auto;
  }

  .form-control,
  .input-group-text,
  .el-input.el-input--suffix > input {
    background-color: #FFFFFF !important;
    border: none !important;
  }

  .content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      animation: .8s in ease-in-out forwards;

      img {
        max-height: 4rem;
        width: 100%;
      }
    }
  }

  .content__headline {
    color: #FFFFFF;
    font-size: 3.6rem;
    font-weight: bold;
    text-shadow: 0 0 1rem rgba(0, 0, 0, .5);
  }

  .content__text {
    color: #FFFFFF;
    font-size: 1.35rem;
    text-shadow: 0 0 1rem rgba(0, 0, 0, .5);
  }

  .card-register-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    max-width: 31.5rem;

    button {
      align-self: end;
    }
  }

  .card-register {
    width: 100%;
    font-family: 'Uncut Sans', sans-serif;
    border-radius: 1rem !important;
    padding: 1rem !important;
    border: 1px solid #e1bb80;

    input {
      width: 100%;
      background: #E2E2E2 !important;
      color: #333333 !important;
      font-family: inherit;
      font-size: 1rem;
      line-height: 1.5;
    }

    .form-group:last-child {
      margin: 0 !important;
    }
  }

  .card-register__terms {
    color: #eff0ea;
    margin-top: .5rem;
  }

  .bg-wrapper {
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  .bg-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 !important;
  }
}
</style>
