<template>
  <div class="dzs-tev">
    <div class="row">
      <div class="col-md-12">
        <card>
          <h4 slot="header" class="card-title">
            Transferência de PIX Conta Celcoin
          </h4>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3">
                  <div style="float: left; width: 100%">
                    <label class="control-label" style="float: left">Valor</label>
                    <money class="form-control input-lg"
                           v-model="transfer.value"
                           v-bind="money">
                    </money>
                  </div>
                </div>
                <div class="col-md-4">
                  <p-button class="search" style="margin-top: 25px; float: left" @click="callTransaction">
                    <i slot="label" class="fas fa-search"></i> Transferir
                  </p-button>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {isNullOrEmpty, moneyToFloat, notNull, toMoney} from "src/util/core.utils"
  import {failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination
    },
    data() {
      return {
        transfer: {
          value: ''
        },
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        }
      }
    },
    methods: {
      toMoney,
      moneyToFloat,
      isNullOrEmpty,
      callTransaction(){
        postWs("/pix/pix-transfer-internal",
          true, null, this.transfer,
          (response) => {
            swal({
              title: 'Sucesso!',
              text: 'Sucesso ao realizar transferência de PIX da Celcoin!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'success'
            })
          }, (error) => {
            let text = 'Erro ao realizar transferência.';
            if (error.response.status == 400) {
              text = 'Erro ao realizar transferência na Celcoin.'
            } else if (error.response.status == 500) {
              text = 'Erro interno.'
            }
            swal({
              title: 'Erro!',
              text: text,
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'error'
            })
          })
      },
    }
  }
</script>
<style lang="scss">
  .dzs-tev {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    .picture-row {
      margin-top: 20px;
      background-color: #5e5e5e;
    }

    .picture-container {
      margin: 5px auto;
      display: table;
    }

    .picture {
      float: left;
      width: 80px;
      height: 80px;
      background-color: #999;
      border: 1px solid #e3e3e3;
      color: #fff;
      border-radius: 50%;
      overflow: hidden;
      transition: all .2s;
      -webkit-transition: all .2s;
    }

    .picture-text {
      float: right;
      margin-top: 20px;
      margin-left: 6px;
    }

    .clickable-rows {
      tbody tr td {
        cursor: pointer;
      }

      .el-table__expanded-cell {
        cursor: default;
      }
    }

    .search {
      background-color: #001a3f !important;
    }

    .search:hover {
      background-color: #000c1e !important;
    }
  }
</style>
