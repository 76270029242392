import { render, staticRenderFns } from "./ManageSalePersonLevels.vue?vue&type=template&id=6c737e90&scoped=true"
import script from "./ManageSalePersonLevels.vue?vue&type=script&lang=js"
export * from "./ManageSalePersonLevels.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c737e90",
  null
  
)

export default component.exports