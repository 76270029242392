<template>

      <card align="center" style="height: 100%; padding: 5%">
        <div class="row">
          <div class="col-lg-6">
            <h4 slot="header" class="card-title">
              Tudo feito pra <span style="color: #00d19a">@</span>você!
            </h4>
            <span style="font-size: 15px;">Estamos trabalhando para desenvolver as melhores funcionalidades para facilitar o seu dia a dia.</span><br><br>
            <span style="font-size: 15px;">Em breve você poderá solicitar o seu SuitCard e realizar compras em mais de 4 milhões de estabelecimentos por todo o Brasil.</span><br><br>
            <span style="font-size: 15px;">Assim como o SuitCard virtual que proporciona segurança ao realizar compras pela internet e facilidade na gestão de suas campanhas, o SuitCard físico
              também contará com gestão centro de custos, multiplos cartões e controle de responsável, facilitando ainda mais o dia-a-dia da sua empresa.</span><br><br>
            <span style="font-size: 15px;">Lembrando que se se ainda não baixou nosso app, ele já está disponivel em todas as plataformas.
              Basta clicar nos botões de acesso abaixo para ter toda sua plataforma na palma da sua mão.</span><br><br>
            <div class="row" style="padding: 5% 10% 5% 10%">
              <div class="col-md-6">
                <a  href="https://apps.apple.com/br/app/suitpay/id1632130123" target="_blank">
                  <img src="/static/img/mobile/apple_store.png" alt="..."  >

                </a>
              </div>
              <div class="col-md-6">
                <a href="https://play.google.com/store/apps/details?id=com.suitpay" target="_blank">
                  <img src="/static/img/mobile/google_play.png" alt="..."  >
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <img src="/static/img/card-dash.png" alt="..."  >
          </div>
        </div>
      </card>

</template>


<script>
  export default {
    name: "developmentFunction"
  }
</script>

<style scoped>

</style>
