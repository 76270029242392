<template>
  <div class="dzs-boleto">
    <div class="row" style="padding: 15px">

      <div class="col-md-5">
        <card style="padding: 5%; height: 100%">
          <h4 slot="header" class="card-title">
            Depósito via Boleto
          </h4>
          <!--          <banner></banner>-->
          <br>
          <br>
          <p><i class="fas fa-arrow-circle-right text-primary"></i> Emissão de boletos individuais com valor máximo de R$ 9.999,99.<br>
            <i class="fas fa-arrow-circle-right text-primary"></i> Compensação dos boletos em até dois dias úteis após o pagamento.<br>
            <i class="fas fa-arrow-circle-right text-primary"></i> Valor mínimo para depósito de R$ 20,00.<br>
            <i class="fas fa-arrow-circle-right text-primary"></i> Vencimento de até um ano.<br>
            <i class="fas fa-arrow-circle-right text-primary"></i> É possível cancelar o registro do boleto na CIP, se for realizado o cancelamento do boleto, não será possível realizar o pagamento do mesmo.<br>
            <i class="fas fa-arrow-circle-right text-primary"></i> Se não adicionar os dados do pagador, o boleto será gerado com os dados do usuário como pagador.</p>
        </card>
      </div>

      <div class="col-md-7">
        <card style="height: 100%">
          <div class="row">
            <div class="col-md-7">
              <label class="control-label">Valor</label>
              <fg-input placeholder="0,00" v-money="'money'" type="tel"
                        v-model="value" maxlength="14">
                <template slot="addonLeft">R$</template>
              </fg-input>
              <label class="control-label" style="float: right">
                Taxa Serviço: <b>R$ {{serviceFee}}</b>
              </label>
            </div>
            <div class="col-md-5">
              <div class="row" style="margin-left: 0px">
                <label>Data de Vencimento</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="dueDate" type="date" placeholder="Escolha uma data"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-7">
              <fg-input type="text"
                        label="Descrição"
                        maxlength="100"
                        name="Descrição"
                        v-model="description">
              </fg-input>
            </div>
            <div class="col-md-5">
              <p-button type="info" style="margin-top: 25px;"
                        @click="generateNew" v-bind:disabled="registrandoBoleto">
                <i slot="label" class="nc-icon nc-check-2"></i>
                Gerar Boleto
              </p-button>
            </div>
            <div class="col-md-12" style="padding-top: 20px;">
              <p-checkbox class="text-left" v-model="enterPayerData">
                <b>Adicionar dados do pagador.</b>
              </p-checkbox>
            </div>
            <div class="col-md-12" v-show="enterPayerData">
              <h4 slot="header" class="card-title" style="padding-top: -20px;">
                Dados do Pagador
              </h4>
              <div class="row">
                <div class="col-md-12">
                  <fg-input type="text"
                            label="Nome Completo"
                            maxlength="70"
                            name="Nome Completo"
                            v-validate="{required: enterPayerData}"
                            :error="getError('Nome Completo')"
                            v-model="payerData.name">
                  </fg-input>
                </div>
              </div>
              <div class="row" style="margin-top: 0px">
                <div class="col-md-7">
                  <fg-input label="CPF/CNPJ"
                            type="tel"
                            v-mask="['###.###.###-##', '##.###.###/####-##']"
                            name="CPF/CNPJ"
                            v-validate="{required: enterPayerData, min: 14}"
                            :error="getError('CPF/CNPJ')"
                            v-model="payerData.document">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <fg-input type="tel"
                            v-mask="['#####-###']"
                            label="CEP"
                            name="CEP"
                            v-validate="{required: enterPayerData, min: 9}"
                            :error="getError('CEP')"
                            v-on:blur="this.searchZipCode"
                            v-model="payerData.deliveryAddress.zipCode"
                            key="cep">
                  </fg-input>
                </div>
                <div class="col-md-7">
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <fg-input type="text"
                            maxlength="40"
                            :disabled="(viacep && viacep.logradouro  != '')"
                            label="Rua/Av."
                            name="Rua/Av."
                            v-validate="{required: enterPayerData}"
                            :error="getError('Rua/Av.')"
                            v-model="payerData.deliveryAddress.street"
                            key="rua">
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input type="tel"
                            v-mask="['######']"
                            label="Número"
                            name="Número"
                            v-validate="{required: enterPayerData}"
                            :error="getError('Número')"
                            v-model="payerData.deliveryAddress.number"
                            key="numero">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <fg-input type="text"
                            maxlength="40"
                            label="Complemento"
                            v-model="payerData.deliveryAddress.complement"
                            key="complemento">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <fg-input type="text"
                            maxlength="40"
                            :disabled="(viacep && viacep.bairro != '')"
                            label="Bairro"
                            name="Bairro"
                            v-validate="{required: enterPayerData}"
                            :error="getError('Bairro')"
                            v-model="payerData.deliveryAddress.neighborhood"
                            key="bairro">
                  </fg-input>
                </div>
                <div class="col-md-5">
                  <fg-input type="text"
                            label="Cidade"
                            disabled="true"
                            v-model="payerData.deliveryAddress.city"
                            key="cidade">
                  </fg-input>
                </div>
                <div class="col-md-2">
                  <fg-input type="text"
                            label="Estado"
                            disabled="true"
                            placeholder=""
                            v-model="payerData.deliveryAddress.state"
                            key="estado">
                  </fg-input>
                </div>
              </div>
            </div>

            <div class="col-md-12" v-if="!isNullOrEmpty(boleto.barcode)">
                <h4 slot="header" class="card-title" style="color:#598b92; text-align: center">
                  CÓDIGO DE BARRAS DO BOLETO
                </h4>
                <hr/>
                <div class="row">
                  <div class="col-md-12">
                    <p style="text-align: center; font-size: 16px">
                      <b>VALOR:</b> R$ {{toMoney(boleto.value)}} | <b>VENCIMENTO:</b> {{boleto.dueDate}}
                    </p>
                    <p style="text-align: center; margin: 0; font-size: 18px">
                      {{boleto.digitableLine}}
                    </p>
                    <barcode format="ITF" display-value="false" v-bind:value="boleto.barcode"></barcode>
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-md-4 col-12" style="text-align: center; padding-bottom: 10px">
                    <p-button @click="()=>{boleto.barcode = ''}" class="btn btn-info" style="margin: 0 auto">
                      <i class="fas fa-asterisk"/> Novo Boleto
                    </p-button>
                  </div>
                  <div class="col-md-4 col-12" style="text-align: center; padding-bottom: 10px">
                    <p-button @click="copyDigitableLine" class="btn btn-info" style="margin: 0 auto">
                      <i class="fas fa-copy"/> Copiar
                    </p-button>
                  </div>
                  <div class="col-md-4 col-12" style="text-align: center; padding-bottom: 10px">
                    <p-button @click="printBoleto(null)" class="btn btn-info" style="margin: 0 auto">
                      <i class="fas fa-print"/> Imprimir
                    </p-button>
                  </div>
                </div>
            </div>

          </div>
        </card>
      </div>

      <div class="col-md-12" style="padding-top: 15px">
        <div class="row">
          <div :class="'col-lg-4 col-md-12'" v-for="stats in {valuePaid}">
            <stats-card :type="stats.type"
                        :icon="stats.icon"
                        :small-title="stats.title"
                        :title="'R$ ' + toMoney(stats.value)">
            </stats-card>
          </div>
          <div :class="'col-lg-4 col-md-12'" v-for="stats in {valueUnpaid}">
            <stats-card :type="stats.type"
                        :icon="stats.icon"
                        :small-title="stats.title"
                        :title="'R$ ' + toMoney(stats.value)">
            </stats-card>
          </div>
          <div :class="'col-lg-4 col-md-12'" v-for="stats in {valueExpired}">
            <stats-card :type="stats.type"
                        :icon="stats.icon"
                        :small-title="stats.title"
                        :title="'R$ ' + toMoney(stats.value)">
            </stats-card>
          </div>
        </div>
      </div>

      <div class="col-md-12" v-if="tableData.length == 0">
        <card>
          <h4 slot="header" class="card-title" align="center">
            Não existem boletos emitidos
          </h4>
        </card>
      </div>

      <div class="col-md-12" v-if="tableData!=null && tableData.length > 0">
        <card>
          <div class="card-body row">
            <div class="col-sm-12" align="center">
              <h4 slot="header" class="card-title">
                Relação de Boletos Emitidos
              </h4>
              <hr>
            </div>
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="tableData"
                        style="width: 100%">
                <el-table-column :min-width="90" label="Valor">
                  <template slot-scope="props">
                    <span>
                      <span><b>R$</b> {{props.row.value}}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="80" label="Venc.">
                  <template slot-scope="props">
                    <span>
                      <span>{{props.row.dueDate}}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="90" label="Status">
                  <template slot-scope="props">
                    <span>
                      <i class="fas fa-circle" :style="'color: #'+statusToColor(props.row.status)"></i>
                      <span> {{statusToLabel(props.row.status)}}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="80" label="Emitido">
                  <template slot-scope="props">
                    <span>
                      <span>{{props.row.created}}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="200" label="Pagador">
                  <template slot-scope="props">
                    <span>
                      <span>{{props.row.payerName}}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :width="150" fixed="right" class-name="td-actions">
                  <template slot-scope="props" v-if="props.row.status == 'WAITING'">
                    <p-button type="danger" size="sm" icon title="Cancelar boleto"
                              @click="cancelBoleto(props.row.id)">
                      <i class="fas fa-ban"></i>
                    </p-button>
                    <p-button type="info" size="sm" icon title="Exibir código de barras"
                              @click="showBarcode(props.row)">
                      <i class="fas fa-barcode"></i>
                    </p-button>
                    <p-button type="info" size="sm" icon title="Imprimir"
                              @click="printBoleto(props.row.id)">
                      <i class="fas fa-print"></i>
                    </p-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-success">
              <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
                {{pagination.totalNumber}} REGISTROS.</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.totalNumber"
                            :click="this.loadData">
              </p-pagination>
            </div>
          </div>
        </card>
      </div>

    </div>
    <input type="hidden" id="digitableLine-copy" :value="boleto.digitableLine">
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {postWs, failWs, getWs} from "src/ws.service"
  import {toMoney, moneyToFloat, isNullOrEmpty} from "src/util/core.utils"
  import swal from 'sweetalert2'
  import {DatePicker} from 'element-ui'
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard'
  import Banner from "../../../UIComponents/Promotion/Banner";

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      [DatePicker.name]: DatePicker,
      PPagination,
      StatsCard,
      Banner
    },
    data() {
      return {
        dueDate: '',
        serviceFee: '5,00',
        minValue: '20,00',
        value: '0,00',
        registrandoBoleto: false,
        enterPayerData: false,
        description: '',
        boleto: {
          id: '',
          value: '',
          digitableLine: '',
          barcode: '',
          dueDate: '',
          errorMessage: '',
          base64: null
        },
        tableData: [],
        pagination: {
          perPage: 15,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        payerData: {
          name: '',
          document: '',
          deliveryAddress: {
            codIbge: '',
            street: '',
            number: '',
            complement: '',
            zipCode: '',
            neighborhood: '',
            city: '',
            state: ''
          }
        },
        viacep: {
          logradouro: '',
          bairro: '',
          ibge: '',
          localidade: '',
          uf: '',
        },
        valuePaid: {
          type: 'primary',
          icon: 'fa fa-money',
          title: 'Boletos Pagos',
          value: 0,
          footerText: '',
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        valueUnpaid: {
          type: 'primary',
          icon: 'fa fa-refresh',
          title: 'Boletos Pendentes',
          value: 0,
          footerText: '',
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        valueExpired: {
          type: 'primary',
          icon: 'fa fa-ban',
          title: 'Boletos Venc. e Canc.',
          value: 0,
          footerText: '',
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
      }
    },
    mounted() {
      this.initializeDueDate()
      this.loadData()
    },
    methods: {
      toMoney,
      moneyToFloat,
      isNullOrEmpty,
      generateNew() {
        if (moneyToFloat(this.value) < moneyToFloat(this.minValue)) {
          swal({
            title: 'Aviso!',
            text: 'O valor mínimo para depósito via boleto é R$ ' + this.minValue + '.',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        if(this.dueDate <= new Date()) {
          swal({
            title: 'Aviso!',
            text: "Data de vencimento deve ser maior que data de hoje.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        if(this.enterPayerData == true && this.viacep == null) {
          swal({
            title: 'Aviso!',
            text: "CEP não encontrado nos Correios, verifique os dados e tente novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        var dateOneYear = new Date();
        dateOneYear.setFullYear(dateOneYear.getFullYear() + 1)
        if(this.dueDate > dateOneYear) {
          swal({
            title: 'Aviso!',
            text: "Data de vencimento não pode ser superior a um ano.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            return
          }
          this.registrandoBoleto = true;
          postWs("/boleto/generate-boleto",
            true, null,
            {
              value: moneyToFloat(this.value),
              dueDate: this.dueDate,
              description: this.description,
              payerData: this.payerData,
              productType: 'ACCOUNT_CREDIT'
            }, this.successGenerateNew, this.failGenerateNew)
        })
      },
      successGenerateNew(response) {
        this.registrandoBoleto = false;
        this.pagination.currentPage = 1;
        this.value = '20,00';
        this.showBarcode(response.data);
        this.newForm();
        this.loadData();
      },
      failGenerateNew(error) {
        this.registrandoBoleto = false;
        let text = 'Falha ao gerar boleto! Tente mais tarde.'
        if (error.response.data.errorMessage == 'OPERATION_EXCEEDS_LIMIT') {
          text = 'Você atingiu o limite de emissão de boletos. Se deseja aumentar este limite, favor entrar em contato via chat.'
        } else if (error.response.data.errorMessage == 'SOURCE_USER_HAVE_NO_ENABLED_ACCOUNT') {
          text = 'Conta ainda não verificada! Para prosseguir, favor preencher os dados e enviar a documentação no menu \'VERIFICAR CONTA\'.'
        } else if (error.response.data.errorMessage == 'ACCOUNT_DOCUMENTS_NOT_VALIDATED') {
          text = 'Conta em processo de verificação de documentação, aguarde até que os dados e documentos enviados sejam verificados.'
        } else if (error.response.data.errorMessage == 'TOO_MANY_OPEN_BOLETOS_NO_FUNDS') {
          text = 'Limite de boletos em aberto atingido. Pague ou aguarde o vencimendo de ao menos um boleto em aberto para emitir novos boletos.'
        } else if (error.response.data.errorMessage == 'BLOCKED') {
          text = 'Devido a problemas em nossa equipe relacionados à contenção do COVID-19, suspendemos a emissão de boleto, com intuito de evitar transtornos quanto à demora na liberação do crédito e/ou possível suporte nos nossos serviços.'
        } else if (error.response.data.errorMessage == 'GREATER_THAN_THE_MAX_VALUE') {
          text = 'O valor máximo para depósito via boleto é R$ ' + toMoney(error.response.data.value) + '.'
        }
        swal({
          title: 'Aviso!',
          text: text,
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      printBoleto(id) {
        if (isNullOrEmpty(id)) {
          id = this.boleto.id
        }
        postWs("/boleto/pdf",
          true,
          {id: id}, null,
          this.successPrintBoleto,
          failWs)
      },
      loadDocSrc(pdfBase64) {
        return this.base64PDFToBlobUrl(pdfBase64)
      },
      base64PDFToBlobUrl(base64) {
        const binStr = atob(base64);
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
        const blob = new Blob([arr], {type: 'application/pdf'});
        return URL.createObjectURL(blob);
      },
      successPrintBoleto(response) {
        try {
          let pdfWindow = window.open("about:blank");
          pdfWindow.document.write("<html lang='pt-BR'><head><title>3RBank - Boleto</title><style>body{margin: 0;}iframe{border-width: 0;}</style></head>");
          pdfWindow.document.write("<body><iframe style='width:100% ; height:100% ' src='" + this.loadDocSrc(response.data.base64) + "'></iframe></body></html>");
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do boleto, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      showBarcode(boleto) {
        this.boleto.id = boleto.id
        this.boleto.value = boleto.value
        this.boleto.digitableLine = boleto.digitableLine
        this.boleto.barcode = boleto.barcode
        this.boleto.dueDate = boleto.dueDate
      },
      loadData() {
        postWs("/boleto/last-requested-boletos",
          true, null,
          {
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          }, this.successLoadData, failWs)
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.valuePaid.value = response.data.valuePaidBoleto
        this.valueUnpaid.value = response.data.valueUnpaidBoleto
        this.valueExpired.value = response.data.valueExpiredBoleto
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      statusToLabel(status) {
        if ('PAID' == status) {
          return 'Pago'
        } else if ('WAITING' == status) {
          return 'Pendente'
        } else if ('CANCELED' == status) {
          return 'Cancelado'
        } else {
          return 'Vencido'
        }
      },
      statusToColor(status) {
        if ('PAID' == status) {
          return '6bd098'
        } else if ('WAITING' == status) {
          return 'fbc658'
        } else {
          return 'ef8157'
        }
      },
      copyDigitableLine() {
        let digitableLineCopy = document.querySelector('#digitableLine-copy')
        digitableLineCopy.setAttribute('type', 'text')
        digitableLineCopy.select()

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          if(msg == 'successful'){
            swal({
              title: 'Sucesso!',
              text: 'Código de barras copiado com sucesso!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'success'
            })
          }else{
            swal({
              title: 'Erro!',
              text: 'Erro ao copiar código de barras!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }
        } catch (err) {
          swal({
            title: 'Erro!',
            text: 'Erro ao copiar código de barras!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }

        /* unselect the range */
        digitableLineCopy.setAttribute('type', 'hidden')
        window.getSelection().removeAllRanges()
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      searchZipCode() {
        this.viacep = null
        this.payerData.deliveryAddress.codIbge = ""
        this.payerData.deliveryAddress.city = ""
        this.payerData.deliveryAddress.state = ""
        getWs("/cep/get-cep",
          true, {cep: this.payerData.deliveryAddress.zipCode},
          (response) => {
            this.viacep = response.data
            this.payerData.deliveryAddress.codIbge = this.viacep.ibge
            if (this.viacep.logradouro.length > 40) {
              this.payerData.deliveryAddress.street = this.viacep.logradouro.substr(0, 40)
            } else {
              this.payerData.deliveryAddress.street = this.viacep.logradouro
            }
            if(this.viacep.bairro.length > 40) {
              this.payerData.deliveryAddress.neighborhood = this.viacep.bairro.substr(0, 40)
            } else {
              this.payerData.deliveryAddress.neighborhood = this.viacep.bairro
            }
            this.payerData.deliveryAddress.city = this.viacep.localidade
            this.payerData.deliveryAddress.state = this.viacep.uf
          },
          (e) => {
            swal({
              title: 'Aviso!',
              text: 'CEP não encontrado nos Correios, favor verifique os dados e tente novamente!',
              buttonsStyling: true,
              timer: 10000,
              confirmButtonClass: 'btn btn-danger btn-fill',
              type: 'warning'
            })
            return
          })
      },
      initializeDueDate() {
        this.dueDate = new Date();
        this.dueDate.setDate(this.dueDate.getDate() + 5)
        //verifica se sábado ou domingo e coloca a data no próximo dia útil
        if(this.dueDate.getDay() == 6) {
          this.dueDate.setDate(this.dueDate.getDate() + 2)
        }
        if(this.dueDate.getDay() == 0) {
          this.dueDate.setDate(this.dueDate.getDate() + 1)
        }
      },
      newForm() {
        this.initializeDueDate();
        this.value = '0,00'
        this.description = ''
        this.enterPayerData = false
        this.payerData.document = ''
        this.payerData.name = ''
        this.payerData.deliveryAddress.city = ''
        this.payerData.deliveryAddress.codIbge = ''
        this.payerData.deliveryAddress.complement = ''
        this.payerData.deliveryAddress.neighborhood = ''
        this.payerData.deliveryAddress.number = ''
        this.payerData.deliveryAddress.state = ''
        this.payerData.deliveryAddress.street = ''
        this.payerData.deliveryAddress.zipCode = ''
      },
      cancelBoleto(id) {
        swal({
          text: "Tem certeza que deseja cancelar o boleto?",
          type: 'warning',
          buttonsStyling: true,
          confirmButtonClass: 'btn-success btn-fill',
          showCancelButton: true,
          confirmButtonColor: '#001a3f',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        }).then((result) => {
          if (result) {
            this.callCancelBoleto(id)
          }
        })
      },
      callCancelBoleto(id) {
        postWs("/boleto/cancel-boleto",
          true,
          {id: id}, null,
          this.successCancelBoleto,
          this.errorCancelBoleto
        )
      },
      successCancelBoleto(response) {
        swal({
          title: 'Sucesso!',
          text: 'Boleto cancelado com sucesso!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'success'
        })
        this.boleto.barcode = ''
        this.loadData()
      },
      errorCancelBoleto(error) {
        let text = 'Erro ao tentar cancelar boleto!'
        if(error.response.status == '412') {
          text = 'Não é possível cancelar o boleto, o mesmo já está pago ou vencido!'
        }
        swal({
          title: 'Atenção!',
          text: text,
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }
    }
  }
</script>
<style lang="scss">
  .dzs-boleto {
    .vue-barcode-element {
      height: 86px !important;
      display: table;
      margin: 0 auto !important;
      width: 550px !important;
    }

    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    .card-stats .card-body .numbers {
      font-size: 18px !important;
    }

    @media (max-width: 800px) {
      .vue-barcode-element {
        height: 50px !important;
        width: 330px !important;
      }
    }
  }
</style>
