<template>
  <div class="dzs-acc-edit">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header" v-if="allowApproveDocs">
            <h4 class="title">Editar Perfil</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card">
                  <div class="card-body" style="">
                    <form>
                      <div class="row">
                        <div class="col-md-5">
                          <fg-input type="text"
                                    label="Usuário"
                                    disabled
                                    v-model="username">
                          </fg-input>
                        </div>
                        <div class="col-md-7">
                          <fg-input type="email"
                                    label="E-mail"
                                    disabled
                                    v-model="account.email"
                                    :disabled="!isUpdatingPersonalData">
                          </fg-input>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <fg-input type="tel"
                                    label="Celular"
                                    v-mask="['(##) #####-####']"
                                    name="Celular"
                                    v-model="account.phoneNumber1"
                                    :disabled="!isUpdatingPersonalData">
                          </fg-input>
                        </div>
                        <div class="col-md-6">
                          <fg-input type="tel"
                                    label="Telefone"
                                    v-mask="['(##) ####-####', '(##) #####-####']"
                                    v-model="account.phoneNumber2"
                                    :disabled="!isUpdatingPersonalData">
                          </fg-input>
                        </div>
                        <div class="col-md-6">
                          <fg-input type="tel"
                                    label="Indicado Por:"
                                    v-model="account.recommendationUser"
                                    disabled>
                          </fg-input>
                        </div>
                      </div>
                      <div class="row" v-if="isSomeAuthorization(['BO_COMMERCIAL_EDIT_CONTACT'])">
                        <div class="col-auto">
                          <button v-on:click.prevent="onsubmit" class="btn btn-neutral" @click="() => this.isUpdatingPersonalData = !this.isUpdatingPersonalData">Atualizar Dados Cadastrais</button>
                        </div>
                        <div class="col-auto">
                          <button
                            class="btn btn-success"
                            v-on:click.prevent="onsubmit"
                            v-show="isUpdatingPersonalData"
                            @click="updateUserPersonalData"
                          >Salvar Alterações</button>
                        </div>
                      </div>
                      <div class="row" style="margin-top: 30px">
                        <div class="col-md-5">
                          <fg-input label="Tipo de Pessoa">
                            <el-select size="large"
                                       placeholder="Selecionar"
                                       v-model="account.personType"
                                       :disabled="formDisabled">
                              <el-option class="select-success"
                                         value="NATURAL_PERSON"
                                         label="Pessoa Física"/>
                              <el-option class="select-success"
                                         value="LEGAL_PERSON"
                                         label="Pessoa Jurídica"/>
                            </el-select>
                          </fg-input>
                        </div>
                        <div class="col-md-7">
                          <fg-input :label="account.personType=='NATURAL_PERSON'?'CPF':'CNPJ'"
                                    type="tel"
                                    v-mask="account.personType=='NATURAL_PERSON'?['###.###.###-##']:['##.###.###/####-##']"
                                    name="CPF/CNPJ"
                                    v-model="account.socialNumber"
                                    :disabled="formDisabled">
                          </fg-input>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <fg-input type="text"
                                    :label='account.personType === "NATURAL_PERSON" ? "Nome Completo" : "Nome Completo do Representante"'
                                    maxlength="70"
                                    name="Nome Completo"
                                    v-model="account.name"
                                    :disabled="formDisabled">
                          </fg-input>
                        </div>
                      </div>
                      <div class="row" v-if="account.personType=='LEGAL_PERSON'">
                        <div class="col-md-6">
                          <fg-input label="CPF do Representante"
                                    type="tel"
                                    v-mask="['###.###.###-##']"
                                    name="CPF do Representante"
                                    v-model="account.legalPerson.procuratorSocialNumber"
                                    :disabled="formDisabled"
                                    key="cpf-do-representante">
                          </fg-input>
                        </div>
                        <div class="col-md-6">
                          <fg-input label="Data de Nascimento do Representante"
                                    type="tel"
                                    v-mask="['##/##/####']"
                                    name="Data de Nascimento do Representante"
                                    v-model="account.legalPerson.procuratorBirthday"
                                    :disabled="formDisabled"
                                    key="dn-representante">
                          </fg-input>
                        </div>
                      </div>
                      <div v-if="account.personType=='LEGAL_PERSON'">
                        <div class="row">
                          <div class="col-md-12">
                            <fg-input type="text"
                                      label="Razão Social"
                                      maxlength="100"
                                      name="Razão Social"
                                      v-model="account.legalPerson.companyName"
                                      :disabled="formDisabled">
                            </fg-input>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">
                            <div class="row" style="padding-left: 15px">
                              <fg-input type="tel"
                                        v-mask="['##/##/####']"
                                        label="Data de Constituição"
                                        name="Data de Constituição"
                                        v-model="account.legalPerson.openingDate"
                                        :disabled="formDisabled"></fg-input>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <div class="card">
                              <div class="card-header">
                                <span class="title">Lista de Sócios</span>
                              </div>
                              <div class="card-body">
                                <div class="card-body row p-0">
                                  <div class="col-sm-12 p-0">
                                    <el-table class="table-striped"
                                              :data="account.legalPerson.members"
                                              style="width: 100%"
                                              :header-cell-style="getHeaderStyle">
                                      <el-table-column :min-width="40" label="Tipo">
                                        <template slot-scope="props">
                                          <span>
                                            <span>{{personTypeToDescription(props.row.personType)}}</span>
                                          </span>
                                        </template>
                                      </el-table-column>
                                      <el-table-column :min-width="70" label="CPF/CNPJ">
                                        <template slot-scope="props">
                                          <span>
                                            <span>{{props.row.socialNumber}}</span>
                                          </span>
                                        </template>
                                      </el-table-column>
                                      <el-table-column :min-width="140" label="Nome">
                                        <template slot-scope="props">
                                          <span>
                                            <span>{{props.row.name.toUpperCase()}}</span>
                                          </span>
                                        </template>
                                      </el-table-column>
                                      <el-table-column :min-width="50" label="Nasc.">
                                        <template slot-scope="props">
                                          <span>
                                            <span>{{props.row.birthday}}</span>
                                          </span>
                                        </template>
                                      </el-table-column>
                                    </el-table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>



                      <div v-if="account.personType=='NATURAL_PERSON'">
                        <div class="row">
                          <div class="col-md-7">
                            <fg-input type="text"
                                      maxlength="40"
                                      label="Naturalidade"
                                      name="Naturalidade"
                                      v-model="account.naturalPerson.birthplace"
                                      :disabled="formDisabled">
                            </fg-input>
                          </div>
                          <div class="col-md-5">
                            <div class="row" style="padding-left: 15px">
                              <fg-input type="tel"
                                        v-mask="['##/##/####']"
                                        label="Data de Nascimento"
                                        name="Data de Nascimento"
                                        v-model="account.naturalPerson.birthDate"
                                        :disabled="formDisabled"></fg-input>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4"
                               :style="account.naturalPerson.identificationDocument.documentType!='RG'?'margin-bottom: 10px':''">
                            <fg-input label="Tipo de Documento">
                              <el-select size="large"
                                         placeholder="Selecionar"
                                         v-model="account.naturalPerson.identificationDocument.documentType"
                                         :disabled="formDisabled">
                                <el-option class="select-success"
                                           value="RG"
                                           label="RG"/>
                                <el-option class="select-success"
                                           value="CNH"
                                           label="CNH"/>
                                <el-option class="select-success"
                                           value="PASSPORT"
                                           label="Passaporte"/>
                              </el-select>
                            </fg-input>
                          </div>
                          <div class="col-md-8" v-if="account.naturalPerson.identificationDocument.documentType=='RG'">
                            <fg-input type="text"
                                      maxlength="40"
                                      label="Orgão Expedidor"
                                      name="Orgão Expedidor"
                                      v-model="account.naturalPerson.identificationDocument.dispatchingAgency"
                                      :disabled="formDisabled">
                            </fg-input>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <fg-input type="text"
                                      maxlength="20"
                                      label="Número do Documento"
                                      name="Número do Documento"
                                      v-model="account.naturalPerson.identificationDocument.number"
                                      :disabled="formDisabled">
                            </fg-input>
                          </div>
                          <div class="col-md-6">
                            <div class="row" style="padding-left: 15px">
                              <fg-input type="tel"
                                        v-mask="['##/##/####']"
                                        label="Data de Expedição"
                                        name="Data de Expedição"
                                        v-model="account.naturalPerson.identificationDocument.emissionDate"
                                        :disabled="formDisabled"></fg-input>
                            </div>
                          </div>
                        </div>
                        <div class="row" style="margin-bottom: 10px">
                          <div class="col-md-4">
                            <fg-input label="Sexo">
                              <el-select size="large"
                                         placeholder="Selecionar"
                                         v-model="account.naturalPerson.gender"
                                         :disabled="formDisabled">
                                <el-option class="select-success"
                                           value="MALE"
                                           label="Masculino"/>
                                <el-option class="select-info"
                                           value="FEMALE"
                                           label="Feminino"/>
                              </el-select>
                            </fg-input>
                          </div>
                          <div class="col-md-8">
                            <fg-input label="Estado Civil">
                              <el-select size="large"
                                         placeholder="Selecionar"
                                         v-model="account.naturalPerson.maritalStatus"
                                         :disabled="formDisabled">
                                <el-option class="select-info"
                                           value="SINGLE"
                                           label="Solteiro"/>
                                <el-option class="select-danger"
                                           value="MARRIED"
                                           label="Casado"/>
                                <el-option class="select-success"
                                           value="DIVORCED"
                                           label="Divorciado"/>
                                <el-option class="select-warning"
                                           value="WIDOWED"
                                           label="Viúvo"/>
                              </el-select>
                            </fg-input>
                          </div>
                        </div>
                        <div class="row" v-if="account.naturalPerson.maritalStatus=='MARRIED'">
                          <div class="col-md-12">
                            <fg-input type="text"
                                      maxlength="70"
                                      label="Nome do Cônjuge"
                                      v-model="account.naturalPerson.partnerName"
                                      :disabled="formDisabled">
                            </fg-input>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <fg-input type="text"
                                      maxlength="30"
                                      label="Profissão"
                                      name="Profissão"
                                      v-model="account.naturalPerson.profession"
                                      :disabled="formDisabled">
                            </fg-input>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <fg-input type="text"
                                      maxlength="70"
                                      label="Nome da Mãe"
                                      name="Nome da Mãe"
                                      v-model="account.naturalPerson.motherName"
                                      :disabled="formDisabled">
                            </fg-input>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <fg-input type="text"
                                      maxlength="70"
                                      label="Nome do Pai"
                                      v-model="account.naturalPerson.fatherName"
                                      :disabled="formDisabled">
                            </fg-input>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <fg-input type="text"
                                    label="Principal Ramo de Atividade"
                                    disabled
                                    v-model="account.cnaeDescription">
                          </fg-input>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <fg-input type="text"
                                    label="Natureza Jurídica"
                                    disabled
                                    v-model="account.legalNatureDescription">
                          </fg-input>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <fg-input type="text"
                                    label="Porte"
                                    disabled
                                    v-model="account.legalFormationDescription">
                          </fg-input>
                          <div class="error-text" style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;">{{ errors.first("Porte") }}</div>
                        </div>
                      </div>
                      <div class="row" style="margin-top: 30px" v-show="account.accountBusinessData.registered">
                        <div class="col-md-6">
                          <fg-input type="text"
                                    maxlength="100"
                                    label="Site"
                                    v-model="account.accountBusinessData.site"
                                    :disabled="formDisabled"
                                    key="site">
                          </fg-input>
                        </div>
                        <div class="col-md-6">
                          <fg-input type="text"
                                    maxlength="100"
                                    label="Mídia Social"
                                    v-model="account.accountBusinessData.socialMedia"
                                    :disabled="formDisabled"
                                    key="socialMedia">
                          </fg-input>
                        </div>
                        <div class="col-md-6">
                          <fg-input type="text"
                                    maxlength="100"
                                    label="Área de Atuação"
                                    v-model="account.accountBusinessData.businessSegment.description"
                                    :disabled="formDisabled"
                                    key="socialMedia">
                          </fg-input>
                        </div>
                        <div class="col-md-6">
                          <fg-input type="text"
                                    maxlength="100"
                                    label="Média de Faturamento"
                                    v-model="account.accountBusinessData.averageBilling.description"
                                    :disabled="formDisabled"
                                    key="socialMedia">
                          </fg-input>
                        </div>
                      </div>

                      <div class="row" style="margin-top: 30px">
                        <div class="col-md-5">
                          <fg-input type="tel"
                                    v-mask="['#####-###']"
                                    label="CEP"
                                    name="CEP"
                                    @blur="searchZipCode"
                                    v-model="account.address.zipCode"
                                    :disabled="formDisabled && !isUpdatingAddress">
                          </fg-input>
                        </div>
                        <div class="col-md-7">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <fg-input type="text"
                                    maxlength="40"
                                    :disabled="((viacep && viacep.logradouro) || formDisabled) && !isUpdatingAddress"
                                    label="Rua/Av."
                                    name="Rua/Av."
                                    v-model="account.address.street">
                          </fg-input>
                        </div>
                        <div class="col-md-4">
                          <fg-input type="tel"
                                    v-mask="['######']"
                                    label="Número"
                                    v-model="account.address.number"
                                    :disabled="formDisabled && !isUpdatingAddress">
                          </fg-input>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <fg-input type="text"
                                    maxlength="40"
                                    label="Complemento"
                                    v-model="account.address.complement"
                                    :disabled="formDisabled && !isUpdatingAddress">
                          </fg-input>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5">
                          <fg-input type="text"
                                    maxlength="40"
                                    :disabled="((viacep && viacep.bairro) || formDisabled) && !isUpdatingAddress"
                                    label="Bairro"
                                    name="Bairro"
                                    v-model="account.address.neighborhood">
                          </fg-input>
                        </div>
                        <div class="col-md-5">
                          <fg-input type="text"
                                    label="Cidade"
                                    disabled
                                    v-model="account.address.city">
                          </fg-input>
                        </div>
                        <div class="col-md-2">
                          <fg-input type="text"
                                    label="Estado"
                                    disabled
                                    placeholder=""
                                    v-model="account.address.state">
                          </fg-input>
                        </div>
                      </div>
                    </form>
                    <div class="row" v-if="isSomeAuthorization(['BO_UPDATE_ADDRESS'])">
                      <div class="col-auto">
                        <button class="btn btn-neutral" @click="() => this.isUpdatingAddress = !this.isUpdatingAddress">Atualizar Endereço</button>
                      </div>
                      <div class="col-auto">
                        <button
                          class="btn btn-success"
                          v-show="isUpdatingAddress"
                          @click="updateAddress"
                        >Salvar Alterações</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card" >
                  <div class="card-body">
                    <div class="row col-md-12">
                      <el-table class="table-striped" :data="images" style="margin: auto; width: 100%">
                        <el-table-column :min-width="90" label="Documento">
                          <template v-slot="props">
                            <span>{{imageTypeToText(props.row.documentPhotoType)}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column :min-width="90" label="Status">
                          <template v-slot="props">
                            <span>{{imageStatusToText(props.row.documentPhotoStatus)}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column :min-width="90" label="Motivo">
                          <template v-slot="props">
                            <span>{{imageCauseToText(props.row.causeError)}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column :min-width="30" label="Ação">
                          <template v-slot="props">
                            <p-button type="primary" size="sm" icon title="Visualizar" @click="openModal(props.row.id)">
                              <i class="fa-regular fa-eye"></i>
                            </p-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                    <div class="row col-md-12">
                      <div v-for="image in images">
                        <!--        <modal :show.sync="modals[image.id]" headerClasses="justify-content-center" @close="reloadDoc(image.id)">-->
                        <div v-show="modals[image.id]" class="card">
                          <div class="card-body">
                            <div style="float: right">
                              <p-button type="danger" size="sm" icon title="Remover" style="margin-right: 5px" @click="()=>{modals[image.id] = false; reloadDoc(image.id)}">
                                <i class="fa-solid fa-xmark"></i>
                              </p-button>
                            </div>
                            <div class="row">
                              <div class="col-md-12 doc-image">
                                <a href="#" @click="openDoc(image)">
                                  <embed width="900" height="900" :src='loadDocSrc(image)'
                                         v-if="'pdf'==image.imageFileType.replace(' ', '').toLowerCase()"/>
                                  <img :src="loadDocSrc(image)" v-else/>
                                </a>

                                <fg-input type="text" disabled
                                          v-model="image.id"/>

                                <fg-input type="text" disabled
                                          v-model="image.documentName"/>

                                <div style="display:table; margin: 0 auto !important">
                                  <fg-input>
                                    <el-select size="large" disabled
                                               placeholder="Selecionar"
                                               v-model="image.documentPhotoType">
                                      <el-option value="PHOTO_DOCUMENT"
                                                 label="Foto da Frente do Documento"/>
                                      <el-option value="BACK_PHOTO_DOCUMENT"
                                                 label="Foto do Verso do Documento"/>
                                      <el-option value="PROOF_OF_RESIDENCE"
                                                 label="Comprovante de Endereço"/>
                                      <el-option value="SELFIE"
                                                 label="Selfie"/>
                                      <el-option value="CNPJ_CARD"
                                                 label="Cartão CNPJ"/>
                                      <el-option value="SOCIAL_CONTRACT"
                                                 label="Contrato Social"/>
                                      <el-option value="PROOF_OF_COMPANY_ADDRESS"
                                                 label="Comprovante Endereço Juridico"/>
                                    </el-select>
                                  </fg-input>
                                  <fg-input v-if="allowApproveDocs || isSomeAuthorization(['BO_UPDATE_DOCUMENT_STATUS'])">
                                    <el-select size="large"
                                               :class="image.documentPhotoStatus=='OK'?'select-success':image.documentPhotoStatus=='REJECTED'?'select-danger':'select-warning'"
                                               placeholder="Selecionar"
                                               v-model="image.documentPhotoStatus">
                                      <el-option class="select-warning"
                                                 value="WAITING_APPROVAL"
                                                 label="AGUARDANDO APROVAÇÃO"/>
                                      <el-option class="select-danger"
                                                 value="REJECTED"
                                                 label="REJEITADO"/>
                                      <el-option class="select-success"
                                                 value="OK"
                                                 label="APROVADO"/>
                                    </el-select>
                                  </fg-input>
                                  <fg-input v-if="allowApproveDocs || isSomeAuthorization(['BO_UPDATE_DOCUMENT_STATUS'])">
                                    <el-select size="large"
                                               :class="image.causeError==null?'select-success':'select-danger'"
                                               placeholder="Selecionar"
                                               v-model="image.causeError">
                                      <el-option class="select-success"
                                                 :value="null"
                                                 label="Nenhum"/>
                                      <el-option class="select-danger"
                                                 value="INVALID"
                                                 label="Doc. Inválido"/>
                                      <el-option class="select-danger"
                                                 value="INCOMPATIBLE"
                                                 label="Doc. Diverge do Form."/>
                                      <el-option class="select-danger"
                                                 value="UNREADABLE"
                                                 label="Doc. Ilegível"/>
                                      <el-option class="select-danger"
                                                 value="EXPIRED_DOCUMENT"
                                                 label="Doc. vencido"/>
                                      <el-option class="select-danger"
                                                 value="COMPLIANCE"
                                                 label="Rejeito no Compliance"/>
                                    </el-select>
                                  </fg-input>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <button class="btn btn-info btn-fill btn-wd" style="margin: 20px auto"
                                      @click="openDoc(image)">
                                <i class="fas fa-search-plus"></i>
                                VISUALIZAR DOCUMENTO EM NOVA JANELA
                              </button>
                            </div>
                            <div class="row">
                              <button class="btn btn-success btn-fill btn-wd" style="margin: 20px auto"
                                      type="submit" v-if="allowApproveDocs || isSomeAuthorization(['BO_UPDATE_DOCUMENT_STATUS'])" @click="() => {saveDoc(image)}">
                                <i class="fas fa-save"></i>
                                Salvar Documento
                              </button>
                            </div>
                          </div>
                        </div>
                        <!--        </modal>-->
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <button
                          v-if="allowApproveDocs || isSomeAuthorization(['BO_UPDATE_DOCUMENT_STATUS'])"
                          class="btn btn-success"
                          @click="approveDocsWaiting"
                          :disabled="imagesWaitingForApproval.length === 0"
                        >Aprovar Documentos em Espera</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row-md-6">
                  <div class="card">
                    <div class="card-header">
                      <h3>Histórico de Bloqueio</h3>
                    </div>
                    <div class="card-body">
                      <div class="row col-md-12">
                        <el-table class="table-striped" :data="historyBlocked" style="margin: auto; width: 100%">
                          <el-table-column :min-width="90" label="Responsável">
                            <template v-slot="props">
                              <span>{{props.row.createdBy}}</span>
                            </template>
                          </el-table-column>
                          <el-table-column :min-width="90" label="Motivo">
                            <template v-slot="props">
                              <span style="word-break: normal">{{props.row.status}}</span>
                            </template>
                          </el-table-column>
                          <el-table-column :min-width="90" label="Data de Bloqueio">
                            <template v-slot="props">
                              <span>{{props.row.createdDate}}</span>
                            </template>
                          </el-table-column>
                          <el-table-column :min-width="90" label="Mais Informações">
                            <template style="text-align: center" v-slot="props">
                              <p-button v-if="props.row.description" type="primary" size="sm" icon title="Visualizar" @click="showSwalAdicionalInfo(props.row.description)">
                                <i class="fa-regular fa-eye"></i>
                              </p-button>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <h3>Histórico de Retorno Aarin</h3>
                  </div>
                  <div class="card-body">
                    <div class="row col-md-12">
                      <el-table class="table-striped" :data="historyAarin" style="margin: auto; width: 100%">
                        <el-table-column :min-width="90" label="Data de Retorno">
                          <template v-slot="props">
                            <span>{{formatDate(props.row.createdDate, 'DD/MM/YYYY HH:mm:ss')}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column :min-width="90" label="Código">
                          <template v-slot="props">
                            <span style="word-break: normal">{{props.row.code}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column :min-width="90" label="Mais Informações">
                          <template style="text-align: center" v-slot="props">
                            <p-button v-if="props.row.description" type="primary" size="sm" icon title="Visualizar" @click="showSwalAdicionalInfo(props.row.description)">
                              <i class="fa-regular fa-eye"></i>
                            </p-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {postWs, getWs, failWs} from 'src/ws.service'
import {isNullOrEmpty} from 'src/util/core.utils'
import swal from 'sweetalert2'
import {isSomeAuthorization} from "@/util/role.utils";
import Autocomplete from 'vuejs-auto-complete'
import {formatDate} from "@/util/date.utils";


  export default {
    name: "account-info",
    components: {
      Autocomplete
    },
    props:{
      injectUsername: null,
      allowApproveDocs: true,
    },
    emits: ['docStatusChanged'],
    data() {
      return {
        username: '',
        viacep: {
          logradouro: '',
          bairro: ''
        },
        formDisabled: false,
        modals: {},
        lastOpenModal: null,
        cnaeList: [],
        legalNatureList: [],
        legalFormationList: [],
        account: {
          name: '',
          email: '',
          socialNumber: '',
          personType: 'NATURAL_PERSON',
          phoneNumber1: '',
          phoneNumber2: '',
          accountDocumentStatus: '',
          address: {
            codIbge: '',
            street: '',
            number: '',
            complement: '',
            zipCode: '',
            neighborhood: '',
            city: '',
            state: ''
          },
          legalPerson: {
            companyName: '',
            openingDate: '',
            mainActivity: ''
          },
          naturalPerson: {
            motherName: '',
            fatherName: '',
            birthDate: '',
            birthplace: '',
            gender: 'MALE',
            maritalStatus: 'SINGLE',
            partnerName: '',
            profession: '',
            identificationDocument: {
              documentType: 'RG',
              number: '',
              emissionDate: '',
              dispatchingAgency: ''
            }
          },
          accountBusinessData: {
            nicheMarket:'',
            registered: false,
            site: '',
            socialMedia: '',
            averageBilling: {
              id: '',
              description: '',
            },
            businessSegment: {
              id: '',
              description: '',
            },
          },
          recommendationUser: '',
        },
        nicheMarketOptions: [],
        isUpdatingAddress: false,
        isUpdatingPersonalInfo: false,
        isUpdatingPersonalInfoDetails: false,
        isUpdatingPersonalData:false,
        images: [],
        historyBlocked: [],
        historyAarin: []
      }
    },
    created() {
      document.body.style.overflow='auto'

      if(this.injectUsername === null || typeof this.injectUsername === 'undefined'){
        this.username = this.$route.params.username
      } else {
        this.username = this.injectUsername
      }
      if (typeof this.allowApproveDocs === 'undefined') {
        this.allowApproveDocs = true
      }

      postWs("/acc/get-account", true,
        {username: this.username}, null,
        this.loadAccount, failWs)
      getWs("/acc/get-cnae-list", true, null, (response) => {this.cnaeList = response.data}, function () {
      })
      getWs("/acc/get-legal-nature-list", true, null, (response) => {this.legalNatureList = response.data}, function () {
      })
      getWs("/acc/get-legal-formation-list", true, null, (response) => {this.legalFormationList = response.data}, function () {
      })

      postWs("/document/status", true,
        {username: this.username}, null,
        this.loadDoc, failWs)
    },
    computed: {
      revenueOptions: () => [
        "Sem faturamento",
        "Abaixo de 100 mil",
        "Entre 100 e 300 mil",
        "Entre 300 e 500 mil",
        "Entre 500 mil e 1 milhão",
        "Entre 1 e 5 milhões",
        "Mais de 5 milhões"
      ],
      referralSourceOptions: () => [
        "Facebook",
        "Instagram",
        "Google",
        "Youtube",
        "Indicação"
      ],
      imagesWaitingForApproval() {
        return this.images.filter(image => {
          return image.documentPhotoStatus === "WAITING_APPROVAL"
        })
      },
      requiredFieldsUpdateAddress() {
        return {
          'ADDRESS_ZIPCODE': {
            value: this.account.address.zipCode,
            fieldName: 'CEP'
          },
          'ADDRESS_STREET': {
            value: this.account.address.street,
            fieldName: 'Rua/Av.'
          },
          'ADDRESS_NEIGHBORHOOD': {
            value: this.account.address.neighborhood,
            fieldName: 'Bairro'
          },
          'ADDRESS_CITY': {
            value: this.account.address.city,
            fieldName: 'Cidade'
          },
          'ADDRESS_STATE': {
            value: this.account.address.state,
            fieldName: 'Estado'
          }
        }
      }
    },
    methods: {
      formatDate,
      isSomeAuthorization,
      imageTypeToText(type){
        switch(type){
          case("PHOTO_DOCUMENT"):
            return "Foto da Frente do Documento"
          case("BACK_PHOTO_DOCUMENT"):
            return "Foto do Verso do Documento"
          case("PROOF_OF_RESIDENCE"):
            return "Comprovante de Endereço"
          case("SELFIE"):
            return "Selfie"
          case("CNPJ_CARD"):
            return "Cartão CNPJ"
          case("SOCIAL_CONTRACT"):
            return "Contrato Social"
          case("PROOF_OF_COMPANY_ADDRESS"):
            return "Comprovante Endereço Juridico"
        }
      },
      imageStatusToText(status){
        switch(status){
          case "WAITING_APPROVAL":
            return "AGUARDANDO APROVAÇÃO"
          case "REJECTED":
            return "REJEITADO"
          case "OK":
            return "APROVADO"
        }
      },
      imageCauseToText(cause){
        switch(cause){
          case null:
            return "Nenhum"
          case "INVALID":
            return "Doc. Inválido"
          case "INCOMPATIBLE":
            return "Doc. Diverge do Form."
          case "UNREADABLE":
            return "Doc. Ilegível"
          case "EXPIRED_DOCUMENT":
            return "Doc. vencido"
          case "COMPLIANCE":
            return "Rejeito no Compliance"
          case "COMMERCIAL":
            return "Rejeito na Pré Análise"
        }
      },
      saveDoc(image, showSuccessMessage = true) {
        if (image.documentPhotoStatus == 'REJECTED'
          && image.causeError == null) {
          swal({
            title: 'Alerta!',
            text: 'Informe o motivo pelo qual o documento foi rejeitado.',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        postWs("/bo/document/update-status", true, null,
          {id: image.id, status: image.documentPhotoStatus, cause: image.causeError},
          () => {
            this.modals[image.id] = false
            if(showSuccessMessage) {
              swal({
                title: 'Sucesso!',
                text: 'Status salvo com sucesso.',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'success'
              })
            }
            this.$root.$emit('docStatusChanged')
          }, failWs)
      },
      reloadDoc(id){
        getWs("/bo/document/image", true,
          {id: id},
          (response) => {
            for(let i = 0; i < this.images.length; i++){
              if(this.images[i].id === id){
                this.$set(this.images, i, response.data)
                return
              }
            }
          }, failWs)
      },
      getHistoryBlockedUser(){
        getWs("/bo/auth/blocked-history-user", true,
          {username: this.username},
          this.successLoadHistoryList,
          failWs)
      },
      successLoadHistoryList(response) {
        this.historyBlocked = response.data.list
      },
      getReturnCodeHistoryAarin(){
        getWs("/bo/aarin/return-code-history-user", true,
          {username: this.username},
          this.sucessGetReturnCodeHistoryAarin,
          failWs)
      },
      sucessGetReturnCodeHistoryAarin(response) {
        console.log(response)
        this.historyAarin = response.data
      },
      loadAccount(response) {
        this.account = response.data
        this.getHistoryBlockedUser()
        this.getReturnCodeHistoryAarin()
        this.formDisabled = true
        if (!isNullOrEmpty(this.account.address.codIbge)) {
          this.viacep.logradouro = this.account.address.street
          this.viacep.bairro = this.account.address.neighborhood
        }
      },
      loadDoc(response) {
        this.images = []
        if (response.data.length > 0) {
          for (let i in response.data) {
            this.loadDocData(response.data[i])
          }
        }
      },
      loadDocData(image) {
        getWs("/bo/document/image", true,
          {id: image.id},
          (response) => {
            this.images.push(response.data)
            this.$set(this.modals, response.data.id, false)
          }, failWs)
      },
      loadDocSrc(image) {
        let fileType = image.imageFileType.replace(" ", "").toLowerCase()
        if (fileType === 'pdf') {
          return this.base64PDFToBlobUrl(image.imageBase64)
        } else if (fileType === 'jpg') {
          return 'data:image/jpeg;base64,' + image.imageBase64
        }
        return 'data:image/' + fileType + ';base64,' + image.imageBase64
      },
      base64PDFToBlobUrl(base64) {
        const binStr = atob(base64);
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
        const blob = new Blob([arr], {type: 'application/pdf'});
        return URL.createObjectURL(blob);
      },
      openDoc(image) {
        let fileType = image.imageFileType.replace(" ", "").toLowerCase()
        let imageDimension = " width='100%' "
        if (fileType === 'pdf') {
          imageDimension += " height='100%' "
        }
        try {
          let pdfWindow = window.open("about:blank");
          pdfWindow.document.write("<html lang='pt-BR'><head><title>3RBank - Validação de Documento</title><style>body{margin: 0;}iframe{border-width: 0;}</style></head>");
          pdfWindow.document.write("<body><iframe style='width:100% ; height:100% ' " + imageDimension + "src='" + this.loadDocSrc(image) + "'></iframe></body></html>");
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do documento, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      openModal(id){
        if(this.lastOpenModal != null){
          this.modals[this.lastOpenModal] = false
        }
        this.modals[id] = true
        this.lastOpenModal = id
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      personTypeToDescription(personType){
        switch (personType) {
          case 'LEGAL_PERSON':
            return 'Pessoa Jurídica'
          case 'NATURAL_PERSON':
            return 'Pessoa Física'
        }
        return ''
      },
      selectCnae(cnae){
        this.account.cnae = cnae.value
      },
      cnaeDescription(cnae){
        return cnae.code + ' - ' + cnae.description
      },
      selectLegalNature(legalNature){
        this.account.legalNature = legalNature.value
      },
      legalNatureDescription(legalNature){
        return legalNature.code + ' - ' + legalNature.description
      },
      selectLegalFormation(legalFormation){
        this.account.legalFormation = legalFormation.value
      },
      showSwalAdicionalInfo(info){
        swal({
          title: "Informações Adicionais",
          text: info
        })
      },
      getHeaderStyle(){
        return 'font-size:12px';
      },
      updateAddress() {
        const validationFails = this.validateFields()

        if(validationFails) {
          swal({
            title: 'Atenção!',
            text: `O campo '${validationFails.fieldName}' não pode ser vazio.`,
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }

        postWs('/bo/acc/update-address', true, null,
          {
            username: this.username ,
            address: {
              codIbge: this.account.address.codIbge,
              street: this.account.address.street,
              number: this.account.address.number,
              complement: this.account.address.complement,
              zipCode: this.account.address.zipCode,
              neighborhood: this.account.address.neighborhood,
              city: this.account.address.city,
              state: this.account.address.state,
            }
          },
          this.successUpdateAddress,
          this.failUpdateAddress,
        )
      },
      successUpdateAddress() {
        swal({
          title: 'Sucesso!',
          text: 'Os dados de endereço foram atualizados com sucesso!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        })
        this.isUpdatingAddress = false
      },
      failUpdateAddress(error) {
        swal({
          title: 'Atenção!',
          text: `Ocorreu um erro com o campo '${this.requiredFieldsUpdateAddress[error.response.data.field].fieldName}'.
              Verifique e Tente Novamente`,
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      updateUserPersonalData() {
        this.isUpdatingPersonalData = false
        const validationFails = this.validateFieldsPersonalData()

        if(validationFails) {
          swal({
            title: 'Atenção!',
            text: `Os campos de E-mail e Celular são obrigatórios`,
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }

        postWs('/bo/acc/update-personal-data', true, null,
          {
            username: this.username,
            email: this.account.email,
            phoneNumber1: this.account.phoneNumber1,
            phoneNumber2: this.account.phoneNumber2
          },
          this.successUpdateUserPersonalData,
          this.failUpdateUserPersonalData,
        )
      },
      successUpdateUserPersonalData() {
        swal({
          title: 'Sucesso!',
          text: 'As informações pessoais foram atualizados com sucesso!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        })
        this.isUpdatingAddress = false
      },
      failUpdateUserPersonalData(error) {
        swal({
          title: 'Atenção!',
          text: `Ocorreu um erro com a atualização de informações pessoais
              Verifique e Tente Novamente`,
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      validateFields() {
        for (const field in this.requiredFieldsUpdateAddress) {
          if (!this.requiredFieldsUpdateAddress[field].value.trim()) {
            return this.requiredFieldsUpdateAddress[field];
          }
        }
        return false
      },
      validateFieldsPersonalData() {
        if (!this.account.email.trim()){
          return !this.account.email
        }else if(!this.account.phoneNumber1.trim() || !this.account.phoneNumber2 && !this.account.phoneNumber1.trim()){
          return !this.account.phoneNumber1
        }
        return false
      },
      searchZipCode() {
        this.viacep = null
        getWs("/cep/get-cep",
          true,
          {
            cep: this.account.address.zipCode
          },
          (response) => {
            this.viacep = response.data
            this.account.address.codIbge = this.viacep.ibge
            if (this.viacep.logradouro.length > 40) {
              this.account.address.street = this.viacep.logradouro.substr(0, 40)
            } else {
              this.account.address.street = this.viacep.logradouro
            }
            if(this.viacep.bairro.length > 40) {
              this.account.address.neighborhood = this.viacep.bairro.substr(0, 40)
            } else {
              this.account.address.neighborhood = this.viacep.bairro
            }
            this.account.address.city = this.viacep.localidade
            this.account.address.state = this.viacep.uf
          },
          (e) => {
            swal({
              title: 'Aviso!',
              text: 'CEP não encontrado nos Correios, favor verifique os dados e tente novamente!',
              buttonsStyling: true,
              timer: 10000,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'warning'
            })
            return
          })
      },
      async approveDocsWaiting() {
        try {
          for (const image of this.imagesWaitingForApproval) {
            image.documentPhotoStatus = "OK";
            await this.saveDoc(image, false);
          }

          swal({
            title: 'Sucesso!',
            text: 'Os documentos foram atualizados com sucesso!',
            buttonsStyling: true,
            timer: 5000,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'success'
          });
        } catch (error) {
          swal({
            title: 'Atenção!',
            text: 'Ocorreu um erro inesperado ao aprovar documentos! Tente Novamente.',
            buttonsStyling: true,
            timer: 5000,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          });
        }
      }
    }
  }

</script>
<style lang="scss">
  .dzs-acc-edit {
    .doc-image input {
      min-width: 400px;
      text-align: center;
      color: #000000 !important;
    }
  }
</style>

