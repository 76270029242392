export function getTransactionName(type, subtype) {
  switch (type) {
    case 'TED':
      return "TED"
    case 'TEV':
      return "Transferência"
    case 'BOLETO':
      return "Boleto"
    case 'BOLETO_PAYMENT':
      return "Pgt. de Boleto"
    case 'FEE':
      return "Taxa" + " " + getTransactionName(subtype, null)
    case 'SUBSCRIPTION':
      return "Mensalidade"
    case 'CHARGEBACK':
      return "Estorno" + " " + getTransactionName(subtype, null)
    case 'BONUS':
      return "Indicação"
    case 'CREDIT_TED':
      return "Recarga Via TED"
    case 'BUY_BTC':
      return "Compra BTC"
    case 'SELL_BTC':
      return "Venda BTC"
    case 'PROFIT_BTC':
      return "Lucro BTC"
    case 'TOTAL_IN':
      return "Total Entradas"
    case 'TOTAL_OUT':
      return "Total Saídas"
    case 'PROFIT_CARD_TERMINAL':
      return "Lucro Máquinas"
    case 'PAYMENT_USER_CARD':
      return "Pagamento Máquinas"
    case 'PAYMENT_PARTNER_CARD':
      return "Cashback Máquinas"
    case 'COST_TRANSACTION':
      return "Pgt. Custo Transação"
    case 'PIX':
      return "PIX" + " " + (subtype==null?"":subtype)
    case 'PIX_FEE':
      return "Taxa Pix"
    case 'PIX_PAYMENT':
      return "Pgt. via PIX" + " " + (subtype==null?"":subtype)
    case 'BANK_FEE':
      return "Taxas Bancárias"
    case 'PROFIT_GATEWAY':
      return "Lucro Online"
    case 'PROFIT_SHARE_GATEWAY':
      return "Profit Share Online"
    case 'PAYMENT_USER_GATEWAY':
      return "Pagamento Online"
    case 'PAYMENT_FIN_RESERVE':
      return "Reserva Financeira"
    case 'PAY_PARTNER_GATEWAY':
      return "Cashback do Online"
    case 'COST_TR_GATEWAY':
      return "Pgt. Custo Online"
    case 'EXCHANGE_PROFIT':
      return "Taxa de Câmbio"
    case 'EXCHANGE':
      return "Câmbio"
    case 'EXCHANGE_PROFIT_CBK':
      return "Estorno Taxa de Câmbio"
    case 'EXCHANGE_CBK':
      return "Estorno Câmbio"
    case 'CARD_RECHARGE':
      return 'Recarga de Cartão'
    case 'CARD_WITHDRAW':
      return 'Dev. Saldo Cartão'
    case 'PHYSICAL_CREDIT_CARD_REQUEST':
      return 'Pedido de Cartão'
    case 'COMMISSION_PAYMENT':
      return 'PLR'
    case 'SPLIT_PIX':
      return 'Split de Pagamento'
    case 'PAYMENT_PARTNER_PIX':
      return 'Cashback Parceiro'
    case 'GATEWAY':
      return 'Gateway'
  }
  return "Outros"
}

export function getCardTransactionName(type){
  switch (type) {
    case 'RECHARGE':
      return "Recarga"
    case 'PURCHASE':
      return "Compra"
    case 'CARD_TRANSFER':
      return "Transf. Responsável"
    case 'WITHDRAW':
      return "Devolução de Saldo"
    case 'CHARGEBACK':
      return "Cancel. de compra"
    case 'CARD_REPLACEMENT_OUT':
      return 'Subst. Cartão'
    case 'CARD_REPLACEMENT_IN':
      return 'Subst. Cartão'
  }
  return 'Outros'
}

export function getCardTransactionLogo(type){
  switch (type) {
    case 'RECHARGE':
      return "fa-solid fa-money-check-dollar"
    case 'PURCHASE':
      return "fa-solid fa-cart-shopping"
    case 'CARD_TRANSFER':
      return "fa-solid fa-repeat"
    case 'WITHDRAW':
      return "fa-solid fa-arrow-up"
    case 'CHARGEBACK':
      return "fas fa-undo"
    case 'CARD_REPLACEMENT_OUT':
      return 'fa-solid fa-recycle'
    case 'CARD_REPLACEMENT_IN':
      return 'fa-solid fa-recycle'
  }
  return ''
}

export function getTransactionLogo(type) {
  switch (type) {
    case 'TED':
      return "fas fa-arrow-up fa-2x"
    case 'TEV':
      return "fas fa-exchange-alt fa-2x"
    case 'BOLETO':
      return "fas fa-file-invoice-dollar fa-2x"
    case 'FEE':
      return "fas fa-receipt fa-2x"
    case 'SUBSCRIPTION':
      return "fas fa-receipt fa-2x"
    case 'CHARGEBACK':
      return "fas fa-undo fa-2x"
    case 'BONUS':
      return "fas fa-hands-helping fa-2x"
    case 'CREDIT_TED':
      return "fa fa-retweet fa-2x"
    case 'BUY_BTC':
      return "fa fa-long-arrow-down fa-2x"
    case 'SELL_BTC':
      return "fa fa-long-arrow-up fa-2x"
    case 'PROFIT_BTC':
      return "fa fa-btc fa-2x"
    case 'BOLETO_PAYMENT':
      return "fa fa-file-invoice fa-2x"
    case 'PROFIT_CARD_TERMINAL':
      return "fa fa-money fa-2x"
    case 'PAYMENT_USER_CARD':
      return "fas fa-calculator fa-2x"
    case 'PAYMENT_PARTNER_CARD':
      return "fas fa-hands-helping fa-2x"
    case 'COST_TRANSACTION':
      return "fas fa-line-chart fa-2x"
    case 'PIX':
      return "fa fa-compress fa-2x"
    case 'PIX_PAYMENT':
      return "fas fa-receipt fa-2x"
    case 'BANK_FEE':
      return "fas fa-file-invoice-dollar fa-2x"
    case 'PROFIT_GATEWAY':
      return "fa-solid fa-money-bill-trend-up fa-2x"
    case 'PROFIT_SHARE_GATEWAY':
      return "fa-solid fa-hand-holding-dollar fa-2x"
    case 'PAYMENT_USER_GATEWAY':
      return "fa-solid fa-money-check-dollar fa-2x"
    case 'PAYMENT_FIN_RESERVE':
      return "fa-solid fa-sack-dollar fa-2x"
    case 'PAY_PARTNER_GATEWAY':
      return "fa-solid fa-handshake-simple fa-2x"
    case 'COST_TR_GATEWAY':
      return "fa-solid fa-file-invoice-dollar fa-2x"
    case 'EXCHANGE_PROFIT':
      return "fa-solid fa-file-invoice-dollar fa-2x"
    case 'EXCHANGE':
      return "fa-solid fa-money-bill-transfer fa-2x"
    case 'EXCHANGE_PROFIT_CBK':
      return "fa-solid fa-file-invoice-dollar fa-2x"
    case 'EXCHANGE_CBK':
      return "fa-solid fa-money-bill-transfer fa-2x"
    case 'COMMISSION_PAYMENT':
      return 'fa-solid fa-coins fa-2x'
    case 'CARD_RECHARGE':
      return 'fa-regular fa-credit-card fa-2x'
    case 'CARD_WITHDRAW':
      return 'fa-regular fa-credit-card fa-2x'
    case 'SPLIT_PIX':
      return 'fa-solid fa-money-bill-transfer fa-2x'
    case 'PAYMENT_PARTNER_PIX':
      return 'fa-solid fa-money-bill-trend-up fa-2x'
  }
  return "fas fa-star-of-life fa-2x"
}
