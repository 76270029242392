<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h7>{{'@' + cardsInfo.username + ' - ' + cardsInfo.salePersonName}}</h7>
          <hr>
          <h4>Buscar Usuário</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-2">
                <label class="control-label">Nome</label>
                <fg-input v-model="search.name"/>
              </div>
              <div class="col-md-2">
                <div class="row" style="margin-left: 0px">
                  <label>Mês das transações</label>
                </div>
                <div class="row" style="margin-left: 0px">
                  <el-date-picker v-model="search.month" placeholder="Escolha um mês" type="month" format="MM/yyyy">
                  </el-date-picker>
                </div>
              </div>
              <div class="col-xl-2">
                <div class="row" style="margin-left: 0px">
                  <label>Data Cadastro Inicio</label>
                </div>
                <div class="row" style="margin-left: 0px">
                  <el-date-picker v-model="search.startDate" type="date" placeholder="Escolha uma data"
                                  :picker-options="dateOptsStart" @input="validatePeriod"
                                  format="dd/MM/yyyy">
                  </el-date-picker>
                </div>
              </div>
              <div class="col-xl-2">
                <div class="row" style="margin-left: 0px">
                  <label>Data Cadastro Fim</label>
                </div>
                <div class="row" style="margin-left: 0px">
                  <el-date-picker v-model="search.endDate" type="date" placeholder="Escolha uma data"
                                  :picker-options="dateOptsEnd"
                                  format="dd/MM/yyyy">
                  </el-date-picker>
                </div>
              </div>
              <div class="col-md-2">
                <fg-input label="Volume de Transações">
                  <el-select size="large"
                             placeholder="Selecionar"
                             class="select-default"
                             v-model="search.above50PercentExpected">
                    <el-option :value="null"
                               class="select-default"
                               label="Todos"/>
                    <el-option value="false"
                               class="select-default"
                               label="Menor que 50%"/>
                    <el-option value="true"
                               class="select-default"
                               label="Maior ou Igual a 50%"/>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-xl-1">
                <fg-input label="Status da Conta">
                  <el-select size="large"
                             placeholder="Selecionar"
                             class="select-default"
                             v-model="search.onlyEnabled">
                    <el-option :value="null"
                               class="select-default"
                               label="Todos"/>
                    <el-option value="true"
                               class="select-default"
                               label="Ativos"/>
                    <el-option value="false"
                               class="select-default"
                               label="Bloqueados"/>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-xl-1">
                <p-button type="success" style="margin-top: 25px; font-size: x-small"
                          @click="find">
                  <i slot="label" class="nc-icon nc-check-2"></i>
                  Buscar
                </p-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-body row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-12 d-flex justify-content-center">
                <p-button type="success" style="margin-top: 25px;" size="sm" @click="toggleBlur" outline round>
                  <i slot="label" class="fa-regular fa-eye-slash" v-if="!blurValues" @click="toggleBlur"></i>
                  <i slot="label" class="fa-regular fa-eye" v-if="blurValues" @click="toggleBlur"></i>
                  <span v-if="blurValues"> Visualizar valores </span>
                  <span v-if="!blurValues"> Esconder valores </span>
                </p-button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="text-muted m-auto row" style="padding: 0 10% 0 10%">
                  <div class="col-lg p-1">
                    <stats-card
                      type="primary"
                      :title="'R$ ' + toMoney(cardsInfo.monthGoal)"
                      small-title="Meta do Mês"
                      icon="fa-solid fa-bullseye"
                      :title-class="getCardTitleClass()"
                      small-title-class="card-category-small-text">
                    </stats-card>
                  </div>
                  <div class="col-lg p-1">
                    <stats-card
                      type="danger"
                      :title="'R$ ' + toMoney(cardsInfo.realAchieved)"
                      small-title="Total Transacionado"
                      icon="fa-solid fa-coins"
                      :title-class="getCardTitleClass()"
                      small-title-class="card-category-small-text">
                    </stats-card>
                  </div>
                  <div class="col-lg p-1">
                    <stats-card
                      type="danger"
                      :title="'R$ ' + toMoney(cardsInfo.realGateway)"
                      small-title="Total Tr. Gateway"
                      icon="fa-solid fa-coins"
                      :title-class="getCardTitleClass()"
                      small-title-class="card-category-small-text">
                    </stats-card>
                  </div>
                  <div class="col-lg p-1">
                    <stats-card
                      type="danger"
                      :title="'R$ ' + toMoney(cardsInfo.realPrePaidCard)"
                      small-title="Total Tr. Pré Pago"
                      icon="fa-solid fa-coins"
                      :title-class="getCardTitleClass()"
                      small-title-class="card-category-small-text">
                    </stats-card>
                  </div>
                  <div class="col-lg p-1">
                    <stats-card
                      type="danger"
                      :title="cardsInfo.percentGoalAchieved + ' %'"
                      small-title="Real / Meta"
                      icon="fa-regular fa-percent"
                      :title-class="getCardTitleClass()"
                      small-title-class="card-category-small-text">
                    </stats-card>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="text-muted m-auto row" style="padding: 0 10% 0 10%">
                  <div class="col-lg p-1">
                    <stats-card
                      type="info"
                      :title="'R$ ' + toMoney(cardsInfo.secondCommission)"
                      small-title="PLR Gateway"
                      icon="fa-solid fa-spinner "
                      :title-class="getCardTitleClass()"
                      small-title-class="card-category-small-text">
                    </stats-card>
                  </div>
                  <div class="col-lg p-1">
                    <stats-card
                      type="info"
                      :title="'R$ ' + toMoney(cardsInfo.fixedCommission)"
                      small-title="PLR Pré-Pago"
                      icon="fa-solid fa-asterisk"
                      :title-class="getCardTitleClass()"
                      small-title-class="card-category-small-text">
                    </stats-card>
                  </div>
                  <div class="col-lg p-1">
                    <stats-card
                      type="primary"
                      :title="'R$ ' + toMoney(cardsInfo.fullCommission)"
                      small-title="PLR Total"
                      icon="fa-solid fa-money-bill-trend-up"
                      :title-class="getCardTitleClass()"
                      small-title-class="card-category-small-text">
                    </stats-card>
                  </div>
                  <div class="col-lg p-1">
                    <stats-card
                      type="primary"
                      :title="'R$ ' + toMoney(cardsInfo.totalCommissionToPay)"
                      small-title="PLR / Meta"
                      icon="fa-solid fa-money-bill-trend-up"
                      :title-class="getCardTitleClass()"
                      small-title-class="card-category-small-text">
                    </stats-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-body row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-sm-12 mt-2">
                <el-table class="table-striped"
                          :data="tableData"
                          style="width: 100%">
                  <el-table-column :min-width="60" label="Usuário">
                    <template slot-scope="props">
                    <span>
                      <span><b>@{{props.row.username}}</b></span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="100" label="Nome">
                    <template slot-scope="props">
                    <span>
                      <span>{{props.row.name}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="40" label="Esperado">
                    <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.expectedTransactionAmount)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="50" label="Transacionado">
                    <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.transactionsTotal)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="20" label="%">
                    <template slot-scope="props">
                    <span>
                      <span>{{props.row.percentFromExpected}} %</span>
                    </span>
                    </template>
                  </el-table-column>
<!--                  <el-table-column :min-width="40" label="% de PLR">-->
<!--                    <template slot-scope="props">-->
<!--                    <span>-->
<!--                      <span>{{props.row.commissionPercent}} %</span>-->
<!--                    </span>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
                  <el-table-column :min-width="40" label="Aprovação">
                    <template slot-scope="props">
                    <span>
                      <span>{{props.row.approvalDate}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="30" label="Gateway">
                    <template slot-scope="props">
                    <span>
                      <span><i :class="'fas fa-circle ' + paymentStatusToStyle(props.row.linkPaymentStatus)"/> {{paymentStatusToLabel(props.row.linkPaymentStatus)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="50" label="Prox. Recomp.">
                    <template slot-scope="props">
                    <span>
                      <span> {{props.row.nextReward == null? "Atingiu todas recomp." : props.row.nextReward}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="70" label="Falta p/ Recomp.">
                    <template slot-scope="props" >
                    <span>
                      <span>  R$ {{toMoney(props.row.missingForReward)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column label="Ações" :width="70" fixed="right" class-name="td-actions">
                    <template slot-scope="props">
                      <p-button type="default" size="sm" icon title="Editar" v-if="isSomeAuthorization(['BO_COMMERCIAL_EDIT_CLIENT', 'BO_COMMERCIAL_SALES_PERSON'])"
                                @click="salesClientEdit(props.row.username)">
                        <i class="fas fa-person-circle-check"></i>
                      </p-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="col-sm-6 pagination-info">
                <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE {{pagination.totalNumber}} REGISTROS.</p>
              </div>
              <div class="col-sm-6">
                <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
                              :total="pagination.totalNumber" :click="this.loadData">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {DatePicker, Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {postWs, getWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {isSomeAuthorization} from "src/util/role.utils"
  import {StatsCard} from "@/components/UIComponents";
  import {toMoney} from "@/util/core.utils";
  import {formatDate} from "src/util/date.utils"

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination, isSomeAuthorization, [DatePicker.name]: DatePicker, StatsCard
    },
    data() {
      return {
        search: {
          name: '',
          month: new Date(),
          startDate: null,
          endDate: null,
          above50PercentExpected: null,
          onlyEnabled: 'true'
        },
        salePersonUsername: null,
        tableData: [],
        cardsInfo:{},
        blurValues: true,
        pagination: {
          perPage: 50,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        dateOptsStart: {
          disabledDate: this.disabledStartDate,
          shortcuts: [{
            text: 'Hoje',
            onClick(picker) {
              const date = new Date()
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
            {
              text: 'Ontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
                this.endDate = date
              }
            },
            {
              text: 'Anteontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 48)
                picker.$emit('pick', date)
                this.endDate = date
              }
            }]
        },
        dateOptsEnd: {
          disabledDate: this.disabledEndDate
        },
      }
    },

    mounted() {
      if(this.$route.params.salePersonUsername != null){
        this.salePersonUsername = this.$route.params.salePersonUsername
      }
      this.loadCards()
      this.loadData()
    },
    methods: {
      isSomeAuthorization,
      toMoney,
      salesClientEdit(username){
        this.openWindow('/bo/commercial/clientDetail/' + username)
      },
      resetDefaultValues(){
        this.search.key = ''
        this.search.enabled = null,
        this.pagination.currentPage = 1
        this.pagination.perPage = 50
        this.pagination.pageMaxNumber = 1
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
        this.pagination.totalNumber = 0
        this.tableData = [];
        this.loadData()
      },
      find() {
        this.pagination.currentPage = 1
        this.loadCards()
        this.loadData()
      },
      toggleBlur(){
        this.blurValues = !this.blurValues;
      },
      getCardTitleClass(){
        if(this.blurValues){
          return 'card-category-small-text-blur'
        } else {
          return 'card-category-small-text'
        }
      },
      loadCards(){
        getWs("/bo/commercial/dashboard/getCardsInfo",
          true, {salePersonUsername: this.salePersonUsername, month: (this.search.month.getMonth()+1)+"/"+this.search.month.getFullYear(),},
          (response) => {
            this.cardsInfo = response.data
          }, (error) => {
            this.cardsInfo = {}
            this.processWsError(error)
          }
        )
      },
      loadData() {
        postWs("/bo/commercial/dashboard/getClientsList",
          true, {salePersonUsername: this.salePersonUsername},
          {
            clientName: this.search.name,
            month: (this.search.month.getMonth()+1)+"/"+this.search.month.getFullYear(),
            above50PercentExpected: this.search.above50PercentExpected,
            onlyEnabled: this.search.onlyEnabled,
            startDate: this.search.startDate != null ? formatDate(this.search.startDate, "DD/MM/YYYY") : null,
            endDate: this.search.endDate != null ? formatDate(this.search.endDate,"DD/MM/YYYY") : null,
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          (error) => {
            this.tableData = []
            this.processWsError(error)
          })

        return this.pagination.currentPage
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      processWsError(error){
        let text= ""
        if("NOT_ALLOWED" === error.response.data.response){
          text = "Você não é um executivo de vendas!"
        } else if("NO_DATA_FOUND" === error.response.data.response){
          text = "Não foi possivel carregar todos os dados para essa consulta!"
        } else {
          text = "Erro ao carregar dados de cartões de estatisticas"
        }

        swal({
          title: 'Aviso!',
          text: text,
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      validatePeriod() {
        this.endDate = this.startDate
      },
      disabledStartDate(date) {
        let now = new Date()
        return date > now
      },
      disabledEndDate(date) {
        let now = new Date()
        return date > now || date < this.startDate
      },
      openWindow(url) {
        try {
          window.open(url)
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do conteúdo, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      paymentStatusToLabel(status) {
        switch (status){
          case('WAITING_SENT'):
          case('WAITING_APPROVAL'):
          case('WAITING_SENT_BANK_DETAILS'):
            return 'Aguard.'
          case('APPROVED'):
            return 'Aprov.'
          case('DISAPPROVED'):
            return 'Reprov.'
          case('INACTIVE'):
          case('INACTIVE_WITH_CHECKOUT'):
            return 'Inativo'
          case('FAIL'):
            return 'Falha'
          default:
            return 'Desc.'
        }
      },
      paymentStatusToStyle(status) {
        if (status === 'WAITING_SENT') {
          return 'text-warning'
        } else if (status === 'WAITING_SENT_BANK_DETAILS') {
          return 'text-warning'
        } else if (status === 'WAITING_APPROVAL') {
          return 'text-warning'
        } else if (status === 'DISAPPROVED') {
          return 'text-danger'
        } else if (status === 'APPROVED') {
          return 'text-success'
        } else if (status === 'INACTIVE') {
          return 'text-danger'
        } else if (status === 'INACTIVE_WITH_CHECKOUT') {
          return 'text-danger'
        } else if (status === 'FAIL') {
          return 'text-danger'
        }
        return 'text-muted'
      },
    }
  }
</script>
<style lang="scss">
  .card-category-small-text{
    font-size: 14px!important;
  }
  .card-category-small-text-blur{
    font-size: 14px!important;
    color: transparent;
    text-shadow: 0 0 8px #000;
  }
  .card-title-small-text{
    font-size: medium!important;
  }
</style>
