<template>
  <div class="sidebar"
       :data-active-color="activeColor">
    <div class="logo">
      <a class="simple-text logo-mini"
         aria-label="sidebar mini logo"
         href="https://www.3rbank.net.br">
        <div style="background-color: transparent !important;" v-if="this.$sidebar.isMinimized">
          <img src="/static/img/vue-logo-mini.png" alt="">
        </div>
      </a>
      <a class="simple-text logo-normal" href="/">
        <img src="/static/img/logo-menu.png" width="120" alt="">
      </a>
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot>

      </slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item v-for="(link, index) in sidebarLinks"
                        :key="link.name + index" v-if="!link.disabled"
                        :link="link">

            <sidebar-item v-for="(subLink, index) in link.children"
                          :key="subLink.name + index" v-if="!subLink.disabled"
                          :link="subLink">
            </sidebar-item>
          </sidebar-item>
        </slot>

      </ul>
    </div>
  </div>
</template>
<script>
  import 'perfect-scrollbar/dist/css/perfect-scrollbar.css'

  export default {
    props: {
      title: {
        type: String,
        default: '3RBank',
        description: 'Para você e seu negócio!'
      },
      backgroundColor: {
        type: String,
        default: 'white',
        validator: (value) => {
          let acceptedValues = ['white', 'brown', 'black']
          return acceptedValues.indexOf(value) !== -1
        },
        description: 'Sidebar background color (white|brown|black)'
      },
      activeColor: {
        type: String,
        default: 'primary',
        validator: (value) => {
          let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
          return acceptedValues.indexOf(value) !== -1
        },
        description: 'Sidebar active text color (primary|info|success|warning|danger)'
      },
      sidebarLinks: {
        type: Array,
        default: () => [],
        description: 'Sidebar links. Can be also provided as children components (sidebar-item)'
      },
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    provide() {
      return {
        autoClose: this.autoClose
      }
    },
    methods: {
      async initScrollBarAsync() {
        let isWindows = navigator.platform.startsWith('Win');
        if (!isWindows) {
          return;
        }
        const PerfectScroll = await import('perfect-scrollbar')
        PerfectScroll.initialize(this.$refs.sidebarScrollArea)
      }
    },
    mounted() {
      this.initScrollBarAsync()
    },
    beforeDestroy() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false
      }
    }
  }

</script>
<style>
  @media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu {
      display: none;
    }

    .simple-text {
      text-transform: none !important;
    }
  }
</style>
