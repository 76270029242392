import moment from "moment";

export function formatDate(date, format) {
  return moment(date).format(format)
}

export function daysBetween(startDate, endDate) {
  let ms = Math.round(endDate - startDate)
  return ms / (1000 * 60 * 60 * 24)
}
