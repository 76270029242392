<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Gerenciar Solicitações De Máquina</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-3">
            <label class="control-label">Usuário</label>
            <fg-input v-model="search.key"/>
          </div>
          <div class="col-md-3">
            <fg-input label="Documentação Status">
              <el-select size="large"
                         placeholder="Selecionar"
                         class="select-default"
                         v-model="search.requestStatus">
                <el-option :value="null"
                           class="select-default"
                           label="Todos"/>
                <el-option value="WAITING_APPROVAL"
                           class="select-default"
                           label="Aguardando Aprovação"/>
                <el-option value="APPROVED"
                           class="select-default"
                           label="Aprovado"/>
                <el-option value="PREPARING_SHIPMENT"
                           class="select-default"
                           label="Preparando Envio"/>
                <el-option value="SHIPPED"
                           class="select-default"
                           label="Enviado"/>
                <el-option value="PARTIAL_SHIPPING"
                           class="select-default"
                           label="Enviado Parcial"/>
                <el-option value="NOT_APPROVED"
                           class="select-default"
                           label="Não Aprovado"/>
                <el-option value="CANCELED_BY_CUSTOMER"
                           class="select-default"
                           label="Cancelado Pelo Cliente"/>
                <el-option value="CANCELED_BY_VENDOR"
                           class="select-default"
                           label="Cancelado Pelo Vendedor"/>
                <el-option value="ACTIVE"
                           class="select-default"
                           label="Máquinas Ativas"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <fg-input label="Ordenação Data">
              <el-select size="large"
                         placeholder="Selecionar"
                         class="select-default"
                         v-model="search.dateOrder">
                <el-option value="ASC"
                           class="select-default"
                           label="Crescente"/>
                <el-option value="DESC"
                           class="select-default"
                           label="Decrescente"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-2">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in [5,10,25,50]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px;"
                      @click="find">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="60" label="Usuário">
                <template slot-scope="props">
                    <span>
                      <span><b>@{{props.row.username}}</b></span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="100" label="Nome">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.name}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Criação">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.createDate}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="28" label="Qtd">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.amountCardTerminal}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Mod. Maq">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.nameModelCardTerminal}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Status">
                <template slot-scope="props">
                    <span>
                      <span>{{reqStatusToLabel(props.row.status)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="60" label="Status KYC">
                <template slot-scope="props">
                    <span>
                      <span>{{reqOwnProcessStatusToLabel(props.row.ownProcessStatus)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="60" label="Status Envio">
                <template slot-scope="props">
                    <span>
                      <span>{{transitStatusToLabel(props.row.transitStatus)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :width="110" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="info" size="sm" icon title="Editar"
                            @click="edit(props.row.idRequest, props.row.status, props.row.transitStatus)"
                            :disabled="isEditButtonDisabled(props.row)">
                    <i class="fas fa-edit"></i>
                  </p-button>
                  <p-button type="success" size="sm" icon title="Consultar Dados"
                            @click="consult(props.row.idRequest)">
                    <i class="fas fa-search"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {isAuthorized, isSomeAuthorization} from "src/util/role.utils"

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination
    },
    data() {
      return {
        search: {
          key: '',
          requestStatus: null,
          dateOrder: 'ASC'
        },
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        editApproval: false,
        editShippingData: false,
        editActivation: false,
      }
    },
    watch:{
      '$route': 'resetDefaultValues'
    },

    mounted() {
      this.loadData()
    },
    methods: {
      resetDefaultValues(){
        this.search.key = ''
        this.search.requestStatus = 'WAITING_APPROVAL'
        this.search.dateOrder = 'ASC'
        this.pagination.currentPage = 1
        this.pagination.perPage = 5
        this.pagination.pageMaxNumber = 1
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
        this.pagination.totalNumber = 0
        this.tableData = [];
        this.loadData()
      },
      find() {
        this.pagination.currentPage = 1
        this.loadData()
      },
      loadData() {
        postWs("/bo/card-terminal/list",
          true, null,
          {
            key: this.search.key,
            status: this.search.requestStatus,
            dateOrder: this.search.dateOrder,
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          }, this.successLoadData, this.failLoadData)
        return this.pagination.currentPage
      },
      failLoadData(error) {
        swal({
          title: 'Aviso!',
          text: "Não foi encontrada solicitação para esse usuário.",
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      successLoadData(response) {
        this.tableData = response.data.requests
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      edit(idRequest, status, transitStatus) {
        if(status == 'WAITING_APPROVAL') {
          this.openWindow('/bo/cardterminal/edit/' + idRequest)
        } else if(status == 'APPROVED' || status == 'PREPARING_SHIPMENT') {
          this.openWindow('/bo/cardterminal/prepareshipment/' + idRequest)
        } else if(status == 'SHIPPED' || status == 'PARTIAL_SHIPPING') {
          if(transitStatus == 'DELIVERED'){
            this.openWindow('/bo/cardterminal/activeRequest/' + idRequest)
          } else {
            this.openWindow('/bo/cardterminal/prepareshipment/' + idRequest)
          }
        }
      },
      consult(idRequest) {
        this.openWindow('/bo/cardterminal/consultrequest/' + idRequest)
      },
      reqStatusToLabel(reqStatus) {
        if (reqStatus === 'WAITING_APPROVAL') {
          return 'Ag. Aprovação'
        } else if (reqStatus === 'APPROVED') {
          return 'Aprovado'
        } else if (reqStatus === 'PREPARING_SHIPMENT') {
          return 'Ag. Envio'
        } else if (reqStatus === 'SHIPPED') {
          return 'Enviado'
        } else if (reqStatus === 'PARTIAL_SHIPPING') {
          return 'Envio Parc.'
        } else if (reqStatus === 'NOT_APPROVED') {
          return 'Não Aprovado'
        } else if (reqStatus === 'CANCELED_BY_CUSTOMER') {
          return 'Canc. Cliente'
        } else if (reqStatus === 'CANCELED_BY_VENDOR') {
          return 'Canc. Vend.'
        } else if (reqStatus === 'ACTIVE') {
          return 'Ativo'
        }
        return '??'
      },
      reqOwnProcessStatusToLabel(reqStatus) {
        if (reqStatus === 'WAITING_ADIQ_APPROVAL') {
          return 'Ag. Aprovação Adiq.'
        } else if (reqStatus === 'APPROVED') {
          return 'Aprovado'
        } else if (reqStatus === 'REJECTED') {
          return 'Rejeitado'
        }
        return 'Não iniciado'
      },
      transitStatusToLabel(reqStatus) {
        if (reqStatus === 'WAITING_SEND') {
          return 'Aguardando Envio'
        } else if (reqStatus === 'SENT') {
          return 'Enviado'
        } else if (reqStatus === 'DELIVERED') {
          return 'Entregue'
        } else if (reqStatus === 'DELIVER_FAIL') {
          return 'Entrega Falhou'
        } else if (reqStatus === 'WAITING_RETURN') {
          return 'Aguardando Devolução'
        } else if (reqStatus === 'RETURN_SENT') {
          return 'Devolução Enviada '
        } else if (reqStatus === 'RETURNED') {
          return 'Devolvido'
        } else if (reqStatus === 'RETURN_FAIL') {
          return 'Falha na Devolução'
        }
        return 'Não Enviado'
      },
      openWindow(url) {
        try {
          window.open(url)
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do conteúdo, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      isEditButtonDisabled(request) {
        return ['ACTIVE', 'NOT_APPROVED', 'CANCELED_BY_CUSTOMER', 'CANCELED_BY_VENDOR'].includes(request.status) ||
          ['WAITING_ADIQ_APPROVAL'].includes(request.ownProcessStatus)
      },
      hasAuthorization(role){
        return isAuthorized(role);
      },
      hasAnyAuthorization(roles){
        return isSomeAuthorization(roles);
      }
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }
</style>
