<template>
  <div class="dzs-acc-cancel">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Cancelar Vendas Online de PIX</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-3">
            <label class="control-label">ID do Bank</label>
            <fg-input v-model="search.requestNumber"/>
          </div>
          <div class="col-md-3">
            <fg-input type="text"
                      label="CPF/CNPJ Cliente"
                      v-mask="['###.###.###-##','##.###.###/####-##']"
                      v-model="search.document">
            </fg-input>
          </div>
          <div class="col-md-2">
            <div class="row" style="margin-left: 0px">
              <label>Data da Venda</label>
            </div>
            <div class="row" style="margin-left: 0px">
              <el-date-picker v-model="search.saleDate" type="date" placeholder="Escolha uma data"
                              :picker-options="dateOptsStart" format="dd/MM/yyyy">
              </el-date-picker>
            </div>
          </div>
          <div class="col-sm-2">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in [50, 100, 200]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px;"
                      @click="find">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="60" label="Pedido">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.requestNumber}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="85" label="Data Pag.">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.payDate}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="85" label="ID do Bank">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.ourNumber}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="100" label="Nome do Pagador">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.payingName}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Doc. Pag.">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.payingDocument}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Usuário">
                <template slot-scope="props">
                    <span>
                      <span>@{{props.row.username}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Valor">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.value)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Status">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.status}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :width="40" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="primary" size="sm" icon title="Dados do Pix"
                            @click="loadPixData(props.row)">
                    <i class="fa-solid fa-search"></i>
                  </p-button>
                </template>
              </el-table-column>
              <el-table-column :width="40" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="warning" size="sm" icon title="Cancelar"
                            @click="cancelTransaction(props.row.id)"
                            :disabled="(props.row.status!='Pago')">
                    <i class="fa-solid fa-ban"></i>
                  </p-button>
                </template>
              </el-table-column>
              <el-table-column :width="40" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="info" size="sm" icon title="Ver Comprovante"
                            @click="printReceipt(props.row.idTransaction)">
                    <i class="fa-solid fa-file-invoice"/>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
    <modal :show.sync="modal"
           footerClasses="justify-content-center"
           type="notice">
      <h6 style="text-align: center"><b>Dados do PIX</b></h6>
      <p>ID Stark: {{pix.ourNumber}}</p>
      <p>Data da Venda: {{pix.saleDate}}</p>
      <p>Data do Pagamento: {{pix.payDate}}</p>
      <p>Data da Devolução: {{pix.reversalDate}}</p>
      <p>Nome do Comprador: {{pix.saleName}}</p>
      <p>Documento do Comprador: {{maskDocument(pix.saleDocument)}}</p>
      <p>Nome do Pagador: {{pix.payingName}}</p>
      <p>Documento do Pagador: {{maskDocument(pix.payingDocument)}}</p>
      <p>Telefone: {{maskPhone(pix.phoneNumber)}}</p>
    </modal>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {toMoney, isNullOrEmpty, notNull} from "src/util/core.utils"
  import {formatDate} from "src/util/date.utils"
  import {DatePicker} from 'element-ui'
  import {Modal} from 'src/components/UIComponents'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination,
      [DatePicker.name]: DatePicker,
      Modal
    },
    data() {
      return {
        modal: false,
        pix: {
          saleDate: '',
          payDate: '',
          reversalDate: '',
          payingName: '',
          payingDocument: '',
          saleName: '',
          saleDocument: '',
          phoneNumber: '',
          ourNumber: '',
        },
        search: {
          requestNumber: '',
          document: '',
          saleDate: '',
        },
        cancel: {
          description: '',
        },
        tableData: [],
        pagination: {
          perPage: 50,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        dateOptsStart: {
          disabledDate: this.disabledStartDate,
          shortcuts: [{
            text: 'Hoje',
            onClick(picker) {
              const date = new Date()
              picker.$emit('pick', date)
            }
          },
            {
              text: 'Ontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
              }
            },
            {
              text: 'Anteontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 48)
                picker.$emit('pick', date)
              }
            }]
        },
      }
    },
    watch:{
      '$route': 'resetDefaultValues'
    },

    methods: {
      toMoney,
      isNullOrEmpty,
      resetDefaultValues(){
        this.search.requestNumber = ''
        this.search.document = ''
        this.search.saleDate = ''
        this.pagination.currentPage = 1
        this.pagination.perPage = 5
        this.pagination.pageMaxNumber = 1
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
        this.pagination.totalNumber = 0
        this.tableData = [];
        this.loadData()
      },
      find() {
        this.pagination.currentPage = 1
        this.loadData()
      },
      loadData() {
        postWs("/bo/gateway/consult/pix",
          true, null,
          {
            requestNumber: this.search.requestNumber,
            document: this.search.document,
            saleDate: notNull(this.search.saleDate, '')==''?'':formatDate(this.search.saleDate, "DD/MM/YYYY"),
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          (error) => {
            let text = 'Erro ao carregar os dados, tente novamente!'
            if(error.response.status == 404) {
              text = "Transação não encontrada para os dados informados!"
            }
            swal({
              title: 'Aviso!',
              text: text,
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            this.tableData = []
          })
        return this.pagination.currentPage
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      fail(){
        swal('Erro', 'Erro ao carregar os dados, tente novamente!', 'error')
      },
      cancelTransaction(saleId) {
        swal({
          title: 'Cancelar Transação',
          text: 'Escreva o motivo de realizar esse cancelamento:',
          input: 'text',
          buttonsStyling: true,
          confirmButtonClass: 'btn-success btn-fill',
          showCancelButton: true,
          confirmButtonColor: '#00c390',
          cancelButtonColor: '#ef8157',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          width: 500,
          preConfirm: (description) => {
            if(isNullOrEmpty(description)){
              swal({
                title: 'Atenção!',
                text: 'Favor preencher o motivo do cancelamento.',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'warning'
              })
            }else {
              this.description = description
              this.callCancel(saleId)
            }
          }
        })
      },
      callCancel(idPix) {
        postWs("/bo/gateway/cancel/pix",
          true, null,
          {
            id: idPix,
            description: this.description
          }, (response) => {
            swal({
              title: 'Sucesso!',
              text: 'Sucesso ao cancelar transação!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'success'
            })
            this.loadData()
          },
          (error) => {
            let text = 'Erro ao cancelar transação!'
            if(error.response.status == 400) {
              text = "Falha de comunicação com o banco!"
            }
            swal({
              title: 'Erro!',
              text: text,
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'error'
            })
          })
        return this.pagination.currentPage
      },
      disabledStartDate(date) {
        let now = new Date()
        return date > now
      },
      maskPhone(phone) {
        if(phone.length == 11) {
          return '(' + phone.substr(0, 2) + ') ' + phone.substr(2, 5) + '-' + phone.substr(7, 4)
        } else {
          return '(' + phone.substr(0, 2) + ') ' + phone.substr(2, 4) + '-' + phone.substr(6, 4)
        }
      },
      maskDocument(document) {
        if(document.length == 11) {
          return document.substr(0, 3) + '.' + document.substr(3, 3) + '.' + document.substr(6, 3) + '-' + document.substr(9, 2)
        } else {
          return '(' + document.substr(0, 2) + ') ' + document.substr(2, 4) + '-' + document.substr(6, 4)
        }
      },
      loadPixData(pix) {
        this.pix.ourNumber = pix.ourNumber
        this.pix.saleDate = pix.saleDate
        this.pix.payDate = pix.payDate
        this.pix.reversalDate = pix.reversalDate
        this.pix.payingName = pix.payingName
        this.pix.payingDocument = pix.payingDocument
        this.pix.saleName = pix.saleName
        this.pix.saleDocument = pix.saleDocument
        this.pix.phoneNumber = pix.phoneNumber
        this.modal = true
      },
      printReceipt(id) {
        postWs("/pix/get-receipt-pix",
          true,
          {idTransaction: id}, null,
          this.successPrintReceipt,
          (error) => {
            if (error.response.status == 404) {
              swal({
                title: 'Aviso!',
                text: 'Transação não encontrada!',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
              return;
            }
            swal({
              title: 'Aviso!',
              text: 'Erro ao gerar o comprovante, tente novamente!',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          })
      },
      loadDocSrc(pdfBase64) {
        return this.base64PDFToBlobUrl(pdfBase64)
      },
      base64PDFToBlobUrl(base64) {
        const binStr = atob(base64);
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
        const blob = new Blob([arr], {type: 'application/pdf'});
        return URL.createObjectURL(blob);
      },
      successPrintReceipt(response) {
        try {
          let pdfWindow = window.open("about:blank");
          pdfWindow.document.write("<html lang='pt-BR'><head><title>3RBank - Comprovante</title><style>body{margin: 0;}iframe{border-width: 0;}</style></head>");
          pdfWindow.document.write("<body><iframe style='width:100% ; height:100% ' src='" + this.loadDocSrc(response.data.pdfBase64) + "'></iframe></body></html>");
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do comprovante, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      }
    }
  }
</script>
<style lang="scss">
  .dzs-acc-cancel {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }
</style>
