<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Gerenciar Bandeiras</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-5">
            <label class="control-label">Nome da Bandeira</label>
            <fg-input v-model="search.key"/>
          </div>
          <div class="col-sm-2">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in [5,10,25,50]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px"
                      @click="find">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-md-3">
            <p-button type="success" style="margin-top: 25px; float: right"
                      @click="insertForm">
              <i slot="label" class="nc-icon nc-simple-add"></i>
              Nova Bandeira
            </p-button>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="80" label="Nome">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.name}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Status">
                <template slot-scope="props">
                    <span>
                      <span v-if="props.row.enabled">ATIVO</span>
                      <span v-if="!props.row.enabled">INATIVO</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :width="70" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="warning" size="sm" icon title="Editar"
                            @click="edit(props.row.id, props.row.name, props.row.enabled, props.row.planName,
                            props.row.planId)">
                    <i class="fas fa-edit"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12" v-show="formEditShow" style="max-width: 800px; margin: 0 auto">
      <card>
        <h4 v-if="id!=''">Editar a Bandeira <b>{{name}}</b></h4>
        <h4 v-if="id==''">Inserir Nova Bandeira</h4>
        <form>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <fg-input type="text"
                          maxlength="100"
                          label="Nome da Bandeira"
                          name="Nome da Bandeira"
                          v-validate="{required: true}"
                          :error="getError('Nome da Bandeira')"
                          v-model="name">
                </fg-input>
              </div>
            </div>
            <div class="row form-group" style="margin-top: 20px">
              <div class="col-sm-3">
                <p-checkbox class="text-left" v-model="enabled">
                  <b>Bandeira Ativa</b>
                </p-checkbox>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <p-button type="info" @click.prevent="insertUpdatePlan">Salvar</p-button>
          </div>
        </form>
      </card>
    </div>
    <div class="col-md-12 card" v-show="formSearchPlan" style="max-width: 800px; margin: 0 auto">
      <div class="card-header">
        <h4>Selecionar Plano</h4>
      </div>
      <div class="card-body row">
        <div class="col-md-6">
          <label class="control-label">Nome da Plano</label>
          <fg-input v-model="searchPlan.key"/>
        </div>
        <div class="col-sm-3">
          <fg-input label="Exibir">
            <el-select
              class="select-default"
              v-model="paginationPlans.perPage"
              placeholder="Per page">
              <el-option
                class="select-default"
                v-for="item in [5,10,25,50]"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </fg-input>
        </div>
        <div class="col-md-3">
          <p-button type="success" style="margin-top: 25px"
                    @click="findPlans">
            <i slot="label" class="nc-icon nc-check-2"></i>
            Buscar
          </p-button>
        </div>
        <div class="col-sm-12 mt-2 clickable-rows">
          <el-table class="table-striped"
                    :data="tableDataPlans"
                    @row-click="selectDestination"
                    style="width: 100%">
            <el-table-column :min-width="60" label="ID">
              <template slot-scope="props">
                    <span>
                      <span><b>#{{props.row.id}}</b></span>
                    </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="100" label="Nome">
              <template slot-scope="props">
                    <span>
                      <span>{{props.row.planName}}</span>
                    </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="80" label="Status">
              <template slot-scope="props">
                    <span>
                      <span v-if="props.row.enabled">ATIVO</span>
                      <span v-if="!props.row.enabled">INATIVO</span>
                    </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">EXIBINDO DO {{paginationPlans.fromNumber}} AO {{paginationPlans.toNumber}}, DE
            {{paginationPlans.totalNumber}} REGISTROS.</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="paginationPlans.currentPage"
                        :per-page="paginationPlans.perPage"
                        :total="paginationPlans.totalNumber"
                        :click="this.loadDataPlans">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {callWs, failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {moneyToFloat, toMoney} from "../../../../../util/core.utils"
  import {Money} from 'v-money'


  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination,
      Money
    },
    data() {
      return {
        tableDataPlans: [],
        search: {
          key: ''
        },
        searchPlan: {
          key: ''
        },
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        },
        id: '',
        name: '',
        enabled: true,
        planId: '',
        formSearchPlan: false,
        planCheck: false,
        planNotFound: false,
        formEditShow: false,
        planName: '',
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        paginationPlans: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        }
      }
    },
    created() {
      this.loadData()
    },
    methods: {
      toMoney,
      find() {
        this.pagination.currentPage = 1
        this.loadData()
      },
      loadData() {
        this.id = ''
        postWs("/bo/card-terminal/list-card-terminal-banner",
          true, null,
          {
            key: this.search.key,
            pageNumber: this.pagination.currentPage - 1,
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          failWs)
        return this.pagination.currentPage
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      edit(id, name, enabled, planName, planId){
        this.formEditShow = true
        this.id = id
        this.name = name
        this.enabled = enabled
        this.planId = planId
        this.planName = planName
        this.planCheck = true
      },
      insertUpdatePlan(){
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            return
          }
          callWs("/bo/card-terminal/card-terminal-banner",
            "POST", null, true, null,
            {id: this.id, name: this.name, planId: this.planId, enabled: this.enabled},
            () => {
              swal({
                title: 'Sucesso!',
                text: 'Dados salvos com sucesso.',
                buttonsStyling: true,
                timer: 10000,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'success'
              })
              this.formEditShow = false
              this.loadData()
            }, (error) => {
              if (error.response.status == 409) {
                swal({
                  title: 'Aviso!',
                  text: 'Nome já existe para outra bandeira cadastrada!',
                  buttonsStyling: true,
                  timer: 5000,
                  confirmButtonClass: 'btn btn-warning btn-fill',
                  type: 'warning'
                })
                return
              }
              swal({
                title: 'Erro!',
                text: 'Erro interno, verifique os dados e tente novamente!',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-danger btn-fill',
                type: 'error'
              })
            })
        })
      },
      insertForm(){
        this.id = ''
        this.name = ''
        this.planId = ''
        this.planName = ''
        this.planCheck = false
        this.formEditShow = true
        this.formSearchPlan = false
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      findPlans() {
        this.paginationPlans.currentPage = 1
        this.loadDataPlans()
      },
      loadNewDataPlan(){
        this.planName = ''
        this.searchPlan.key = ''
        this.loadDataPlans()
      },
      loadDataPlans() {
        postWs("/bo/card-terminal/list-card-terminal-plans",
          true, null,
          {
            enabled: null,
            planBannerCard: null,
            key: this.searchPlan.key,
            pageNumber: this.paginationPlans.currentPage - 1,
            pageSize: this.paginationPlans.perPage
          }, this.successLoadDataPlans,
          failWs)
        return this.paginationPlans.currentPage
      },
      successLoadDataPlans(response) {
        this.formEditShow = false
        this.formSearchPlan = true
        this.tableDataPlans = response.data.list
        this.paginationPlans.totalNumber = response.data.totalNumber
        this.paginationPlans.pageMaxNumber = response.data.pageMaxNumber
        if (this.paginationPlans.totalNumber > 0) {
          this.paginationPlans.fromNumber = ((this.paginationPlans.perPage * (this.paginationPlans.currentPage - 1)) + 1)
          this.paginationPlans.toNumber = ((this.paginationPlans.fromNumber + this.tableDataPlans.length) - 1)
        } else {
          this.paginationPlans.fromNumber = 0
          this.paginationPlans.toNumber = 0
        }
      },
      searchDataPlan() {
        postWs("/bo/card-terminal/search-card-terminal-plan",
          true, {name: this.planName},
          null, this.successSearchPlan,
          () => {
            swal({
              title: 'Aviso!',
              text: 'Nenhum plano encontrado com esse nome!',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            this.planNotFound = true
            this.planCheck = false
          })
      },
      successSearchPlan(response) {
        this.tableDataPlan = response.data
        this.planId = this.tableDataPlan.id
        this.planNotFound = false
        this.planCheck = true
      },
      selectDestination(destination) {
        this.planId = destination.id
        this.planName = destination.planName
        this.formEditShow = true
        this.formSearchPlan = false
        this.planNotFound = false
        this.planCheck = true
      }
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 5px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .input-group-text {
      padding: 5px 5px 5px 5px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }

  .clickable-rows {
    tbody tr td {
      cursor: pointer;
    }

    .el-table__expanded-cell {
      cursor: default;
    }
  }

  .btn.btn-primary{
    margin-right: 10px;
  }
</style>
