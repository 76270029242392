<template>
  <div class="login-page dzs-recovery">
    <div class="full-page login-page section-image sb-login-page">
      <div class="content container-fluid">
        <div class="row">
          <div class="col-lg-6 content-wrapper">
            <div>
              <img src="/static/img/login/3RBankLogo.svg" alt="">
            </div>
          </div>
          <div class="col-lg-6 d-flex align-items-center justify-content-center">
            <form class="card-recovery-wrapper">
              <div class="card-recovery__header">
                <div class="text-primary">
                  <span>Digite a sua nova senha!</span>
                </div>
              </div>
              <div class="card-recovery">
                <div class="div-recovery">
                  <fg-input type="password"
                            placeholder="Nova Senha"
                            name="Nova Senha" ref="password"
                            v-validate="{required: true, min: 8}"
                            :error="getError('Nova Senha')"
                            v-model="password">
                  </fg-input>
                  <fg-input type="password"
                            placeholder="Confirme a Senha"
                            name="Confirme a Senha"
                            v-validate="{required: true, confirmed: 'password'}"
                            :error="getError('Confirme a Senha')"
                            v-model="confirmPassword">
                  </fg-input>
                </div>
              </div>
              <p-button native-type="submit" slot="footer" name="submit"
                        type="success" class="btn btn-outline-primary btn-round"
                        @click.prevent="recovery">SALVAR NOVA SENHA
              </p-button>
            </form>
          </div>
        </div>
      </div>
      <div class="bg-wrapper">
        <img class="bg-image" src="/static/img/login/bgLogin.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
  import {Button, Card, Checkbox} from 'src/components/UIComponents'
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'
  import swal from 'sweetalert2'
  import {failWs, postWs} from 'src/ws.service'

  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button,
    },
    data() {
      return {
        key: '',
        password: '',
        confirmPassword: ''
      }
    },
    created() {
      this.key = this.$route.params.key
    },
    methods: {
      recovery() {
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            postWs("/auth/password-recovery", false, null,
              {hash: this.key, newPassword: this.password},
              this.successRecovery, this.failRecovery)
          }
        })
      },
      successRecovery() {
        swal({
          title: "Senha Alterada",
          text: "Você já pode acessar sua conta com a nova senha.",
          buttonsStyling: true,
          closeOnClickModal: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: "success"
        })
        this.$router.push('/login')
      },
      failRecovery(error) {
        if (error.response.status == 404) {
          swal({
            title: "O prazo expirou!",
            text: "O tempo para recuperação de senha usando este link expirou. Favor re-solicitar a recuperação de senha.",
            buttonsStyling: true,
            closeOnClickModal: false,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: "warning"
          })
          this.$router.push('/forgot')
          return
        }
        failWs(error)
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      }
    }
  }
</script>
<style lang="scss">
  .dzs-recovery {
    min-height: 100vh;
    font-family: 'Uncut Sans', sans-serif;
    display: flex;
    align-items: center;

    .content-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      > div {
        animation: .8s in ease-in-out forwards;

        img {
          max-height: 4rem;
          width: 100%;
        }
      }
    }

    .full-page .content {
      padding-top: 0 !important;
      max-width: 120rem;
      margin: 0 auto;
    }

    .card-recovery-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: end;
      max-width: 31.5rem;

      button {
        align-self: end;
      }

      .form-group:last-child {
        margin: 0 !important;
      }
    }

    .card-recovery {
      border-radius: 1rem !important;
      width: 100%;
      max-width: 31.5rem;
      text-align: center;
      padding: 1rem !important;
      border: 1px solid #e1bb80;

      input {
        background: #e2e2e2 !important;
        color: #333333 !important;
        font-family: inherit;
        font-size: 1rem;
        line-height: 1.5;
      }
    }

    .card-recovery__header {
      font-size: 1.3rem;
      margin-bottom: 1rem;
    }

    .bg-wrapper {
      width: 100vw;
      min-height: 100vh;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
    }

    .bg-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0 !important;
    }

    @keyframes in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

</style>
