<template>
  <div class="dzs-card row">
    <div class="col-lg-12 col-md-7">
      <div class="row">
        <div class="col-md-12 card">
          <div class="card-body" style="padding-bottom: 30px">
            <form @submit.prevent="saveShipment">
              <div>
                <h4>Enviar Remessa</h4>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <fg-input type="text"
                            label="Usuário"
                            disabled="true"
                            v-model="request.username">
                  </fg-input>
                </div>
                <div class="col-md-7">
                  <fg-input type="email"
                            label="E-mail"
                            disabled="true"
                            v-model="request.email">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <fg-input type="tel"
                            label="Celular"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            v-model="request.phoneNumber1"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input type="tel"
                            label="Telefone"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            v-model="request.phoneNumber2"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <fg-input type="text"
                            label="Nome Completo"
                            v-model="request.name"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <div class="row" style="margin-top: 0px">
                <div class="col-md-5">
                  <fg-input label="Tipo de Pessoa"
                            type="text"
                            v-model="request.personType=='NATURAL_PERSON'?'Pessoa Física':'Pessoa Jurídica'"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-7">
                  <fg-input :label="request.personType=='NATURAL_PERSON'?'CPF':'CNPJ'"
                            type="tel"
                            v-mask="request.personType=='NATURAL_PERSON'?['###.###.###-##']:['##.###.###/####-##']"
                            name="CPF/CNPJ"
                            v-model="request.document"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <div v-if="request.personType=='LEGAL_PERSON'">
                <div class="row">
                  <div class="col-md-12">
                    <fg-input type="text"
                              label="Razão Social"
                              v-model="request.companyName"
                              disabled="true">
                    </fg-input>
                  </div>
                </div>
              </div>
              <div class="row" style="margin-top: 30px" >
                <div class="col-md-5">
                  <fg-input type="tel"
                            v-mask="['#####-###']"
                            label="CEP"
                            v-model="request.deliveryAddress.zipCode"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-7">
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <fg-input type="text"
                            label="Rua/Av."
                            v-model="request.deliveryAddress.street"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input type="tel"
                            v-mask="['######']"
                            label="Número"
                            v-model="request.deliveryAddress.number"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <fg-input type="text"
                            label="Complemento"
                            v-model="request.deliveryAddress.complement"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <fg-input type="text"
                            label="Bairro"
                            v-model="request.deliveryAddress.neighborhood"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-5">
                  <fg-input type="text"
                            label="Cidade"
                            v-model="request.deliveryAddress.city"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-2">
                  <fg-input type="text"
                            label="Estado"
                            v-model="request.deliveryAddress.state"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <br/>
              <div class="row">
                <div class="col-md-6">
                  <fg-input type="text"
                            label="Quantidade de Máquinas"
                            v-model="request.terminal.amount"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input type="text"
                            label="Máquina Escolhida"
                            v-model="request.terminal.nameModel"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <br/>
              <div class="card-body row">
                <div class="col-md-12">
                  <h6>Máquinas Para Envio</h6>
                </div>
                <div class="col-sm-12">
                  <el-table class="table-striped"
                            :data="tableData"
                            style="width: 100%">
                    <el-table-column :min-width="80" label="Serial">
                      <template slot-scope="props">
                        <span>
                          <span><b>#{{props.row.physicalId}}</b></span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="70" label="Modelo">
                      <template slot-scope="props">
                        <span>
                          <span>{{props.row.model.name}}</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="50" label="Customizada">
                      <template slot-scope="props">
                        <span>
                          <span v-if="props.row.themeCustomized">SIM</span>
                          <span v-if="!props.row.themeCustomized">NÃO</span>
                        </span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <br/>
              <div class="row">
                <div class="col-md-4">
                  <fg-input label="Transportadora">
                    <el-select size="large"
                               placeholder="Selecione uma transportadora"
                               v-model="formRequest.idShipmentService"
                               :disabled="disableForm">
                      <el-option v-for="option in request.shipmentServices"
                                 class="select-default"
                                 :value="option.id"
                                 :label="option.name"
                                 :key="option.name">
                      </el-option>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-md-5">
                  <fg-input type="text"
                            label="Código de Rastreio"
                            v-model="formRequest.dispatchTrackingCode"
                            :disabled="disableForm">
                  </fg-input>
                </div>
                <div class="col-md-3">
                  <label>Valor do Envio</label>
                  <money class="form-control input-lg"
                         v-model="formRequest.shipmentValue"
                         v-bind="money"
                         :disabled="disableForm">
                  </money>
                </div>
              </div>
              <br/>
              <div class="row">
                <div class="col-md-6">
                  <fg-input type="text"
                            label="Status Atual da Requisição"
                            v-model="request.requestStatus"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-6" v-show="!disableForm">
                  <fg-input label="Atualizar Status">
                    <el-select size="large"
                               v-model="formRequest.requestStatus">
                      <el-option value="SHIPPED"
                                 class="select-default"
                                 label="Enviado"/>
                      <el-option value="PARTIAL_SHIPPING"
                                 class="select-default"
                                 label="Enviado Parcial"/>
                    </el-select>
                  </fg-input>
                </div>
              </div>
              <br/>
              <div class="row">
                <div class="col-md-6" v-show="!disableFormDelivered">
                  <fg-input label="Atualizar Status de Envio">
                    <el-select size="large"
                               v-model="formRequest.transitStatus">
                      <el-option value="SENT"
                                 class="select-default"
                                 label="Enviado"/>
                      <el-option value="DELIVERED"
                                 class="select-default"
                                 label="Entregue"/>
                      <el-option value="DELIVER_FAIL"
                                 class="select-default"
                                 label="Falha na Entrega"/>
                      <el-option value="WAITING_RETURN"
                                 class="select-default"
                                 label="Aguardando Devolução"/>
                      <el-option value="RETURN_SENT"
                                 class="select-default"
                                 label="Devolução Enviada"/>
                      <el-option value="RETURNED"
                                 class="select-default"
                                 label="Devolvido"/>
                      <el-option value="RETURN_FAIL"
                                 class="select-default"
                                 label="Falha na Devolução"/>
                    </el-select>
                  </fg-input>
                </div>
              </div>
              <div class="card-footer text-center">
                <button type="submit" class="btn btn-info btn-fill btn-wd" :disabled="disableFormDelivered && disableForm">
                  Atualizar Envio
                </button>
              </div>
            </form>
            <div class="card-footer text-left">
              <button @click="$router.push('/bo/cardterminal/prepareshipment/'+idRequest)" class="btn btn-success btn-fill btn-wd">
                Preparar Remessa
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import {postWs, getWs, failWs, callWs} from 'src/ws.service'
  import swal from 'sweetalert2'
  import {isNullOrEmpty, moneyToFloat} from "../../../../../../util/core.utils";
  import {Table, TableColumn} from "element-ui";

  Vue.use(Table)
  Vue.use(TableColumn)

  export default {
    components: {},
    data() {
      return {
        idRequest: '',
        request: {
          username: '',
          name: '',
          email: '',
          document: '',
          phoneNumber1: '',
          phoneNumber2: '',
          companyName: '',
          personType: '',
          deliveryAddress: {
            codIbge: '',
            street: '',
            number: '',
            complement: '',
            zipCode: '',
            neighborhood: '',
            city: '',
            state: ''
          },
          plan: {
            carTerminalPlan: '',
            cardTerminalPlanLabel: '',
          },
          terminal: {
            nameModel: '',
            amount: '',
          },
          billingRange: '',
          partner: {
            username: '',
            overprice: 0,
          },
          requestStatus: '',
          shipmentServices: [],
          shipment: {
            transitStatus: '',
            dispatchShipmentService: {
              name: '',
            },
            shipmentValue: 0,
            dispatchTrackingCode: ''
          }
        },
        formRequest: {
          idRequest: '',
          requestStatus: 'SHIPPED',
          idShipmentService: '',
          dispatchTrackingCode: '',
          shipmentValue: 0,
          tablePhysicalId: [],
          transitStatus: '',
        },
        search: {
          physicalId: '',
          active: false,
        },
        tableData: [],
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        },
        disableForm: true,
        disableFormDelivered: true,
      }
    },

    created() {
      this.idRequest = this.$route.params.id
    },

    mounted() {
      this.loadData()
    },

    methods: {
      isNullOrEmpty,
      loadData() {
        getWs('/bo/card-terminal/get-card-terminal-request', true, {idRequest: this.idRequest}, this.loadRequestData, this.failRequestData)
      },
      loadRequestData(response) {
        this.formRequest.idRequest = response.data.idRequest

        this.request.requestStatus = this.reqStatusToLabel(response.data.requestStatus)
        if(response.data.requestStatus == 'PREPARING_SHIPMENT') {
          this.disableForm = false
          this.disableFormDelivered = false
        } else {
          this.formRequest.dispatchTrackingCode = response.data.shipment.dispatchTrackingCode
          this.formRequest.shipmentValue = response.data.shipment.shipmentValue
          this.formRequest.idShipmentService = response.data.shipment.dispatchShipmentService.id
          this.formRequest.transitStatus = response.data.shipment.transitStatus
          if(response.data.shipment.transitStatus == 'DELIVERED') {
            this.disableFormDelivered = true
          } else {
            this.disableFormDelivered = false
          }
        }
        this.request.username = response.data.username
        this.request.name = response.data.name
        this.request.email = response.data.email
        this.request.document = response.data.document
        this.request.phoneNumber1 = response.data.phoneNumber1
        this.request.phoneNumber2 = response.data.phoneNumber2
        this.request.companyName = response.data.companyName
        this.request.personType = response.data.personType
        this.request.deliveryAddress.codIbge = response.data.deliveryAddress.codIbge
        this.request.deliveryAddress.street = response.data.deliveryAddress.street
        this.request.deliveryAddress.number = response.data.deliveryAddress.number
        this.request.deliveryAddress.complement = response.data.deliveryAddress.complement
        this.request.deliveryAddress.zipCode = response.data.deliveryAddress.zipCode
        this.request.deliveryAddress.neighborhood = response.data.deliveryAddress.neighborhood
        this.request.deliveryAddress.city = response.data.deliveryAddress.city
        this.request.deliveryAddress.state = response.data.deliveryAddress.state

        if(response.data.shipment != null) {
          this.request.shipment.dispatchTrackingCode = response.data.shipment.dispatchTrackingCode
          this.request.shipment.shipmentValue = response.data.shipment.shipmentValue
          this.request.shipment.transitStatus = response.data.shipment.transitStatus
          this.request.shipment.dispatchShipmentService.name = response.data.shipment.dispatchShipmentService.name
        }

        this.request.plan.plans = response.data.plans
        this.request.plan.carTerminalPlan = response.data.cardTerminalPlan
        this.request.plan.cardTerminalPlanLabel = response.data.cardTerminalPlanLabel

        this.request.terminal.nameModel = response.data.nameModelRequestedTerminal
        this.request.terminal.amount = response.data.amountRequestedTerminal

        this.request.billingRange = response.data.billingRange

        if(response.data.usernamePartner != null) {
          this.request.partner.username = '@' + response.data.usernamePartner;
          this.request.partner.overprice = response.data.overpricePartner;
        }

        if(response.data.registeredTerminals != null) {
          this.tableData = response.data.registeredTerminals
        }

        if(response.data.shipmentServices != null) {
          this.request.shipmentServices = response.data.shipmentServices
        }
      },
      failRequestData(){
        swal('Erro', 'Erro ao carregar os dados da solicitação!', 'error')
      },
      findTerminal(){
        if(this.request.terminal.amount == this.tableData.length) {
          swal({
            title: 'Aviso',
            text: 'O número de máquinas cadastradas é o mesmo de máquinas solicitadas, cadastrar mais máquinas?',
            buttonsStyling: true,
            confirmButtonClass: 'btn-success btn-fill',
            showCancelButton: true,
            confirmButtonColor: '#667eea',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            width: 400
          }).then((result) => {
            if (result) {
              this.loadTerminal()
            }
          })
        } else {
          this.loadTerminal()
        }
      },
      loadTerminal(){
        this.search.active = true
        this.tableData.forEach(value => {
          if(value.physicalId == this.search.physicalId) {
            swal('Aviso', 'Terminal já foi incluído na lista!', 'warning')
            this.search.active = false
          }
        })
        if(this.search.active) {
          getWs('/bo/card-terminal/card-terminal-shipment', true, {physicalId: this.search.physicalId}, this.successLoadTerminal, this.failLoadTerminal)
        }
        this.search.physicalId = ''
      },
      successLoadTerminal(response){
        this.tableData.push(response.data)
      },
      failLoadTerminal(error){
        let text = 'Erro ao carregar os dados do terminal!'
        if(error.response.status == '404') {
          text = 'Terminal não encontrado!'
        } else if(error.response.status == '428') {
          text = 'Terminal já está cadastrado em um plano!'
        }
        swal('Erro', text, 'warning')
      },
      deleteList(physicalId){
        let index = 0
        this.tableData.forEach(value => {
          if(value.physicalId == physicalId) {
            this.tableData.splice(index, 1);
          }
          index++
        })
      },
      saveShipment() {
        if (this.tableData.length == 0) {
          swal({
            title: 'Aviso!',
            text: 'Devem ser cadastradas uma ou mais máquinas para envio!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        this.formRequest.tablePhysicalId = []
        this.tableData.forEach(value => {
          this.formRequest.tablePhysicalId.push(value.physicalId)
        })
        callWs("/bo/card-terminal/edit-card-terminal-send-shipment",
          "POST", null, true, null,
          this.formRequest, () => {
            swal({
              title: 'Sucesso!',
              text: 'Dados salvos com sucesso.',
              buttonsStyling: true,
              timer: 10000,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'success'
            }), this.loadData()},
          (error) => {
            swal({
              title: 'Erro!',
              text: 'Erro, verifique os dados e tente novamente!',
              buttonsStyling: true,
              timer: 10000,
              confirmButtonClass: 'btn btn-danger btn-fill',
              type: 'error'
            })
          })
      },
      reqStatusToLabel(reqStatus) {
        if (reqStatus == 'WAITING_APPROVAL') {
          return 'Aguardando Aprovação'
        } else if (reqStatus == 'APPROVED') {
          return 'Aprovado'
        } else if (reqStatus == 'PREPARING_SHIPMENT') {
          return 'Aguardando Envio'
        } else if (reqStatus == 'SHIPPED') {
          return 'Enviado'
        } else if (reqStatus == 'PARTIAL_SHIPPING') {
          return 'Envio Parcial'
        } else if (reqStatus == 'NOT_APPROVED') {
          return 'Não Aprovado'
        } else if (reqStatus == 'CANCELED_BY_CUSTOMER') {
          return 'Cancelado Pelo Cliente'
        } else if (reqStatus == 'CANCELED_BY_VENDOR') {
          return 'Cancelado Pelo Vendedor'
        } else if (reqStatus == 'ACTIVE') {
          return 'Ativo'
        }
        return 'Não Enviado'
      },
    }
  }
</script>

<style lang="scss">
  .dzs-card {
    .card-title {
      margin-top: 0px;
    }
  }

</style>
