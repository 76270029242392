<template>
  <div class="dzs-acc-consult-tr">
    <div class="row" v-show="!formRequestNotFound">
      <div class="col-md-12">
        <p>Relatório Mensal:</p>
      </div>
      <div :class="'col-lg-4 col-md-12'" v-for="stats in {valueMonth}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
        </stats-card>
      </div>
      <div :class="'col-lg-4 col-md-12'" v-for="stats in {netValueMonth}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
        </stats-card>
      </div>
      <div :class="'col-lg-4 col-md-12'" v-for="stats in {totalMonth}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="stats.value">
        </stats-card>
      </div>
      <div :class="'col-lg-4 col-md-12'" v-for="stats in {valuePaidMonth}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
        </stats-card>
      </div>
      <div :class="'col-lg-4 col-md-12'" v-for="stats in {valueUnpaidMonth}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
        </stats-card>
      </div>
      <div :class="'col-lg-4 col-md-12'" v-for="stats in {valueCanceledMonth}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
        </stats-card>
      </div>

      <div class="col-md-12 card">
        <div class="row card">
          <div class="card-header">
            <h4 style="margin-top: 0px">Relatório Financeiro</h4>
          </div>
          <div class="card-body row" style="padding-left: 25px;">
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Início</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsStart" @input="validatePeriod"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Fim</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsEnd"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-sm-2">
              <fg-input label="Exibir">
                <el-select
                  class="select-default"
                  v-model="pagination.perPage"
                  placeholder="Por Página">
                  <el-option
                    class="select-default"
                    v-for="item in [100,500,1000,5000]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <p-button type="success" style="margin-top: 25px"
                        @click="find">
                <i slot="label" class="fas fa-search-dollar"></i>
                Buscar
              </p-button>
            </div>
            <div class="col-md-3" v-if="this.requestExcel.enabled">
              <p-button type="success" style="margin-top: 25px"
                        @click="generateExcel">
                <i slot="label" class="fas fa-file-invoice"></i>
                Exportar Excel
              </p-button>
            </div>
          </div>
          <div class="row" style="padding-left: 25px; padding-right: 25px">
            <div class="col-md-12">
              <p-checkbox class="text-left" v-model="advancedSearch">
                <b>Busca Avançada.</b>
              </p-checkbox>
            </div>
            <div class="col-md-12" v-show="advancedSearch">
              <div class="row">
                <div class="col-md-3">
                  <fg-input label="Status">
                    <el-select size="large"
                               placeholder="Selecione"
                               v-model="search.statusTransaction">
                      <el-option class="select-info"
                                 value="PAID"
                                 label="Pago"/>
                      <el-option class="select-info"
                                 value="WAITING_PAYMENT"
                                 label="Aguardando Pagamento"/>
                      <el-option class="select-info"
                                 value="REJECT"
                                 label="Rejeitado"/>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-md-3">
                  <fg-input label="Bandeira">
                    <el-select size="large"
                               placeholder="Selecione"
                               v-model="search.bannerCard">
                      <el-option v-for="option in bannerList"
                                 class="select-default"
                                 :value="option"
                                 :label="option"
                                 :key="option">
                      </el-option>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-md-3">
                  <fg-input label="Máquina">
                    <el-select size="large"
                               placeholder="Selecione"
                               v-model="search.terminalNumber">
                      <el-option v-for="option in cardTerminalList"
                                 class="select-default"
                                 :value="option"
                                 :label="option"
                                 :key="option">
                      </el-option>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-md-3">
                  <fg-input label="Tipo">
                    <el-select size="large"
                               placeholder="Selecione"
                               v-model="search.transactionType">
                      <el-option class="select-info"
                                 value="DEBIT"
                                 label="Débito"/>
                      <el-option class="select-info"
                                 value="CREDIT"
                                 label="Crédito à Vista"/>
                      <el-option class="select-info"
                                 value="INSTALLED_CREDIT"
                                 label="Crédito Parcelado"/>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-md-5">
                  <fg-input type="text"
                            label="NSU"
                            v-model="search.nsu">
                  </fg-input>
                </div>
                <div class="col-md-7" style="text-align: right">
                  <p-button type="danger" style="margin-top: 25px"
                            @click="clearSearchData">
                    <i slot="label" class="nc-icon nc-check-2"></i>
                    Limpar
                  </p-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row card">
            <div class="row col-md-12" style="margin-bottom: 15px; margin-top: 20px; text-align: center">
              <div class="box3" style="text-align: left">
                <p><i style="color: #001a3f" class="fas fa-arrow-circle-right"></i> Venda Bruta: R$ {{toMoney(valueTransactions)}}<br>
                  <i style="color: #001a3f" class="fas fa-arrow-circle-right"></i> Venda Líquida: R$ {{toMoney(netValueTransactions)}}</p>
              </div>
              <div class="box3" style="text-align: left">
                <p><i style="color: #001a3f" class="fas fa-arrow-circle-right"></i> Vendas Pagas: R$ {{toMoney(valuePaidTransaction)}}<br>
                  <i style="color: #001a3f" class="fas fa-arrow-circle-right"></i> Vendas à Receber: R$ {{toMoney(valueUnpaidTransaction)}}</p>
              </div>
              <div class="box3" style="text-align: left">
                <p><i style="color: #001a3f" class="fas fa-arrow-circle-right"></i> Vendas Rejeitadas: R$ {{toMoney(valueCanceledTransaction)}}</p>
              </div>
            </div>
        </div>

        <div class="row card">
          <div class="col-sm-12">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="80" label="Data/Hora Venda">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.transactionDateTime}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Tipo Transação">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.transactionType}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="60" label="Bandeira">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.bannerCard}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="60" label="Valor Bruto">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.transactionAmount)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="60" label="Valor Líquido">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.netValue)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Taxa">
                <template slot-scope="props">
                    <span>
                      <span>{{toMoney(props.row.fee)}}%</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Status">
                <template slot-scope="props">
                    <span>
                      <span>{{statusTranslate(props.row.statusTransaction)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Terminal">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.terminalNumber}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="NSU">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.nsu}}</span>
                    </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="row" style="padding: 10px 10px 10px 10px">
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-show="formRequestNotFound">
      <div class="col-lg-9">
        <card>
          <div class="row" style="margin-bottom: 30px">
            <div class="col-md-4" style="text-align: right; font-size: 20px; color: #667eea; margin-top: 25px">
              <i class="fa fa-exclamation-triangle fa-10x"></i>
            </div>
            <div class="col-md-8" style="text-align: center">
              <h4 slot="header" class="card-title">Não Existem Solicitações de Máquinas</h4>
              <p style="margin: 20px 60px 0px 60px;">Você não realizou nenhuma solicitação de máquina de cartão <b style="color: #000000 !important;">3RBank</b>.<br><br>
                Para realizar o seu pedido <a @click="$router.push('/card-terminal/request')" href="#">clique aqui</a>.<br><br>
                Obrigado por escolher a <b style="color: #000000 !important;">3RBank</b></p>
            </div>
          </div>
        </card>
      </div>
<!--      <div class="col-lg-6">-->
<!--        <card align="center" style="background-color: #FFFFFF; height: 100%;">-->
<!--          <img style="max-width: 100%" src="/static/img/cardterminal/mockup_maquinas.png" alt="...">-->
<!--        </card>-->
<!--      </div>-->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {callWs, failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {toMoney, moneyToFloat} from "../../../../util/core.utils";
  import {DatePicker} from 'element-ui'
  import {daysBetween, formatDate} from "../../../../util/date.utils";
  import ChartCard from 'src/components/UIComponents/Cards/ChartCard'
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard'
  import download from 'downloadjs'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination,
      [DatePicker.name]: DatePicker,
      StatsCard,
      ChartCard,
    },
    data() {
      return {
        valueMonth: {
          type: 'success',
          icon: 'nc-icon nc-money-coins',
          title: 'Venda Bruta',
          value: 0,
          footerText: '',
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        netValueMonth: {
          type: 'success',
          icon: 'fa fa-money',
          title: 'Venda Líquida',
          value: 0,
          footerText: '',
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        totalMonth: {
          type: 'success',
          icon: 'fa fa-line-chart',
          title: 'Total de Vendas',
          value: 0,
          footerText: '',
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        valuePaidMonth: {
          type: 'success',
          icon: 'fa fa-check',
          title: 'Vendas Pagas',
          value: 0,
          footerText: '',
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        valueUnpaidMonth: {
          type: 'success',
          icon: 'fa fa-refresh',
          title: 'Vendas à Receber',
          value: 0,
          footerText: '',
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        valueCanceledMonth: {
          type: 'success',
          icon: 'fa fa-ban',
          title: 'Vendas Rejeitadas',
          value: 0,
          footerText: '',
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        startDate: new Date(),
        endDate: new Date(),
        dateOptsStart: {
          disabledDate: this.disabledStartDate,
          shortcuts: [{
            text: 'Hoje',
            onClick(picker) {
              const date = new Date()
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
            {
              text: 'Ontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
                this.endDate = date
              }
            },
            {
              text: 'Anteontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 48)
                picker.$emit('pick', date)
                this.endDate = date
              }
            }]
        },
        dateOptsEnd: {
          disabledDate: this.disabledEndDate
        },
        search: {
          statusTransaction: null,
          bannerCard: null,
          transactionType: null,
          terminalNumber: null,
          nsu: null,
        },
        modelCheck: false,
        modelNotFound: false,
        tableData: [],
        pagination: {
          perPage: 100,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        formRequestNotFound: false,
        valueTransactions: 0,
        netValueTransactions: 0,
        valuePaidTransaction: 0,
        valueUnpaidTransaction: 0,
        valueCanceledTransaction: 0,
        advancedSearch: false,
        cardTerminalList: [],
        bannerList: [],
        requestExcel: {
          listTr: [],
          startDate: '',
          endDate: '',
          enabled: false,
        },
        username: '',
      }
    },
    created() {
      this.username = localStorage.getItem("usernameDisplay")
      this.$validator.extend('leaseValueValid', {
        getMessage: field => (field + ' informada é inválida, informe um valor maior que Zero.'),
        validate: (value) => {
          if (moneyToFloat(value) == 0) {
            return false
          } else {
            return true
          }
        }
      })

      this.$validator.extend('purchaseValueValid', {
        getMessage: field => (field + ' informada é inválida, informe um valor maior que Zero.'),
        validate: (value) => {
          if (moneyToFloat(value) == 0) {
            return false
          } else {
            return true
          }
        }
      })

      this.loadData()
    },
    methods: {
      toMoney,
      find() {
        if(daysBetween(this.startDate, this.endDate) > 31){
          swal({
            title: 'Aviso!',
            text: 'O período selecionado deve ser de no máximo 31 dias!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        } else {
          this.pagination.currentPage = 1
          this.loadData()
        }
      },
      loadData() {
        postWs("/card-terminal/transaction-report",
          true, null,
          {
            statusTransaction: this.search.statusTransaction,
            bannerCard: this.search.bannerCard,
            transactionType: this.search.transactionType,
            terminalNumber: this.search.terminalNumber,
            nsu: this.search.nsu,
            startDate: formatDate(this.startDate, "DD/MM/YYYY"),
            endDate: formatDate(this.endDate, "DD/MM/YYYY"),
            pageNumber: this.pagination.currentPage - 1,
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          this.failLoadData)
        return this.pagination.currentPage
      },
      failLoadData(error) {
        this.formRequestNotFound = true
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.requestExcel.listTr = response.data.list
        if(this.tableData != null && response.data.list.length > 0) {
          this.requestExcel.enabled = true
        }
        this.requestExcel.startDate = formatDate(this.startDate, "DD_MM_YYYY")
        this.requestExcel.endDate = formatDate(this.endDate, "DD_MM_YYYY")
        this.valueMonth.value = response.data.valueTransactionsMonth
        this.netValueMonth.value = response.data.netValueTransactionsMonth
        this.valuePaidMonth.value = response.data.valuePaidTransactionMonth
        this.valueUnpaidMonth.value = response.data.valueUnpaidTransactionMonth
        this.valueCanceledMonth.value = response.data.valueCanceledTransactionMonth
        this.totalMonth.value = response.data.totalTransactionsMonth
        this.valueTransactions = response.data.valueTransactions
        this.netValueTransactions = response.data.netValueTransactions
        this.valuePaidTransaction = response.data.valuePaidTransaction
        this.valueUnpaidTransaction = response.data.valueUnpaidTransaction
        this.valueCanceledTransaction = response.data.valueCanceledTransaction
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        this.cardTerminalList = response.data.cardTerminalList
        this.bannerList = response.data.bannerList
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      validatePeriod() {
        this.endDate = this.startDate
      },
      clearSearchData() {
        this.search.statusTransaction = null
        this.search.nsu = null
        this.search.terminalNumber = null
        this.search.bannerCard = null
        this.search.transactionType = null
      },
      statusTranslate(status){
        let retorno = ''
        switch (status) {
          case 'PAID':
            retorno = 'Pago';
            break;
          case 'WAITING_PAYMENT':
            retorno = 'Ag. Pagamento';
            break;
          case 'REJECT':
            retorno = 'Rejeitado';
            break;
        }
        return retorno
      },
      generateExcel() {
        callWs("/card-terminal/transaction-report-excel",
          "POST", null, true, null, this.requestExcel,
          this.successGenerateExcel,
          (error) => {
            swal({
              title: 'Aviso!',
              text: 'Erro ao gerar o Excel do extrato, tente novamente!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          })
      },
      successGenerateExcel(response) {
        try {
          download("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+response.data,
            "transacoes_cartao_"+this.username+"_"+this.requestExcel.startDate+"_a_"+this.requestExcel.endDate+".xlsx", "vnd.openxmlformats-officedocument.spreadsheetml.sheet")
        } catch (ex) {
          swal({
            title: 'Erro!',
            text: "Erro ao gerar o relatório em Excel, favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      disabledStartDate(date) {
        let now = new Date()
        return date > now
      },
      disabledEndDate(date) {
        let now = new Date()
        return date > now || date < this.startDate
      },
    }
  }
</script>
<style lang="scss">
  .dzs-acc-consult-tr {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 5px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .input-group-text {
      padding: 5px 5px 5px 5px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    .card-stats .card-body .numbers {
      font-size: 18px !important;
    }

    .box3 {
      width: 31%;
      margin-left: 2.1%;
    }

    @media (max-width: 1366px) {
      .box3 {
        width: 31%;
        margin-left: 2.1%;
      }
    }

    @media (max-width: 500px) {
      .box3 {
        width: 90%;
        margin-left: 2.1%;
      }
    }

    .clickable-rows {
      tbody tr td {
        cursor: pointer;
      }

      .el-table__expanded-cell {
        cursor: default;
      }
    }

    .btn.btn-primary{
      margin-right: 10px;
    }

    .el-input__inner {
      background-color: white !important;
    }
  }
</style>
