<template>
  <div class="dzs-ted">
    <div class="row">
      <div class="col-md-5">
        <card style="padding: 5%; height: 100%">
          <h4 slot="header" class="card-title">
            Transferências Internacionais
          </h4>
          <bannerCard></bannerCard>
          <br>
          <br>
          <p>
            As solicitações podem ser realizadas de segunda a sexta das 08h00 as 15h30.
          </p>
          <p>
            Abaixo alguns fatores que podem impactar na efetivação das transferências de câmbio:
          </p>
          <p><i style="color: #001a3f" class="fas fa-arrow-circle-right"></i> Os pagamentos são realizados apenas para contas PJ no exterior.<br>
            <i style="color: #001a3f" class="fas fa-arrow-circle-right"></i> Informe o valor desejado para envio e verifique se possui saldo em conta.<br>
            <i style="color: #001a3f" class="fas fa-arrow-circle-right"></i> Caso o valor de envio desejado seja menor que o saldo disponível,
            realize um deposito em sua conta 3RBank por meio de PIX ou boleto.<br>
            <i style="color: #001a3f" class="fas fa-arrow-circle-right"></i> A cotação é baseada no dolar comercial, portanto verifique antes de finalizar a solicitação, pois
            pode haver variações.<br>
            <i style="color: #001a3f" class="fas fa-arrow-circle-right"></i> O tempo estimado de envio é de até um dia útil.<br>
            <i style="color: #001a3f" class="fas fa-arrow-circle-right"></i> Caso a solicitação seja estornada por erro nos dados informados, a taxa não será devolvida.</p>

          <p>
            <b>Precisa receber uma transferência internacional?</b> fale com nossos especialistas:
          </p>
          <div style="text-align: center; border: 1px solid; border-radius: 25px; border-color: #00d19a; height: 50px; padding-top: 10px">
            <a href="https://api.whatsapp.com/send?phone=55628003020202" target="_blank">Falar com Especialista</a>
          </div>
        </card>
      </div>


      <div class="col-md-7">
        <card v-show="isNullOrEmpty(exchange.qrCode)" style="height: 100%">
          <div class="row">
            <div class="col-md-4">
              <label class="control-label">Valor a enviar em Dólar</label>
              <money class="form-control input-lg"
                     v-model="exchange.foreignCurrencyValue"
                     v-bind="dolar"
                     :disabled="disableValue">
              </money>
              <label class="control-label" style="float: left">
                Cotação dólar: <b>R$ {{toMoney(exchange.priceQuote)}}</b>
              </label>
            </div>
            <div class="col-md-4">
              <label class="control-label">Valor em Real</label>
              <money class="form-control input-lg"
                     v-model="brlValue"
                     v-bind="money"
                     :disabled="true">
              </money>
              <label class="control-label" style="float: left">
                Taxa de Serviço: <b>R$ {{toMoney(fee)}}</b>
              </label>
            </div>
            <div class="col-md-4">
              <label class="control-label">Total da Transação</label>
              <money class="form-control input-lg"
                     v-model="brlValueTotal"
                     v-bind="money"
                     :disabled="true">
              </money>
              <label class="control-label" style="float: left">
                Saldo: <b>R$ {{toMoney(accountBalance)}}</b>
              </label>
            </div>
            <div class="col-md-12" style="margin-top: 10px">
              <label class="control-label">Beneficiário (Sem Abreviações)</label>
              <fg-input v-model="exchange.beneficiary" style="margin: 0 auto" maxlength="200"
                        v-validate="{required: true}"
                        :error="getError('Beneficiário')"
                        name="Beneficiário"/>
            </div>
            <div class="col-md-6" style="margin-top: 10px">
              <label class="control-label">País de Destino</label>
              <autocomplete v-validate="{required: true}"
                            placeholder="Selecionar"
                            :source="countryList"
                            input-class="form-control"
                            results-value="numberIso"
                            :results-display="displayCountries"
                            @selected="selectCountry"
                            :error="getError('País de Destino')"
                            name="País de Destino">
              </autocomplete>
            </div>
            <div class="col-md-6" style="padding-top: 15px">
              <label class="control-label">Descrição</label>
              <fg-input v-model="exchange.userDescription" style="margin: 0 auto" maxlength="20"/>
            </div>
          </div>

          <div class="row" style="margin-top: 10px">
            <div class="col-md-5">
              <label class="control-label">SWIFT/IBC</label>
              <fg-input v-model="exchange.destinationSwiftCode" style="width: 100%"
                        maxlength="11" name="SWIFT/IBC">
              </fg-input>
            </div>
            <div class="col-md-7">
              <label class="control-label">IBAN</label>
              <fg-input v-model="exchange.destinationIbanCode" style="width: 100%"
                        maxlength="34" name="IBAN">
              </fg-input>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="col-md-4">
              <label class="control-label">Código do Banco de Destino</label>
              <fg-input v-model="exchange.destinationBankCode" style="width: 100%"
                        v-validate="{required: true}" type="tel" maxlength="15"
                        :error="getError('Código do Banco')"
                        name="Código do Banco" v-mask="['###############']">
              </fg-input>
            </div>
            <div class="col-md-8">
              <label class="control-label">Nome do Banco de Destino</label>
              <fg-input v-model="exchange.destinationBankName" style="width: 100%"
                        v-validate="{required: true}" type="text" maxlength="100"
                        :error="getError('Nome do Banco')"
                        name="Nome do Banco">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <label class="control-label">Agência Destino</label>
              <fg-input v-model="exchange.destinationAgency" style="width: 100%"
                        v-validate="{required: true}" type="tel" maxlength="10"
                        :error="getError('Agência Destino')"
                        name="Agência Destino" v-mask="['##########']">
              </fg-input>
            </div>
            <div class="col-md-4">
              <label class="control-label">Dígito Agência Destino</label>
              <fg-input v-model="exchange.destinationAgencyDv" style="width: 100%"
                        type="tel" maxlength="1"
                        :error="getError('Dígito Agência')"
                        name="Dígito Agência" v-mask="['#']">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <label class="control-label">Conta Destino</label>
              <fg-input v-model="exchange.destinationAccountNumber" style="width: 100%"
                        v-validate="{required: true}" type="tel" maxlength="30"
                        :error="getError('Conta Destino')"
                        name="Conta Destino" v-mask="['##############################']">
              </fg-input>
            </div>
            <div class="col-md-4">
              <label class="control-label">Dígito Conta Destino</label>
              <fg-input v-model="exchange.destinationAccountNumberDv" style="width: 100%"
                        type="tel" maxlength="1" :error="getError('Dígito Conta')"
                        name="Dígito Conta" v-mask="['#']">
              </fg-input>
            </div>
          </div>
          <br><br>
          <!--                  <pix-consult-details :transfer="transfer"/>-->
          <div class="row">
            <!--                    <div class="col-md-6" style="padding-top: 30px">-->
            <!--                      <p-button @click="()=>{consultSuccess = false; clearInterval(validateIntervalLoop)}"-->
            <!--                                type="danger" style="margin: 0 auto; display: table; min-width: 200px; height: 60px">-->
            <!--                        <i class="fas fa-chevron-left"/> Voltar-->
            <!--                      </p-button>-->
            <!--                    </div>-->
            <div class="col-md-12" style="padding-bottom: 20px">
              <p-button type="info" @click="doTransaction"
                        style="margin: 0 auto; display: table; min-width: 200px; height: 60px">
                <i slot="label" class="fa fa-compress"></i> Solicitar Câmbio
              </p-button>
            </div>
          </div>
        </card>

        <div v-if="!isNullOrEmpty(exchange.qrCode) && transactionAuthorizationTotp==='true'" style="height: 100%;">
          <card style="height: 100%; padding: 5%">
            <h4 slot="header" class="card-title">
              Confirmação de Transferência
            </h4>
            <hr/>
            <div class="row"  v-if="isNullOrEmpty(totpQrCodeUri)">
              <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>
            </div>
            <div class="row">
              <div class="col-md-12" style="text-align: right" v-if="!isNullOrEmpty(totpQrCodeUri)">
                <p style="margin-top: 14px"><b>1</b> - Baixe e instale o aplicativo <span style="color: black; font-weight: bold">Google Authenticator</span></p>
                <div class="row" style="margin-bottom: 10px">
                  <div class="col-md-4"></div>
                  <div class="col-md-4">
                    <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">
                      <img border="0" alt="Android" src="/static/img/mobile/google_play.png" style="width: 120px">
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="https://apps.apple.com/br/app/google-authenticator/id388497605" target="_blank">
                      <img border="0" alt="Android" src="/static/img/mobile/apple_store.png" style="width: 120px">
                    </a>
                  </div>
                </div>
                <p><b>2</b> - No aplicativo, leia o QR Code ao lado ou informe a chave manualmente</p>
                <p><b>3</b> - Informe, no campo abaixo, a senha exibida pelo aplicativo</p>
                <p><b>4</b> - Confirme a operação</p>
                <p><b>5</b> - Pronto!</p>
              </div>
              <div class="col-md-6" style="text-align: left" v-if="!isNullOrEmpty(totpQrCodeUri)">
                <qrcode :value="totpQrCodeUri" :options="{ width: 200, margin:0 }"></qrcode>
                <p><b>{{this.totpManualCode}}</b></p>
              </div>
              <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
                <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <span style="color: black; font-weight: bold">Google Authenticator</span></p>
                <p><b>2</b> - Procure por <b style="color: #000000 !important;">3RBank</b> e seu nome de usuário no aplicativo </p>
                <p><b>3</b> - Informe, no campo abaixo, a senha exibida pelo aplicativo </p>
                <p><b>4</b> - Confirme a operação</p>
                <p><b>5</b> - Pronto!</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" style="text-align: center">
                Código: <input style="margin: 0 auto; margin-top: 14px" v-mask="['### ###']" v-model="validationCode" size="6"/>
              </div>
            </div>
            <div class="row" v-if="!codeValid">
              <p style="margin: 0 auto; margin-top: 14px" class="text-warning"> Código inválido</p>
            </div>
            <div class="row">
              <p-button @click="()=>{validateCode()}"
                        type="info" style="margin: 0 auto; margin-top: 14px"
                        :disabled="totpConfirmBtnDisabled"
              >
                <i class="fas fa-chevron-right"/> Confirmar
              </p-button>
            </div>
            <hr/>

            <div class="row">
              <p-button @click="()=>{exchange.qrCode = ''}"
                        type="danger" style="margin: 0 auto">
                <i class="fas fa-chevron-left"/> Voltar
              </p-button>
            </div>
          </card>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import {isNullOrEmpty, isValidCnpj, isValidCpf, moneyToFloat, notNull, toMoney} from "src/util/core.utils"
  import {failWs, postWs, getWs} from 'src/ws.service'
  import swal from 'sweetalert2'
  import nprogress from 'nprogress'
  import Autocomplete from 'vuejs-auto-complete'
  import BannerCard from "../../../UIComponents/Promotion/BannerCard";

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      Autocomplete,
      BannerCard
    },
    data() {
      return {
        countryList: [],
        validateIntervalLoop: null,
        accountBalance: '0,00',
        value: 0,
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        },
        dolar: {
          decimal: ',',
          thousands: '.',
          prefix: 'US$ ',
          suffix: '',
          precision: 2,
          masked: false
        },
        exchange: {
          qrCode: '',
          priceQuote: 0,
          exchangePrice: 0,
          currency: '',
          quoteDateTime: '',
          brlValue: 0,
          brlValueTotal: 0,
          foreignCurrencyValue: '',
          beneficiary: '',
          buyerSellerCountry: '',
          type: 'BUY',
          username: '',
          destinationBankCode: '',
          destinationBankName: '',
          destinationAgency: '',
          destinationAgencyDv: '',
          destinationAccountNumber: '',
          destinationAccountNumberDv: '',
          userDescription: '',
          fee: 0,
          destinationSwiftCode: '',
          destinationIbanCode: '',
          brokerFee: 0,
          iof: 0,
          percentageIof: 0,
        },
        disableValue: false,
        transactionAuthorizationEmail: 'false',
        transactionAuthorizationTotp: 'false',
        validationCode: '',
        totpQrCodeUri : '',
        totpManualCode : '',
        totpConfirmBtnDisabled: false,
        codeValid: true
      }
    },
    created() {
      this.accountBalance = moneyToFloat(notNull(localStorage.getItem('accountBalance'), '0,00'))
      this.exchange.username = localStorage.getItem("usernameDisplay")
      this.$validator.extend('cpfCnpjValid', {
        getMessage: field => 'O ' + field + ' é inválido.',
        validate: (value) => {
          value = value.replace(/(\.)|(\/)|(-)/g, '')
          return isValidCnpj(value) || isValidCpf(value)
        }
      })
      this.transactionAuthorizationTotp = notNull(localStorage.getItem('transactionAuthorizationTotp'), 'false')
      this.loadCountries()
      this.loadPriceQuote()
    },
    computed: {
      brlValue() {
        let brlValue = this.exchange.priceQuote * this.exchange.foreignCurrencyValue
        this.exchange.brlValue = brlValue
        return brlValue
      },
      fee() {
        let fee = 0
        if(this.exchange.foreignCurrencyValue > 0) {
          this.exchange.iof = (this.exchange.exchangePrice * this.exchange.foreignCurrencyValue) * this.exchange.percentageIof / 100
          fee = this.exchange.brokerFee + this.exchange.iof +
            ((this.exchange.priceQuote * this.exchange.foreignCurrencyValue) * (this.exchange.overprice / 100))
        }
        this.exchange.fee = fee
        return fee
      },
      brlValueTotal() {
        let brlValueTotal = 0
        if(this.exchange.foreignCurrencyValue > 0) {
          brlValueTotal = (this.exchange.brokerFee +
            ((this.exchange.exchangePrice * this.exchange.foreignCurrencyValue) * this.exchange.percentageIof / 100) +
            ((this.exchange.priceQuote * this.exchange.foreignCurrencyValue) * (this.exchange.overprice / 100))) +
            (this.exchange.priceQuote * this.exchange.foreignCurrencyValue)
        }
        this.exchange.brlValueTotal = brlValueTotal
        return brlValueTotal
      },
    },
    methods: {
      toMoney,
      moneyToFloat,
      isNullOrEmpty,
      loadCountries() {
        getWs('/exchange/get-countries', true, null, this.loadCountriesSuccess, this.loadFail)
      },
      loadCountriesSuccess(response) {
        this.countryList = response.data
      },
      loadPriceQuote() {
        getWs('/exchange/get-price-quote', true, null, this.loadPriceQuoteSuccess, this.loadFail)
      },
      loadPriceQuoteSuccess(response) {
        this.exchange.priceQuote = response.data.priceQuote
        this.exchange.exchangePrice = response.data.exchangePrice
        this.exchange.currency = response.data.currency.code
        this.exchange.quoteDateTime = response.data.quoteDateTime
        this.exchange.percentageIof = response.data.iof
        this.exchange.brokerFee = response.data.brokerFee
        this.exchange.overprice = response.data.overprice
      },
      loadFail(error) {
        this.error.message = error.response.data.message
        this.error.response = error.response.data.response
      },
      displayCountries(countries) {
        return countries.numberIso + ' - ' + countries.name
      },
      selectCountry(country) {
        this.exchange.buyerSellerCountry = country.selectedObject.numberIso
      },
      doTransaction() {
        if (this.exchange.foreignCurrencyValue <= 0) {
          swal({
            title: 'Aviso!',
            text: 'O campo valor em dólar deve ser maior que zero.',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        this.exchange.brlValue = this.exchange.priceQuote * this.exchange.foreignCurrencyValue
        if ((this.exchange.brlValue+this.exchange.fee) > this.accountBalance) {
          swal({
            title: 'Aviso!',
            text: 'Você não possui saldo suficiente.',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        if (isNullOrEmpty(this.exchange.buyerSellerCountry)) {
          swal({
            title: 'Aviso!',
            text: 'O campo País de Destino é obrigatório.',
            buttonsStyling: true,
            timer: 5000,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            return
          }
          this.callExchange()
        })
      },
      callExchange() {
        if (this.exchange.foreignCurrencyValue > 10000) {
          swal({
            title: 'Aviso!',
            text: 'O valor do câmbio não pode ser maior que US$ 10.000,00, para câmbio de maior valor, entre em contato com o nosso suporte!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        postWs("/exchange/request-buy",
          true, null,
          this.exchange,
          (response) => {
            let text = 'Falha ao transferir! Tente mais tarde.'
            if (response.data.response === 'TOTP_CODE_VALIDATION_REQUIRED' && !isNullOrEmpty(response.data.qrCode)) {
              this.exchange.qrCode = response.data.qrCode
              this.totpQrCodeUri = response.data.totpQrCodeUri
              this.totpManualCode = response.data.totpManualCode
              return
            } else if(response.data.response == 'OK'){
              this.success()
              return
            }
            swal({
              title: 'Aviso!',
              text: text,
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }, (error) => {
            let text = null
            if (error.response.data.response == 'NO_MOBILE_DEVICE_REGISTERED') {
              text = 'Nenhum dispositivo de verificação cadastrado! Para prosseguir, cadastre clicando no menu \'CADASTRAR DISPOSITIVO\'.'
            } else if (error.response.data.response == 'NO_FUNDS') {
              text = 'Você não possui saldo suficiente! Altere o valor e tente novamente.'
            } else if (error.response.data.response == 'ACCOUNT_DOCUMENTS_NOT_VALIDATED') {
              text = 'Conta ainda não verificada! Para prosseguir, favor preencher os dados e enviar a documentação clicando no menu \'VERIFICAR CONTA\'.'
            } else if (error.response.data.response == 'SOURCE_USER_HAVE_NO_ENABLED_ACCOUNT' || error.response.data.response == 'BLOCKED_OUT_TRANSACTIONS' ||
              error.response.data.response == 'BLOCKED') {
              text = 'Sua conta foi bloqueada! Entre em contato com nosso suporte.'
            } else if (error.response.data.response == 'OPERATION_EXCEEDS_LIMIT') {
              text = 'Esta transação excede os limites para sua conta. Entre em contato com nosso suporte para solicitar um aumento no seu limite.'
            } else if (error.response.data.response == 'SOURCE_USER_INACTIVE') {
              text = 'Sua conta está inativa! Entre em contato com nosso suporte.'
            } else if (error.response.data.response == 'OPERATION_EXCEEDS_TIME_LIMIT') {
              text = 'As solicitações de Câmbio podem ser realizadas de segunda a sexta das 08h às 15h30, tente novamente no horário permitido.'
            }
            if (!isNullOrEmpty(text)) {
              swal({
                title: 'Aviso!',
                text: text,
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
            } else {
              swal({
                title: 'Serviço temporariamente indisponível!',
                text: 'Tente novamente mais tarde.',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-danger btn-fill',
                type: 'error'
              })
            }
          })
      },
      transferSuccess(response) {
        if (response.data.status == 'USED') {
          this.success()
        }
      },
      validateCode(){
        this.totpConfirmBtnDisabled = true
        postWs("/verifcode/validate",
          true,
          null,
          {verifCode: this.exchange.qrCode,
            hashCode: this.validationCode},
          this.confirmCode,
          this.failValidateCode)
      },
      failValidateCode() {
        this.totpConfirmBtnDisabled = false
        swal({
          title: 'Aviso!',
          text: 'Código inválido!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      confirmCode(response){
        postWs("/verifcode/confirm",
          true,
          null,
          {verifCode: response.data.verifCode},
          this.success,
          this.failConfirmCode
        )
      },
      success(){
        this.totpConfirmBtnDisabled = false
        clearInterval(this.validateIntervalLoop)
        this.accountBalance = (this.accountBalance - this.exchange.brlValue)
        localStorage.setItem('accountBalance', this.accountBalance)

        this.disableValue = false
        this.value = 0
        this.validationCode = ''

        this.exchange.brlValue = 0
        this.exchange.foreignCurrencyValue = 0
        this.exchange.beneficiary = ''
        this.exchange.userDescription = ''
        this.exchange.destinationBankCode = ''
        this.exchange.destinationBankName = ''
        this.exchange.destinationAgency = ''
        this.exchange.destinationAgencyDv = ''
        this.exchange.destinationAccountNumber = ''
        this.exchange.destinationAccountNumberDv = ''
        this.exchange.destinationSwiftCode = ''
        this.exchange.destinationIbanCode = ''
        this.exchange.iof = 0
        this.exchange.brokerFee = 0

        this.exchange.qrCode = ''
        swal({
          title: 'Sucesso!',
          text: 'Câmbio solicitado com sucesso!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        })
        this.loadPriceQuote()
        nprogress.done()
      },
      failConfirmCode() {
        this.totpConfirmBtnDisabled = false
        swal({
          title: 'Erro!',
          text: 'Erro ao transferir, tente novamente!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-danger btn-fill',
          type: 'error'
        })
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
    }
  }
</script>
<style lang="scss">
  .dzs-ted {
    .autocomplete__icon {
      display: none;
    }

    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    .picture-row {
      margin-top: 20px;
      background-color: #5e5e5e;
    }

    .picture-container {
      margin: 5px auto;
      display: table;
    }

    .picture {
      float: left;
      width: 80px;
      height: 80px;
      background-color: #999;
      border: 1px solid #e3e3e3;
      color: #fff;
      border-radius: 50%;
      overflow: hidden;
      transition: all .2s;
      -webkit-transition: all .2s;
    }

    .picture-text {
      float: right;
      margin-top: 20px;
      margin-left: 6px;
    }

    .clickable-rows {
      tbody tr td {
        cursor: pointer;
      }

      .el-table__expanded-cell {
        cursor: default;
      }
    }

    .btn-tab {
      border-color: #00d19a !important;
      color: #00d19a !important;
      padding-right: 15px;
      padding-left: 15px;
    }

    .btn-tab:hover {
      background-color: #00d19a !important;
      border-color: #00d19a !important;
      color: #FFFFFF !important;
    }

    .btn-tab:focus {
      background-color: #00d19a !important;
      border-color: #00d19a !important;
      color: #FFFFFF !important;
    }

    .btn-tab:active:focus {
      background-color: #00d19a !important;
      border-color: #00d19a !important;
      color: #FFFFFF !important;
    }

    .btn-tab-active {
      background-color: #00d19a !important;
      border-color: #00d19a !important;
      color: #FFFFFF !important;
    }
  }
</style>
