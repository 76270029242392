<template>
  <div class="login-page dzs-forgot">
    <div class="full-page login-page section-image sb-login-page">
      <div class="content container-fluid">
        <div class="row">
          <div class="col-lg-6 content-wrapper">
            <div>
              <img class="mb-5 mb-lg-0" src="/static/img/login/3RBankLogo.svg" alt="">
            </div>
          </div>
          <div class="col-lg-6 d-flex align-items-center justify-content-center">
            <div class="card-forgot">
              <div class="card-forgot__header">
                <div class="text-primary">
                  <span>Esqueceu sua senha?</span><br>
                  <span>Informe seu usuário e vamos lhe ajudar!</span>
                </div>
              </div>
              <form class="div-forgot">
                <fg-input class="mb-3" placeholder="Usuário ou E-mail" data-vv-as="Usuário"
                          name="username" v-model="form.username"
                          v-validate="validations.username"
                          :error="getError('username')"></fg-input>
                <div class="card-forgot__buttons">
                  <p-button native-type="submit" slot="footer" name="submit"
                            round block class="btn btn-outline-primary"
                            :disabled="!this.showSubmit"
                            @click.prevent="recover">Recuperar Senha
                  </p-button>
                  <p><a href="/login" style="font-size: 1rem; text-align: center;" class="card--highlight">Fazer Login</a></p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-wrapper">
        <img class="bg-image" src="/static/img/login/bgLogin.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import {Button, Card, Checkbox} from 'src/components/UIComponents'
import AppNavbar from './Layout/AppNavbar'
import AppFooter from './Layout/AppFooter'
import swal from 'sweetalert2'
import {failWs, postWs} from 'src/ws.service'
import {VueReCaptcha} from 'vue-recaptcha-v3'

export default {
  components: {
    Card,
    AppNavbar,
    AppFooter,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    VueReCaptcha,
  },
  data() {
    return {
      showSubmit: true,
      form: {
        username: ''
      },
      validations: {
        username: {
          required: true,
          min: 3,
          ok: false
        }
      },
    }
  },
  mounted() {
    this.recaptcha()
  },
  methods: {
    recover() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          this.showSubmit = false
          this.doRecaptcha()
        }
      })
    },
    async recaptcha() {
      await this.$recaptchaLoaded()
      this.$recaptchaInstance.showBadge()
    },
    async doRecaptcha() {
      const token = await this.$recaptcha('forgot')
      this.onCaptchaVerified(token)
    },
    onCaptchaVerified(recaptchaToken) {
      postWs("/auth/forgot-password", false, null, {key: this.form.username, recaptchaToken: recaptchaToken},
        this.successRecover, this.failRecover)
    },
    successRecover() {
      swal({
        title: "Enviado",
        text: "Foi enviado para seu e-mail o procedimento para recuperação de senha. Obrigado",
        buttonsStyling: true,
        closeOnClickModal: false,
        confirmButtonClass: 'btn btn-success btn-fill',
        type: "success"
      })
      this.$router.push('/login')
    },
    failRecover(error) {
      if (error.response.status == 404) {
        this.successRecover()
      } else {
        failWs(error)
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    }
  }
}
</script>
<style lang="scss">
.dzs-forgot {
  min-height: 100vh;
  font-family: 'Uncut Sans', sans-serif;
  display: flex;
  align-items: center;

  .form-control,
  .input-group-text {
    background-color: #FFFFFF !important;
    border: none !important;
  }

  .full-page .content {
    padding-top: 0 !important;
    max-width: 120rem;
    margin: 0 auto;
  }

  .content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      animation: .8s in ease-in-out forwards;

      img {
        max-height: 4rem;
        width: 100%;
      }
    }
  }

  .card-forgot {
    border-radius: 1rem !important;
    width: 100%;
    max-width: 31.5rem;
    text-align: center;

    input {
      width: 100%;
      background: #e2e2e2 !important;
      color: #333333 !important;
      padding: 1.2rem 1.5rem !important;
      font-family: inherit;
      font-size: 1rem;
      line-height: 1.5;
    }
  }

  .card-forgot__header {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .bg-wrapper {
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  .bg-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 !important;
  }

  @keyframes in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

</style>
