export default [{ispb: '00000000', name: 'BCO DO BRASIL S.A.'},
{ispb: '00000208', name: 'BRB - BCO DE BRASILIA S.A.'},
{ispb: '00038121', name: 'Selic'},
{ispb: '00038166', name: 'Bacen'},
{ispb: '00250699', name: 'AGK CC S.A.'},
{ispb: '00315557', name: 'CONF NAC COOP CENTRAIS UNICRED'},
{ispb: '00329598', name: 'ÍNDIGO INVESTIMENTOS DTVM LTDA.'},
{ispb: '00360305', name: 'CAIXA ECONOMICA FEDERAL'},
{ispb: '00394460', name: 'STN'},
{ispb: '00416968', name: 'BANCO INTER'},
{ispb: '00517645', name: 'BCO RIBEIRAO PRETO S.A.'},
{ispb: '00556603', name: 'BANCO BARI S.A.'},
{ispb: '00558456', name: 'BCO CETELEM S.A.'},
{ispb: '00795423', name: 'BANCO SEMEAR'},
{ispb: '00806535', name: 'PLANNER CV S.A.'},
{ispb: '00997185', name: 'BCO B3 S.A.'},
{ispb: '01023570', name: 'BCO RABOBANK INTL BRASIL S.A.'},
{ispb: '01027058', name: 'CIELO S.A.'},
{ispb: '01073966', name: 'CCR DE ABELARDO LUZ'},
{ispb: '01181521', name: 'BCO COOPERATIVO SICREDI S.A.'},
{ispb: '01330387', name: 'CREHNOR LARANJEIRAS'},
{ispb: '01522368', name: 'BCO BNP PARIBAS BRASIL S A'},
{ispb: '01634601', name: 'CCCM UNICRED CENTRAL RS'},
{ispb: '01658426', name: 'CECM COOPERFORTE'},
{ispb: '01701201', name: 'KIRTON BANK'},
{ispb: '01800019', name: 'PORTOCRED S.A. - CFI'},
{ispb: '01852137', name: 'BBC LEASING'},
{ispb: '02038232', name: 'BANCO SICOOB S.A.'},
{ispb: '02276653', name: 'TRINUS CAPITAL DTVM'},
{ispb: '02318507', name: 'BCO KEB HANA DO BRASIL S.A.'},
{ispb: '02332886', name: 'XP INVESTIMENTOS CCTVM S/A'},
{ispb: '02398976', name: 'UNIPRIME NORTE DO PARANÁ - CC '},
{ispb: '02685483', name: 'CM CAPITAL MARKETS CCTVM LTDA'},
{ispb: '02801938', name: 'BCO MORGAN STANLEY S.A.'},
{ispb: '02819125', name: 'UBS BRASIL CCTVM S.A.'},
{ispb: '02992317', name: 'TREVISO CC S.A.'},
{ispb: '02992335', name: 'CIP Siloc'},
{ispb: '03012230', name: 'HIPERCARD BM S.A. '},
{ispb: '03017677', name: 'BCO. J.SAFRA S.A.'},
{ispb: '03046391', name: 'UNIPRIME CENTRAL CCC LTDA.'},
{ispb: '03215790', name: 'BCO TOYOTA DO BRASIL S.A.'},
{ispb: '03311443', name: 'PARATI - CFI S.A.'},
{ispb: '03323840', name: 'BCO ALFA S.A.'},
{ispb: '03502968', name: 'PI DTVM S.A.'},
{ispb: '03532415', name: 'BCO ABN AMRO S.A.'},
{ispb: '03609817', name: 'BCO CARGILL S.A.'},
{ispb: '03751794', name: 'TERRA INVESTIMENTOS DTVM'},
{ispb: '03973814', name: 'SERVICOOP'},
{ispb: '04062902', name: 'VISION S.A. CC'},
{ispb: '04184779', name: 'BANCO BRADESCARD'},
{ispb: '04257795', name: 'NOVA FUTURA CTVM LTDA.'},
{ispb: '04307598', name: 'FIDUCIA SCMEPP LTDA'},
{ispb: '04332281', name: 'GOLDMAN SACHS DO BRASIL BM S.A'},
{ispb: '04391007', name: 'CAMARA INTERBANCARIA DE PAGAMENTOS - CIP'},
{ispb: '04632856', name: 'CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.'},
{ispb: '04715685', name: 'CCM DESP TRÂNS SC E RS'},
{ispb: '04814563', name: 'BCO SOROCRED S.A. - BM'},
{ispb: '04866275', name: 'BANCO INBURSA'},
{ispb: '04902979', name: 'BCO DA AMAZONIA S.A.'},
{ispb: '04913129', name: 'CONFIDENCE CC S.A.'},
{ispb: '04913711', name: 'BCO DO EST. DO PA S.A.'},
{ispb: '05192316', name: 'VIA CERTA FINANCIADORA S.A. - CFI'},
{ispb: '05351887', name: 'ZEMA CFI S/A'},
{ispb: '05442029', name: 'CASA CREDITO S.A. SCM'},
{ispb: '05463212', name: 'COOP CENTRAL AILOS'},
{ispb: '05491616', name: 'CC POUP SER FIN CO'},
{ispb: '05684234', name: 'PLANNER SCM S.A.'},
{ispb: '05790149', name: 'CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO'},
{ispb: '05841967', name: 'CECM FABRIC CALÇADOS SAPIRANGA'},
{ispb: '06271464', name: 'BCO BBI S.A.'},
{ispb: '07207996', name: 'BCO BRADESCO FINANC. S.A.'},
{ispb: '07237373', name: 'BCO DO NORDESTE DO BRASIL S.A.'},
{ispb: '07450604', name: 'BCO CCB BRASIL S.A.'},
{ispb: '07512441', name: 'HS FINANCEIRA'},
{ispb: '07652226', name: 'LECCA CFI S.A.'},
{ispb: '07656500', name: 'BCO KDB BRASIL S.A.'},
{ispb: '07679404', name: 'BANCO TOPÁZIO S.A.'},
{ispb: '07693858', name: 'HSCM SCMEPP LTDA.'},
{ispb: '07853842', name: 'CCR DE OURO'},
{ispb: '07945233', name: 'POLOCRED SCMEPP LTDA.'},
{ispb: '08240446', name: 'CCR DE IBIAM'},
{ispb: '08253539', name: 'CCR DE SÃO MIGUEL DO OESTE'},
{ispb: '08357240', name: 'BCO CSF S.A.'},
{ispb: '08561701', name: 'PAGSEGURO S.A.'},
{ispb: '08609934', name: 'MONEYCORP BCO DE CÂMBIO S.A.'},
{ispb: '08673569', name: 'F D GOLD DTVM LTDA'},
{ispb: '09089356', name: 'GERENCIANET'},
{ispb: '09105360', name: 'ICAP DO BRASIL CTVM LTDA.'},
{ispb: '09210106', name: 'SOCRED SA - SCMEPP'},
{ispb: '09274232', name: 'STATE STREET BR S.A. BCO COMERCIAL'},
{ispb: '09313766', name: 'CARUANA SCFI'},
{ispb: '09464032', name: 'MIDWAY S.A. - SCFI'},
{ispb: '09512542', name: 'CODEPE CVC S.A.'},
{ispb: '09516419', name: 'BCO ORIGINAL DO AGRO S/A'},
{ispb: '09554480', name: 'SUPER PAGAMENTOS E ADMINISTRACAO DE MEIOS ELETRONICOS S.A.'},
{ispb: '10264663', name: 'BANCOSEGURO S.A.'},
{ispb: '10398952', name: 'CRESOL CONFEDERAÇÃO'},
{ispb: '10573521', name: 'MERCADO PAGO'},
{ispb: '10664513', name: 'BCO AGIBANK S.A.'},
{ispb: '10690848', name: 'BCO DA CHINA BRASIL S.A.'},
{ispb: '10853017', name: 'GET MONEY CC LTDA'},
{ispb: '10866788', name: 'BCO BANDEPE S.A.'},
{ispb: '11165756', name: 'GLOBAL SCM LTDA'},
{ispb: '11476673', name: 'BANCO RANDON S.A.'},
{ispb: '11495073', name: 'OM DTVM LTDA'},
{ispb: '11581339', name: 'MONEY PLUS SCMEPP LTDA'},
{ispb: '11703662', name: 'TRAVELEX BANCO DE CÂMBIO S.A.'},
{ispb: '11758741', name: 'BANCO FINAXIS'},
{ispb: '11970623', name: 'BCO SENFF S.A.'},
{ispb: '12865507', name: 'BRK S.A. CFI'},
{ispb: '13009717', name: 'BCO DO EST. DE SE S.A.'},
{ispb: '13059145', name: 'BEXS BCO DE CAMBIO S.A.'},
{ispb: '13140088', name: 'ACESSO SOLUCOES PAGAMENTO SA'},
{ispb: '13220493', name: 'BR PARTNERS BI'},
{ispb: '13293225', name: 'ÓRAMA DTVM S.A.'},
{ispb: '13370835', name: 'BPP IP S.A.'},
{ispb: '13486793', name: 'BRL TRUST DTVM SA'},
{ispb: '13673855', name: 'FRAM CAPITAL DTVM S.A.'},
{ispb: '13720915', name: 'BCO WESTERN UNION '},
{ispb: '13884775', name: 'HUB PAGAMENTOS'},
{ispb: '14190547', name: 'CAMBIONET CC LTDA'},
{ispb: '14388334', name: 'PARANA BCO S.A.'},
{ispb: '14511781', name: 'BARI CIA HIPOTECÁRIA'},
{ispb: '15111975', name: 'IUGU SERVICOS NA INTERNET S/A '},
{ispb: '15114366', name: 'BCO BOCOM BBM S.A.'},
{ispb: '15173776', name: 'BCO CAPITAL S.A.'},
{ispb: '15357060', name: 'BCO WOORI BANK DO BRASIL S.A.'},
{ispb: '15581638', name: 'FACTA S.A. CFI'},
{ispb: '16501555', name: 'STONE PAGAMENTOS S.A.'},
{ispb: '16927221', name: 'AMAZÔNIA CC LTDA.'},
{ispb: '16944141', name: 'BROKER BRASIL CC LTDA.'},
{ispb: '17184037', name: 'BCO MERCANTIL DO BRASIL S.A.'},
// {ispb: '17298092', name: 'BCO ITAÚ BBA S.A.'},
{ispb: '17351180', name: 'BCO TRIANGULO S.A.'},
{ispb: '17352220', name: 'SENSO CCVM S.A.'},
{ispb: '17453575', name: 'ICBC DO BRASIL BM S.A.'},
{ispb: '17772370', name: 'VIPS CC LTDA.'},
{ispb: '17826860', name: 'BMS SCD S.A.'},
{ispb: '18188384', name: 'CREFAZ SCMEPP LTDA'},
{ispb: '18236120', name: 'NU PAGAMENTOS S.A.'},
{ispb: '18520834', name: 'UBS BRASIL BI S.A.'},
{ispb: '19307785', name: 'MS BANK S.A. BCO DE CÂMBIO'},
{ispb: '19324634', name: 'LAMARA SCD S.A.'},
{ispb: '20155248', name: 'PARMETAL DTVM LTDA'},
{ispb: '21018182', name: 'JUNO'},
{ispb: '21332862', name: 'CARTOS SCD S.A.'},
{ispb: '22610500', name: 'VORTX DTVM LTDA.'},
{ispb: '22896431', name: 'PICPAY'},
{ispb: '23522214', name: 'COMMERZBANK BRASIL S.A. - BCO MÚLTIPLO'},
{ispb: '23862762', name: 'WILL FINANCEIRA S.A.CFI'},
{ispb: '24074692', name: 'GUITTA CC LTDA'},
{ispb: '24537861', name: 'FFA SCMEPP LTDA.'},
{ispb: '26563270', name: 'CCR DE PRIMAVERA DO LESTE'},
{ispb: '27098060', name: 'BANCO DIGIO'},
{ispb: '27214112', name: 'AL5 S.A. CFI'},
{ispb: '27302181', name: 'CRED-UFES'},
{ispb: '27351731', name: 'REALIZE CFI S.A.'},
{ispb: '27652684', name: 'GENIAL INVESTIMENTOS CVM S.A.'},
{ispb: '27842177', name: 'IB CCTVM S.A.'},
{ispb: '28127603', name: 'BCO BANESTES S.A.'},
{ispb: '28195667', name: 'BCO ABC BRASIL S.A.'},
{ispb: '28650236', name: 'BS2 DTVM S.A.'},
{ispb: '28719664', name: 'Balcão B3'},
{ispb: '29011780', name: 'CIP C3'},
{ispb: '29030467', name: 'SCOTIABANK BRASIL'},
{ispb: '29162769', name: 'TORO CTVM LTDA'},
{ispb: '30306294', name: 'BANCO BTG PACTUAL S.A.'},
{ispb: '30680829', name: 'NU FINANCEIRA S.A. CFI'},
{ispb: '30723886', name: 'BCO MODAL S.A.'},
{ispb: '31597552', name: 'BCO CLASSICO S.A.'},
{ispb: '31749596', name: 'IDEAL CTVM S.A.'},
{ispb: '31872495', name: 'BCO C6 S.A.'},
{ispb: '31880826', name: 'BCO GUANABARA S.A.'},
{ispb: '31895683', name: 'BCO INDUSTRIAL DO BRASIL S.A.'},
{ispb: '32062580', name: 'BCO CREDIT SUISSE S.A.'},
{ispb: '32402502', name: 'QI SCD S.A.'},
{ispb: '32648370', name: 'FAIR CC S.A.'},
{ispb: '32997490', name: 'CREDITAS SCD'},
{ispb: '33042151', name: 'BCO LA NACION ARGENTINA'},
{ispb: '33042953', name: 'CITIBANK N.A.'},
{ispb: '33132044', name: 'BCO CEDULA S.A.'},
{ispb: '33147315', name: 'BCO BRADESCO BERJ S.A.'},
{ispb: '33172537', name: 'BCO J.P. MORGAN S.A.'},
{ispb: '33264668', name: 'BCO XP S.A.'},
{ispb: '33466988', name: 'BCO CAIXA GERAL BRASIL S.A.'},
{ispb: '33479023', name: 'BCO CITIBANK S.A. '},
{ispb: '33603457', name: 'BCO RODOBENS S.A.'},
{ispb: '33644196', name: 'BCO FATOR S.A.'},
{ispb: '33657248', name: 'BNDES'},
{ispb: '33775974', name: 'ATIVA S.A. INVESTIMENTOS CCTVM'},
{ispb: '33862244', name: 'BGC LIQUIDEZ DTVM LTDA'},
// {ispb: '33885724', name: 'BANCO ITAÚ CONSIGNADO S.A.'},
{ispb: '33923798', name: 'BCO MÁXIMA S.A.'},
{ispb: '34088029', name: 'LISTO SCD S.A.'},
{ispb: '34111187', name: 'HAITONG BI DO BRASIL S.A.'},
{ispb: '34335592', name: 'ÓTIMO SCD S.A.'},
{ispb: '34711571', name: 'VITREO DTVM S.A.'},
{ispb: '35977097', name: 'UP.P SEP S.A.'},
{ispb: '36113876', name: 'OLIVEIRA TRUST DTVM S.A.'},
{ispb: '36586946', name: 'BONUSPAGO SCD S.A.'},
{ispb: '36947229', name: 'COBUCCIO SCD S.A.'},
{ispb: '37241230', name: 'SUMUP SCD S.A.'},
{ispb: '37526080', name: 'WORK SCD S.A.'},
{ispb: '37715993', name: 'ACCREDITO SCD S.A.'},
{ispb: '37880206', name: 'CORA SCD S.A.'},
{ispb: '38129006', name: 'NUMBRS SCD S.A.'},
{ispb: '39664698', name: 'CRED-SYSTEM SCD S.A.'},
{ispb: '40303299', name: 'PORTOPAR DTVM LTDA'},
{ispb: '42272526', name: 'BNY MELLON BCO S.A.'},
{ispb: '43180355', name: 'PEFISA S.A. - CFI'},
{ispb: '44189447', name: 'BCO LA PROVINCIA B AIRES BCE'},
{ispb: '45246410', name: 'BANCO GENIAL'},
{ispb: '46518205', name: 'JPMORGAN CHASE BANK'},
{ispb: '48795256', name: 'BCO ANDBANK S.A.'},
{ispb: '49336860', name: 'ING BANK N.V.'},
{ispb: '50579044', name: 'LEVYCAM CCV LTDA'},
{ispb: '50585090', name: '"BCV - BCO, CRÉDITO E VAREJO S.A.'},
{ispb: '52904364', name: 'NECTON INVESTIMENTOS S.A CVM'},
{ispb: '52937216', name: 'BEXS CC S.A.'},
{ispb: '53518684', name: 'BCO HSBC S.A. '},
{ispb: '54403563', name: 'BCO ARBI S.A.'},
{ispb: '54641030', name: 'Câmara B3'},
{ispb: '55230916', name: 'INTESA SANPAOLO BRASIL S.A. BM'},
{ispb: '57839805', name: 'BCO TRICURY S.A.'},
{ispb: '58160789', name: 'BCO SAFRA S.A. '},
{ispb: '58497702', name: 'SMARTBANK'},
{ispb: '58616418', name: 'BCO FIBRA S.A.'},
{ispb: '59109165', name: 'BCO VOLKSWAGEN S.A'},
{ispb: '59118133', name: 'BCO LUSO BRASILEIRO S.A.'},
{ispb: '59274605', name: 'BCO GM S.A.'},
{ispb: '59285411', name: 'BANCO PAN'},
{ispb: '59588111', name: 'BCO VOTORANTIM S.A.'},
// {ispb: '60394079', name: 'BCO ITAUBANK S.A.'},
{ispb: '60498557', name: 'BCO MUFG BRASIL S.A.'},
{ispb: '60518222', name: 'BCO SUMITOMO MITSUI BRASIL S.A.'},
{ispb: '60701190', name: 'ITAU UNIBANCO S.A.'},
{ispb: '60746948', name: 'BCO BRADESCO S.A.'},
{ispb: '60814191', name: 'BCO MERCEDES-BENZ S.A.'},
{ispb: '60850229', name: 'OMNI BANCO S.A.'},
// {ispb: '60872504', name: 'ITAÚ UNIBANCO HOLDING S.A.'},
{ispb: '60889128', name: 'BCO SOFISA S.A.'},
{ispb: '60934221', name: 'Câmbio B3'},
{ispb: '61024352', name: 'BCO INDUSVAL S.A.'},
{ispb: '61033106', name: 'BCO CREFISA S.A.'},
{ispb: '61088183', name: 'BCO MIZUHO S.A.'},
{ispb: '61182408', name: 'BANCO INVESTCRED UNIBANCO S.A.'},
{ispb: '61186680', name: 'BCO BMG S.A.'},
{ispb: '61348538', name: 'BCO C6 CONSIG'},
{ispb: '61444949', name: 'SAGITUR CC LTDA'},
{ispb: '61533584', name: 'BCO SOCIETE GENERALE BRASIL'},
{ispb: '61723847', name: 'MAGLIANO S.A. CCVM'},
{ispb: '61747085', name: 'TULLETT PREBON BRASIL CVC LTDA'},
{ispb: '61809182', name: 'C.SUISSE HEDGING-GRIFFO CV S/A'},
{ispb: '61820817', name: 'BCO PAULISTA S.A.'},
{ispb: '62073200', name: 'BOFA MERRILL LYNCH BM S.A.'},
{ispb: '62109566', name: 'CREDISAN CC'},
{ispb: '62144175', name: 'BCO PINE S.A.'},
{ispb: '62169875', name: 'EASYNVEST - TÍTULO CV SA'},
{ispb: '62232889', name: 'BCO DAYCOVAL S.A'},
{ispb: '62237649', name: 'CAROL DTVM LTDA.'},
{ispb: '62285390', name: 'SINGULARE CTVM S.A.'},
{ispb: '62287735', name: 'RENASCENCA DTVM LTDA'},
{ispb: '62331228', name: 'DEUTSCHE BANK S.A.BCO ALEMAO'},
{ispb: '62421979', name: 'BANCO CIFRA'},
{ispb: '65913436', name: 'GUIDE'},
{ispb: '67030395', name: 'PLANNER TRUSTEE DTVM LTDA'},
{ispb: '68757681', name: 'SIMPAUL'},
{ispb: '68900810', name: 'BCO RENDIMENTO S.A.'},
{ispb: '71027866', name: 'BCO BS2 S.A.'},
{ispb: '71590442', name: 'LASTRO RDV DTVM LTDA'},
{ispb: '71677850', name: 'FRENTE CC LTDA.'},
{ispb: '73622748', name: 'B&T CC LTDA.'},
{ispb: '74828799', name: 'NOVO BCO CONTINENTAL S.A. - BM'},
{ispb: '75647891', name: 'BCO CRÉDIT AGRICOLE BR S.A.'},
{ispb: '76461557', name: 'CCR COOPAVEL'},
{ispb: '76543115', name: 'BANCO SISTEMA'},
{ispb: '76641497', name: 'DOURADA CORRETORA'},
{ispb: '78157146', name: 'CREDIALIANÇA CCR'},
{ispb: '78626983', name: 'BCO VR S.A.'},
{ispb: '78632767', name: 'BCO OURINVEST S.A.'},
{ispb: '80271455', name: 'BCO RNX S.A.'},
{ispb: '81723108', name: 'CREDICOAMO'},
{ispb: '89960090', name: 'RB INVESTIMENTOS DTVM LTDA. '},
{ispb: '90400888', name: 'BCO SANTANDER (BRASIL) S.A.'},
{ispb: '91884981', name: 'BANCO JOHN DEERE S.A.'},
{ispb: '92702067', name: 'BCO DO ESTADO DO RS S.A.'},
{ispb: '92856905', name: 'ADVANCED CC LTDA'},
{ispb: '92874270', name: 'BCO DIGIMAIS S.A.'},
{ispb: '92875780', name: 'WARREN CVMC LTDA'},
{ispb: '92894922', name: 'BANCO ORIGINAL'},
{ispb: '94968518', name: 'DECYSEO CC LTDA.'},
{ispb: '20018183', name: 'Stark Bank S.A.'}]
