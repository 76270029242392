<template>
  <div class="login-page dzs-login">
    <div class="full-page login-page section-image sb-login-page">
      <div class="container-fluid content">
        <div class="row">
          <di class="col-lg-6 d-flex align-items-center justify-content-center">
            <div class="card-login text-white">
              <div class="card-login__header">
                <img src="/static/img/login/3RBankLogo.svg"/>
              </div>
              <form class="div-login">
                <fg-input label="Usuário" data-vv-as="Usuário"
                          name="username" v-model="form.username"
                          v-validate="validations.username"
                          :error="getError('username')"></fg-input>

                <fg-input label="Senha" data-vv-as="Senha" type="password"
                          name="password" v-model="form.password"
                          v-validate="validations.password"
                          :error="getError('password')"></fg-input>

                <div class="card-login__buttons">
                  <p-button native-type="submit" round class="btn btn-outline-primary" :disabled="!this.showSubmit"
                            @click.prevent="doLogin">Entrar
                  </p-button>
                  <div class="btn btn-primary btn-outline-primary btn-round">
                    <span><a style="color: inherit;" href="/register">Abrir Conta</a></span>
                  </div>
                </div>
                <div>
                  <p><a href="/forgot" style="font-size: .9rem;">Esqueci minha senha</a></p>
                </div>
              </form>
            </div>
          </di>
          <div class="d-none d-lg-flex brand-image-wrapper col-lg-6">
            <div class="man-wrapper">
              <img class="man" src="/static/img/login/man.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="bg-wrapper">
        <img class="bg-image" src="/static/img/login/bgLogin.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import {Button, Card, Checkbox} from 'src/components/UIComponents'
import AppNavbar from './Layout/AppNavbar'
import AppFooter from './Layout/AppFooter'
import swal from 'sweetalert2'
import nprogress from 'nprogress'
import {failWs, postWs, signin} from 'src/ws.service'
import {VueReCaptcha} from 'vue-recaptcha-v3'

export default {
  components: {
    Card,
    AppNavbar,
    AppFooter,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    VueReCaptcha,
  },
  data() {
    return {
      showSubmit: true,
      validateIntervalLoop: null,
      verifCode: null,
      verif: null,
      form: {
        username: '',
        password: ''
      },
      validations: {
        username: {
          required: true,
          min: 3,
          ok: false
        },
        password: {
          required: true,
          min: 6,
          ok: false
        }
      },
    }
  },
  mounted() {
    if (window.location.href.includes('3rbank.net.br')
      && location.protocol != 'https:') {
      location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
      return
    }
    if (this.$route.query.fail == 1) {
      swal({
        title: 'Serviço temporariamente indisponível!',
        text: 'Tente novamente mais tarde.',
        buttonsStyling: true,
        timer: 5000,
        confirmButtonClass: 'btn btn-danger btn-fill',
        type: 'error'
      })
    } else if (this.$route.query.msg == 1) {
      postWs("/auth/validate-email", false,
        {hash: this.$route.query.code}, null,
        () => {
          swal({
            title: 'Sucesso!',
            text: 'E-mail confirmado! Agora é só acessar utilizando o usuário e senha cadastrados.',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'success'
          })
        }, () => {
        })
    }
    this.recaptcha()
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },
    doLogin() {
      localStorage.setItem('isAuth', false)
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          this.showSubmit = false
          this.doRecaptcha()
        }
      })
    },
    async recaptcha() {
      await this.$recaptchaLoaded()
      this.$recaptchaInstance.showBadge()
    },
    async doRecaptcha() {
      const token = await this.$recaptcha('login')
      this.onCaptchaVerified(token)
    },
    onCaptchaVerified(recaptchaToken) {
      signin(this.form.username, this.form.password, recaptchaToken, this.verif,
        (response) => this.loginSuccess(),
        this.signinFail)
    },
    signinFail(error) {
      this.showSubmit = true
      if (error.response.status === 511) {
        this.verifCode = JSON.parse(error.response.data.message)
        if (this.verifCode.totpManualCode == null || this.verifCode.totpManualCode === '') {
          this.showTotpModal()
        } else {
          require('qrcode').toDataURL(this.verifCode.totpQRCodeURI, {border: 0, width: 300}, this.showQrCode)
        }
      } else {
        let title, text, type, btnClass
        if ('CREDENTIAL' == error.response.data.message) {
          title = ''
          text = 'Usuário e/ou senha incorretos!'
          type = 'warning'
          btnClass = 'warning'
        } else if ('EMAIL_NOT_VALIDATED' == error.response.data.message) {
          title = 'E-mail não confirmado'
          text = 'Foi enviado um e-mail de confirmação para você. Para prosseguir abra o e-mail e clique no botão \'Confirmar e-mail\'.'
          type = 'warning'
          btnClass = 'waring'
        } else if ('DISABLED' == error.response.data.message) {
          title = ''
          text = 'Olá!\n' +
            'neste momento não será possível prosseguir com a solicitação de abertura de conta. \n' +
            'Você poderá tentar novamente dentro dos próximos 120 dias. Agradecemos pela Atenção!'
          type = 'warning'
          btnClass = 'danger'
        } else if ('LOCKED' == error.response.data.message) {
          title = 'Atenção'
          text = 'Usuário bloqueado! Favor entrar em contato com o nosso suporte.'
          type = 'error'
          btnClass = 'danger'
        } else if ('ACCOUNT_CANCELED' == error.response.data.message) {
          title = 'Atenção'
          text = 'Conta cancelada por solicitação do cliente. Caso tenha alguma dúvida, favor entrar em contato com o nosso suporte!'
          type = 'error'
          btnClass = 'danger'
        } else if ('FORCE_PASSWORD_RESET' == error.response.data.message) {
          title = 'Atenção'
          text = 'Devido ao tempo de inatividade de sua conta, é necessário redefinir sua senha. Foi enviado um email para redefinição!'
          type = 'warning'
          btnClass = 'warning'
        } else if ('EMAIL_REVALIDATION_REQUIRED' == error.response.data.message) {
          title = 'Confirmação de Identidade!'
          text = 'Detectamos três tentativas de login sem sucesso. Por motivos de segurança, enviamos um e-mail para você, solicitando a validação do seu acesso.'
          type = 'warning'
          btnClass = 'warning'
        } else {
          failWs(error)
          return
        }
        swal({
          title: title,
          text: text,
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-' + btnClass + ' btn-fill',
          type: type
        })
      }
    },
    showQrCode(err, url) {
      this.showTotpModal(url)
    },
    showTotpModal(qrCodeUrl) {
      let totpHtml = null;
      if (qrCodeUrl == null || qrCodeUrl == '') {
        totpHtml = '<p style="margin-bottom: 0px">Siga os passos abaixo para utilizar o segundo fator de autenticação para seu login! </p>' +
          ' <br> ' +
          '<p style="margin-bottom: 0px; text-align: left">1 - Abra o aplicativo Google Authenticator' +
          '<p style="margin-bottom: 0px; text-align: left">2 - Procure o cadastro da <span style="font-weight: bold">3RBank - B.O.</span> ou pelo nome inserido na fase de configuração manual </p>' +
          '<p style="margin-bottom: 0px; text-align: left">4 - Informe o código no campo abaixo </p>' +
          '<br>' +
          '<p style="text-align: center">Código: <input id="totpCode" style="text-align: center" maxlength="6"></p>'
      } else {
        totpHtml = '<p style="margin-bottom: 0px">Siga os passos abaixo para utilizar o segundo fator de autenticação para seu login! </p>' +
          ' <br> ' +
          '<p style="margin-bottom: 0px; text-align: left">1 - Baixe o aplicativo Google Authenticator em sua loja de aplicativos </p>' +
          '<p style="margin-bottom: 0px; text-align: left">2 - Clique no botão <span style="font-weight: bold">+</span> e após em <span style="font-weight: bold">Ler Código QR</span> </p>' +
          '<p style="margin-bottom: 0px; text-align: left">3 - Leia o QR Code abaixo </p>' +
          '<p style="margin-bottom: 0px; text-align: left">4 - Informe o código gerado no campo abaixo. </p>' +
          '<img src="' + qrCodeUrl + '"/>' +
          '<p>Código para Configuração Manual: <br> <span style="font-weight: bold">' + this.verifCode.totpManualCode + '</span></p>' +
          ' <br> ' +
          '<p style="text-align: center">Código: <input id="totpCode" style="text-align: center" maxlength="6"></p>'
      }
      swal({
        title: 'Conta com acesso administrativo!',
        html: totpHtml,
        type: 'warning',
        showConfirmButton: true,
        confirmButtonColor: '#00c390',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        timer: 5 * 60 * 1000,
        timerProgressBar: true
      }).then(() => {
        this.validateCode(document.getElementById('totpCode').value)
      }).catch(() => {
        this.showSubmit = true
      })
    },
    loginSuccess(response) {
      let redirect = false
      if (response == undefined) {
        localStorage.setItem('verifiedDevice', false)
        redirect = true
      } else if (response.data.status == 'USED') {
        localStorage.setItem('verifiedDevice', true)
        redirect = true
      }
      if (redirect) {
        localStorage.setItem('isAuth', true)
        location.reload()
        nprogress.done()
      }
    },
    getError(fieldName) {
      this.buttonColorError()
      return this.errors.first(fieldName)
    },
    buttonColorError() {
      let els = document.getElementsByName("submit")
      if (els.length > 0) {
        if (this.errors.count() == 0) {
          els[0].classList.add("btn-success")
          els[0].classList.remove("btn-danger")
        } else {
          els[0].classList.add("btn-danger")
          els[0].classList.remove("btn-success")
        }
      }
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    },
    validateCode(totpCode) {
      postWs("/verifcode/login/validate",
        true,
        null,
        {verifCode: this.verifCode.code, hashCode: totpCode},
        (response) => {
          try {
            this.verif = response.data.verifCode;
            this.doLogin()
          } catch (e) {
            console.log(e)
            throw e
          }
        },
        function (error) {
          swal({
            title: 'Aviso!',
            text: 'Código inválido!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        })
    },
  },
  beforeDestroy() {
    this.closeMenu()
  }
}
</script>
<style lang="scss">
.dzs-login {
  min-height: 100vh;
  font-family: 'Standerd', sans-serif;
  display: flex;
  align-items: center;

  .full-page .content {
    padding-top: 0;
  }

  .card-login {
    width: 100%;
    max-width: 31.5rem;

    input {
      background: #e2e2e2 !important;
      color: #333333 !important;
      font-family: inherit;
      font-size: 1rem;
      line-height: 1.5;
    }
  }

  .card-login__header {
    color: #333333;
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 1rem;

    img {
      height: 5rem;
      margin: 0 auto 1.5rem;
    }
  }

  .card-login__buttons {
    display: flex;
    align-items: center;
    gap: 1rem;

    a {
      text-decoration: none !important;
    }
  }

  .card-login__button--outlined:hover {
    background: #333333;
    color: #FFFFFF;
  }

  .brand-image-wrapper {
    min-height: 100vh;
    position: relative;
    display: flex;
    align-items: flex-end;
    padding-right: 10%;

    .man-wrapper {
      z-index: 100;
      max-height: 80vh;
      height: 100%;
      width: 100%;
    }

    .man {
      object-fit: contain;
      object-position: bottom;
      width: 100%;
      height: 100%;
    }
  }

  .bg-wrapper {
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  .bg-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 !important;
  }
}
</style>
