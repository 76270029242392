import Vue from 'vue'
import './pollyfills'
import VueRouter from 'vue-router'
import VueRouterPrefetch from 'vue-router-prefetch'
import VueNotify from 'vue-notifyjs'
import VeeValidate, {Validator} from 'vee-validate'
import locale from 'element-ui/lib/locale'
import lang from 'element-ui/lib/locale/lang/pt-br'
import langVal from 'vee-validate/dist/locale/pt_BR'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import VueTheMask from 'vue-the-mask'
import VMoney from 'v-money'
import VueBarcode from 'vue-barcode'
import ViaCep from 'vue-viacep'
import { VueReCaptcha } from 'vue-recaptcha-v3'
// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import SideBar from './components/UIComponents/SidebarPlugin'
import initProgress from './progressbar'
// router setup
import routes from './routes/routes'

// library imports
import './assets/sass/paper-dashboard.scss'
import './assets/sass/demo.scss'
import sidebarLinks from './sidebarLinks'
import './registerServiceWorker'

// plugin setup
Vue.use(VueRouter)
Vue.use(VueRouterPrefetch)
Vue.use(GlobalDirectives)
Vue.use(GlobalComponents)
Vue.use(VueNotify)
Vue.use(SideBar, {sidebarLinks: sidebarLinks})
Vue.use(VeeValidate)
Vue.use(VueAxios, axios)
Vue.use(VueTheMask)
Vue.use(VMoney, {decimal: ',', thousands: '.', precision: 2})
Vue.use(ViaCep)
Vue.use(VueReCaptcha, { siteKey: '6LeROrAmAAAAAFCEUvN2QWNQRxCLFx6LakYEqigc' , loaderOptions: {renderParameters: {badge:'bottomleft'}}})
Vue.component(VueQrcode.name, VueQrcode)
Vue.component('barcode', VueBarcode)

locale.use(lang)
Validator.localize('pt_BR', langVal)
Vue.config.productionTip = true

axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Referer,Accept,Origin,User-Agent,Content-Type'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'PUT,GET,POST,DELETE,OPTIONS'

// configure router
export const router = new VueRouter({
  mode: "history",
  routes: routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return {x: 0, y: 0}
    }
  }
})

initProgress(router);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router
})


