<template>
  <div class="card">
    <form>
      <div class="card-header">
        <h4 class="card-title">
          Cadastrar Pack
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12">
            <label>Nome</label>
            <input type="text" class="form-control" maxlength="100" v-model="pack.name" style="text-transform: uppercase"></input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h4 class="card-title">Descrição da Pack</h4>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-6">
            <label>Descrição</label>
            <textarea class="form-control" rows="5" maxlength="255" v-model="description.description"></textarea>
          </div>
          <div class="col-sm-6">
            <label>Detalhes Internos</label>
            <textarea class="form-control" rows="5" maxlength="1000" v-model="description.internalDetails"></textarea>
          </div>
        </div>
      </div>
      <div class="card-footer text-right">
        <p-button type="info" @click.prevent="savePack">Salvar</p-button>
      </div>
    </form>
  </div>
</template>
<script>
  import {callWs, failWs} from 'src/ws.service'
  import swal from 'sweetalert2'

  export default {
    components: {
    },
    data () {
      return {
        pack: {
          name: '',
        },
        description: {
          description: '',
          internalDetails: ''
        }
      }
    },
    mounted() {
      this.callAllPacks()
    },
    methods: {
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      validate () {
        this.$validator.validateAll().then(isValid => {
          this.$emit('on-submit', this.registerForm, isValid)
        })
      },
      savePack(){
        if(this.pack.name == ''){
          swal({
            title: 'Aviso!',
            text: 'O campo Nome é obrigatório!',
            buttonsStyling: true,
            timer: 5000,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        callWs("/role/insert-role-pack",
          "POST", null, true, null,
          {name: this.pack.name.toUpperCase(), idDescription: null,
            newDescription:{description: this.description.description, internalDetails: this.description.internalDetails}},
          () => {
            swal({
              title: 'Sucesso!',
              text: 'Dados salvos com sucesso.',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'success'
            })
          }, (error) => {
            this.buttonDisabled = false
            if (error.response.status == 409) {
              swal({
                title: 'Aviso!',
                text: 'Pack já cadastrada!',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
              return
            }
            swal({
              title: 'Falha!',
              text: 'Falha ao salvar a role, verifique os dados e tente novamente!',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-danger btn-fill',
              type: 'error'
            })
          })
      }
    }
  }
</script>
<style>
  .uppercase{
    text-transform: uppercase;
  }
</style>
