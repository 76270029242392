<template>
  <div style="--aspect-ratio: 16/9">
  <iframe width="1600" height="1200"
          src="https://datastudio.google.com/embed/reporting/e91e1bed-be53-49aa-8fac-00b49dd7f950/page/hCZsC"
          frameborder="0"></iframe>
  </div>
</template>

<script>
  export default {
    name: "Balances"
  }
</script>

<style lang="scss">
  [style*="--aspect-ratio"] > :first-child {
    width: 100%;
  }

  [style*="--aspect-ratio"] > img {
    height: auto;
  }

  @supports (--custom:property) {
    [style*="--aspect-ratio"] {
      position: relative;
      height: 1200px;
    }

    [style*="--aspect-ratio"]::before {
      content: "";
      display: block;
      padding-bottom: calc(100% / (var(--aspect-ratio)));
    }

    [style*="--aspect-ratio"] > :first-child {
      position: absolute;
      top: 0;
      left: 0;
      //height: 100%;
    }
  }
</style>
