<template>
  <div class="dzs-operation-limit">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Limites Globais</h4>
        </div>
        <div class="card-body row">
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 85%">
              <el-table-column :min-width="60" label="Limite">
                <template slot-scope="props">
                    <span>
                      <span>
                        <b>{{opNameToLabel(props.row.limit)}}</b>
                      </span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="25" label="P. Jurídica">
                <template slot-scope="props">
                    <span>
                      <fg-input v-model="props.row.valueLegalPerson" v-on:input="handleChangeValue(props)" v-on:change="handleChangeValue(props)"/>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="25" label="P. Física">
                <template slot-scope="props">
                    <span>
                      <fg-input v-model="props.row.valueNaturalPerson" v-on:input="handleChangeValue(props)" v-on:change="handleChangeValue(props)"/>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="30" label="Tipo Op.">
                <template slot-scope="props">
                    <span>
                      <span>{{opStatusToLabel(props.row.operationNature)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :width="120" fixed="right" class-name="td-actions" label="Ação">
                <template slot-scope="props" >
                  <span v-if="getEditedState(props.$index)">
                    <p-button type="danger" size="sm" icon title="Salvar" @click="save(props)">
                      <i class="fas fa-edit"></i>
                    </p-button>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">*APÓS ALTERAR ALGUM VALOR, O ÍCONE DE SALVAR SE TORNARÁ DISPONÍVEL.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination
    },
    data() {
      return {
        tableData: [],
        editStatus: []
      }
    },
    mounted() {
      this.loadData()
    },
    methods: {
      getEditedState(index){
        return this.editStatus[index].edited
      },
      handleChangeValue(props){
          this.editStatus[props.$index].edited = true;
      },
      loadData() {
        postWs("/op-limit/list-global-op-limits",
          true, null,
          null,
          this.successLoadData,
          failWs);
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.editStatus = this.tableData.map(row => {return { edited: false }})
      },
      save(operationLimitScope) {

        swal({
          title: 'Alterar Limite',
          text: "Você deseja realmente salvar as alterações para o limite " + operationLimitScope.row.limit + "?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'sim',
          confirmButtonColor: '#00c390',
          cancelButtonText: 'CANCELAR',
          cancelButtonColor: '#ef8157'
        }).then(() => {
          postWs("/op-limit/set-op-limit", true,
            null, {
              limit: operationLimitScope.row.limit,
              naturalPersonValue: operationLimitScope.row.valueNaturalPerson,
              legalPersonValue: operationLimitScope.row.valueLegalPerson
            },
            (response) => {
              this.editStatus[operationLimitScope.$index].edited = false
            }, failWs)
        })
      },
      opStatusToLabel(opType) {
        if (opType === 'SOLICITATION') {
          return 'SOLICITAÇÃO'
        } else if (opType === 'OUT') {
          return 'SAÍDA'
        } else if (opType === 'IN') {
          return 'ENTRADA'
        }
        return '??'
      },
      opNameToLabel(limit) {
        if (limit == 'BOLETO_MAX_UNPAID_DUEDATE') {
          return 'Quantidade de boletos não pagos:'
        } else if (limit == 'BOLETO_MAX_VALUE') {
          return 'Valor máximo por boleto (R$):'
        } else if (limit == 'BOLETO_MAX_VALUE_MONTHLY') {
          return 'Valor máximo de boleto mês (R$):'
        } else if (limit == 'BOLETO_MIN_VALUE') {
          return 'Valor mínimo por boleto (R$):'
        } else if (limit == 'BOLETO_MONTHLY_FREE') {
          return 'Quantidade de boletos gratuitos por mês:'
        } else if (limit == 'PIX_FEE') {
          return 'Taxa de PIX (%):'
        } else if (limit == 'PIX_PAYMENT_MAX_VALUE') {
          return 'Valor máximo de envio por PIX (R$):'
        } else if (limit == 'PIX_PAYMENT_MAX_VALUE_DAILY') {
          return 'Valor máximo de envio de PIX por dia (R$):'
        } else if (limit == 'PIX_PAYMENT_MONTHLY_FREE') {
          return 'Quantidade de envio de PIX gratuitos no mês: '
        } else if (limit == 'TED_MAX_VALUE') {
          return 'Valor máximo por TED (R$):'
        } else if (limit == 'TED_MAX_VALUE_DAILY') {
          return 'Valor máximo de TED por dia (R$):'
        } else if (limit == 'TED_MAX_VALUE_MONTHLY') {
          return 'Valor máximo de TED por mês (R$):'
        } else if (limit == 'BOLETO_PAYMENT_MAX_VALUE') {
          return 'Valor máximo de pagamento de um boleto (R$):'
        } else if (limit == 'BOLETO_PAYMENT_MAX_VALUE_DAILY') {
          return 'Valor máximo de pag. de boleto por dia (R$):'
        } else if (limit == 'CREDIT_CARD_VIRTUAL_MAX_ITEMS') {
          return 'Quantidade máxima de cartões virtuais para emissão:'
        } else if (limit == 'CREDIT_CARD_PHYSICAL_MAX_ITEMS') {
          return 'Quantidade máxima de cartões físicos para emissão:'
        } else if (limit == 'CREDIT_CARD_RECHARGE_MAX_DAILY') {
          return 'Valor máximo de recarga de cartão por dia (R$):'
        } else if (limit == 'CREDIT_CARD_RECHARGE_MAX_MONTHLY') {
          return 'Valor máximo de recarga de cartão por mês (R$):'
        } else if (limit == 'CREDIT_TED_MAX_VALUE') {
          return 'Valor máximo para crédito via TED (R$):'
        } else if (limit == 'CREDIT_TED_MIN_VALUE') {
          return 'Valor mínimo para crédito via TED (R$):'
        } else if (limit == 'CREDIT_TED_MAX_REQUEST_DAILY') {
          return 'Máximo pedidos diários de crédito via TED:'
        } else if (limit == 'BTC_BUY_MAX_VALUE_DAILY') {
          return 'Quantidade máxima de compras de  BTC:'
        } else if (limit == 'PAYMENT_LINK_MAX_VALUE') {
          return 'Valor máximo de um link de pagamento:'
        } else if (limit == 'BOLETO_PAYMENT_MAX_DAILY') {
          return 'Valor máximo de pagamento de boletos por dia:'
        } else if (limit == 'BOLETO_PAYMENT_MAX_MONTHLY') {
          return 'Valor máximo de pagamento de boletos por mês:'
        } else if (limit == 'PIX_IN_GATEWAY') {
          return 'Valor máximo de pix IN pelo Gateway:'
        } else if (limit == 'PIX_IN_BANKING') {
          return 'Valor máximo de pix IN pelo Banking:'
        } else {
          return limit
        }
      },
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }
</style>
