<template>
  <div class="card card-stats">
    <div class="card-body">
      <div class="row">
        <div :class="headerRowClass == null ? 'col-5 col-md-4' : headerRowClass">
          <slot name="header">
            <div class="icon-big text-center"
                 :class="`text-${type}`">
              <i :class="icon"></i>
            </div>
          </slot>
        </div>
        <div :class="contentRowClass == null ? 'col-7 col-md-8' : contentRowClass">
          <slot name="content">
            <div class="numbers">
              <p :class="'card-category ' + smallTitleClass">{{smallTitle}}</p>
              <p :class="'card-title ' + titleClass">{{title}}</p>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <hr/>
      <slot name="footer"></slot>
    </div>

  </div>
</template>
<script>
  export default {
    name: 'stats-card',
    props:{
      type: {
        type: String,
        description: 'Card text type (primary|info|danger|default|warning|success)'
      },
      title: {
        type: String,
        description: 'Card title'
      },
      smallTitle: {
        type: String,
        description: 'Card small title'
      },
      icon: {
        type: String,
        description: 'Card icon'
      },
      smallTitleClass: {
        type: String,
        description: 'Card small title class',
        default: ''
      },
      titleClass: {
        type: String,
        description: 'Card title class',
        default: ''
      },
      headerRowClass: {
        type: String,
        description: 'Card header row class',
        default: null
      },
      contentRowClass: {
        type: String,
        description: 'Card content row class',
        default: null
      }
    }
  }

</script>
<style scoped>
  .card-content{
    min-height: 85px
  }
</style>
