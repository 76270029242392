<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Gerenciar Máquinas</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-3">
            <label class="control-label">Serial da máquina</label>
            <fg-input v-model="search.physicalId"/>
          </div>
          <div class="col-sm-2">
            <fg-input label="Status">
              <el-select
                class="select-default"
                v-model="search.status"
                placeholder="Status">
                <el-option
                  class="select-default"
                  v-for="item in tableStatus"
                  :key="item"
                  :label="statusTranslate(item)"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-4">
            <div style="float: left; width: 78%">
              <label class="control-label">Modelo</label>
              <fg-input v-model="search.modelName"
                        style="width: 100%"
                        name="Modelo">
                <template slot="addonRight"><i class="fas fa-check" style="color: #156800" v-show="modelCheck"></i>
                  <i class="fas fa-times" style="color: #156800" v-show="modelNotFound"></i></template>
              </fg-input>
            </div>
            <p-button type="primary" style="margin-top: 25px; float: left" @click="searchDataModel">
              <i slot="label" class="fas fa-search"></i>
            </p-button>
          </div>
          <div class="col-sm-1">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Por Página">
                <el-option
                  class="select-default"
                  v-for="item in [5,10,25,50]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px"
                      @click="find">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-sm-12">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="80" label="ID">
                <template slot-scope="props">
                    <span>
                      <span><b>#{{props.row.physicalId}}</b></span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Modelo">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.model.name}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="120" label="Adquirente">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.acquirerName}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="User">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.ownerUsername}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Status">
                <template slot-scope="props">
                    <span>
                      <span>{{statusTranslate(props.row.status)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :width="70" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="success" size="sm" icon title="Vincular usuário" style="margin-left: 5px"
                            @click="activeListUser(props.row.physicalId)" v-show="!notNullOrEmpty(props.row.ownerUsername)">
                    <i class="fas fa-user-plus"></i>
                  </p-button>
                  <p-button type="danger" size="sm" icon title="Excluir usuário"
                            @click="deleteUser(props.row.physicalId)" v-show="notNullOrEmpty(props.row.ownerUsername)">
                    <i class="fas fa-user-slash"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-show="listUsers.enabled">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Vincular Usuário ao Terminal #{{listUsers.terminalId}}</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-3">
            <label class="control-label">Usuário</label>
            <fg-input v-model="listUsers.search.key"/>
          </div>
          <div class="col-sm-2">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="listUsers.pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in [5,10,25,50]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px;"
                      @click="loadDataListUsers">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="listUsers.tableData"
                      style="width: 100%">
              <el-table-column :min-width="60" label="Usuário">
                <template slot-scope="props">
                    <span>
                      <span><b>@{{props.row.username}}</b></span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="100" label="Nome">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.name}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Criação">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.createDate}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="28" label="Qtd">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.amountCardTerminal}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Mod. Maq">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.nameModelCardTerminal}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :width="80" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="success" size="sm" icon title="Vincular"
                            @click="insertUserTerminal(props.row.username)">
                    <i class="fas fa-plus"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{listUsers.pagination.fromNumber}} AO {{listUsers.pagination.toNumber}}, DE
              {{listUsers.pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="listUsers.pagination.currentPage"
                          :per-page="listUsers.pagination.perPage"
                          :total="listUsers.pagination.totalNumber"
                          :click="this.loadDataListUsers">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {callWs, failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {toMoney, moneyToFloat, notNullOrEmpty} from "../../../../../util/core.utils";

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination
    },
    data() {
      return {
        search: {
          physicalId: '',
          modelId: '',
          modelName: '',
          status: null
        },
        modelCheck: false,
        modelNotFound: false,
        tableStatus: [null, 'STORED','LEASED','SOLD','DEFECTIVE','WARRANTY','REPAIR','REFURBISHED','DISPOSED','RETURNED','OTHER'],
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        listUsers: {
          enabled: false,
          terminalId: '',
          tableData: [],
          pagination: {
            perPage: 5,
            currentPage: 1,
            pageMaxNumber: 1,
            fromNumber: 0,
            toNumber: 0,
            totalNumber: 0
          },
          search: {
            key: '',
            requestStatus: 'ACTIVE',
            dateOrder: 'ASC'
          },
        }
      }
    },
    created() {
      this.$validator.extend('leaseValueValid', {
        getMessage: field => (field + ' informada é inválida, informe um valor maior que Zero.'),
        validate: (value) => {
          if (moneyToFloat(value) == 0) {
            return false
          } else {
            return true
          }
        }
      })

      this.$validator.extend('purchaseValueValid', {
        getMessage: field => (field + ' informada é inválida, informe um valor maior que Zero.'),
        validate: (value) => {
          if (moneyToFloat(value) == 0) {
            return false
          } else {
            return true
          }
        }
      })

      this.loadData()
    },
    methods: {
      notNullOrEmpty,
      toMoney,
      find() {
        this.pagination.currentPage = 1
        this.loadData()
      },
      loadData() {
        postWs("/bo/card-terminal/list-card-terminal",
          true, null,
          {
            physicalId: this.search.physicalId,
            modelId: this.search.modelId,
            status: this.search.status,
            pageNumber: this.pagination.currentPage - 1,
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          failWs)
        return this.pagination.currentPage
      },
      successLoadData(response) {
        if(this.search.modelId != ''){
          this.search.modelId = ''
          this.modelCheck = false
        }
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      statusTranslate(status){
        let retorno = ''
        switch (status) {
          case 'STORED':
            retorno = 'Estoque';
            break;
          case 'LEASED':
            retorno = 'Locado';
            break;
          case 'SOLD':
            retorno = 'Vendido';
            break;
          case 'DEFECTIVE':
            retorno = 'Defeito';
            break;
          case 'WARRANTY':
            retorno = 'Garantia';
            break;
          case 'REPAIR':
            retorno = 'Manutenção';
            break;
          case 'REFURBISHED':
            retorno = 'Reparada';
            break;
          case 'DISPOSED':
            retorno = 'Descartada';
            break;
          case 'RETURNED':
            retorno = 'Retornou';
            break;
          case 'OTHER':
            retorno = 'Outro';
            break;
          default:
            retorno = 'Todos';
        }
        return retorno
      },
      searchDataModel() {
        postWs("/bo/card-terminal/search-card-terminal-model",
          true, {name: this.search.modelName},
          null, this.successSearchModel,
          () => {
            swal({
              title: 'Aviso!',
              text: 'Nenhum modelo encontrado com esse nome!',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            this.modelNotFound = true
            this.modelCheck = false
          })
      },
      successSearchModel(response) {
        this.tableDataModels = response.data
        this.search.modelId = this.tableDataModels.id
        this.modelNotFound = false
        this.modelCheck = true
      },
      deleteUser(physicalId) {
        swal({
          text: "Tem certerza que deseja excluir o usuário do terminal " + physicalId + "?",
          type: 'warning',
          buttonsStyling: true,
          confirmButtonClass: 'btn-success btn-fill',
          showCancelButton: true,
          confirmButtonColor: '#001a3f',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Excluir',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result) {
            this.callDeleteUser(physicalId)
          }
        })
      },
      callDeleteUser(physicalId) {
        postWs("/bo/card-terminal/delete-user-terminal",
          true, {physicalId: physicalId},
          null,
          () => {
            swal({
              title: 'Sucesso!',
              text: 'Usuário deletado com sucesso!',
              buttonsStyling: true,
              timer: 10000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'success'
            })
            this.loadData()
          },
          () => {
            swal({
              title: 'Erro!',
              text: 'Erro ao deletar usuário!',
              buttonsStyling: true,
              timer: 10000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          })
      },
      loadDataListUsers() {
        postWs("/bo/card-terminal/list",
          true, null,
          {
            key: this.listUsers.search.key,
            status: this.listUsers.search.requestStatus,
            dateOrder: this.listUsers.search.dateOrder,
            pageNumber: (this.listUsers.pagination.currentPage - 1),
            pageSize: this.listUsers.pagination.perPage
          }, this.successLoadDataListUsers,
          failWs)
        return this.pagination.currentPage
      },
      successLoadDataListUsers(response) {
        this.listUsers.tableData = response.data.requests
        this.listUsers.pagination.totalNumber = response.data.totalNumber
        this.listUsers.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.listUsers.pagination.totalNumber > 0) {
          this.listUsers.pagination.fromNumber = ((this.listUsers.pagination.perPage * (this.listUsers.pagination.currentPage - 1)) + 1)
          this.listUsers.pagination.toNumber = ((this.listUsers.pagination.fromNumber + this.listUsers.tableData.length) - 1)
        } else {
          this.listUsers.pagination.fromNumber = 0
          this.listUsers.pagination.toNumber = 0
        }
      },
      activeListUser(terminalId) {
        this.listUsers.terminalId = terminalId
        this.listUsers.enabled = true
        this.loadDataListUsers()
      },
      insertUserTerminal(username) {
        swal({
          text: "Tem certeza que deseja vincular o usuário @" + username + " ao terminal " + this.listUsers.terminalId + "?",
          type: 'warning',
          buttonsStyling: true,
          confirmButtonClass: 'btn-success btn-fill',
          showCancelButton: true,
          confirmButtonColor: '#001a3f',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Vincular',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result) {
            this.callInsertUser(username)
          }
        })
      },
      callInsertUser(username){
        postWs("/bo/card-terminal/insert-user-terminal",
          true, {physicalId: this.listUsers.terminalId, username: username},
          null,
          () => {
            swal({
              title: 'Sucesso!',
              text: 'Usuário vinculado com sucesso!',
              buttonsStyling: true,
              timer: 10000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'success'
            })
            this.loadData()
            this.listUsers.enabled = false
            this.listUsers.terminalId = ''
          },
          () => {
            swal({
              title: 'Erro!',
              text: 'Erro ao vincular usuário!',
              buttonsStyling: true,
              timer: 10000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          })
      },
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 5px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .input-group-text {
      padding: 5px 5px 5px 5px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }

  .clickable-rows {
    tbody tr td {
      cursor: pointer;
    }

    .el-table__expanded-cell {
      cursor: default;
    }
  }

  .btn.btn-primary{
    margin-right: 10px;
  }
</style>
