<template>
  <div class="row" style="text-align: center">
    <div class="col-md-12">
      <label class="control-label">Destino</label>
      <p class="form-control-static"><b>{{transfer.destinationAccountOwnerName}}</b></p>
    </div>
    <div class="col-md-12">
      <label class="control-label">CPF/CNPJ</label>
      <p class="form-control-static">{{transfer.destinationAccountOwnerSocialNumber}}</p>
    </div>
    <div class="col-md-12">
      <label class="control-label">Banco</label>
      <p class="form-control-static">({{transfer.destinationBankIspb}})</p>
    </div>
<!--    <div class="col-md-12">-->
<!--      <label class="control-label">Agencia/Conta</label>-->
<!--      <p class="form-control-static">-->
<!--        {{transfer.destinationAgency}}/{{transfer.destinationAccountNumber}}</p>-->
<!--    </div>-->
    <div class="col-md-12">
      <label class="control-label">Valor</label>
      <p class="form-control-static">R$ {{toMoney(transfer.value)}}</p>
    </div>
    <div class="col-md-12">
      <label class="control-label">Descrição</label>
      <p class="form-control-static">{{transfer.userDescription}}</p>
    </div>
  </div>
</template>
<script>
  import {toMoney} from "src/util/core.utils"
  export default {
    name: 'pix-transfer-details',
    props: {
      transfer: {}
    },
    methods: {
      toMoney
    }
  }
</script>
