<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Buscar Conta</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-3">
            <label class="control-label">Usuário / E-Mail / CPF</label>
            <fg-input v-model="search.key"/>
          </div>
          <div class="col-md-2" v-if="hasAnyAuthorization(['BO_UPDATE_DOCUMENT_STATUS'])">
            <fg-input label="Documentação Status">
              <el-select size="large"
                         placeholder="Selecionar"
                         class="select-default"
                         v-model="search.documentStatus">
                <el-option :value="null"
                           class="select-default"
                           label="Todos"/>
                <el-option value="REJECTED_AT_PRE_APPROVAL"
                           class="select-default"
                           label="Rejeitado Pré Aprovação (RP)"/>
                <el-option value="PENDING_DOCUMENT"
                           class="select-default"
                           label="Pendente (PE)"/>
                <el-option value="PENDING_VALIDATION"
                           class="select-default"
                           label="A Validar (AV)"/>
                <el-option value="APPROVED"
                           class="select-default"
                           label="Validado (OK)"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2" v-else>
            <fg-input label="Documentação Status">
              <el-select size="large"
                         placeholder="Selecionar"
                         class="select-default"
                         v-model="search.documentStatus">
                <el-option value="PENDING_DOCUMENT"
                           class="select-default"
                           label="Pendente (PE)"/>
                <el-option value="PENDING_VALIDATION"
                           class="select-default"
                           label="A Validar (AV)"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-2">
            <fg-input label="Pré Aprovado">
              <el-select size="large" placeholder="Selecionar" class="select-default" v-model="search.preApproved">
                <el-option :value="null" class="select-default" label="Todos"/>
                <el-option value="WAITING_PRE_APPROVAL" class="select-default" label="Pendentes"/>
                <el-option value="PRE_APPROVED" class="select-default" label="Pré Aprovados"/>
                <el-option value="REJECTED" class="select-default" label="Recusados"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <fg-input label="Ordenação Data">
              <el-select size="large"
                         placeholder="Selecionar"
                         class="select-default"
                         v-model="search.dateOrder">
                <el-option value="ASC"
                           class="select-default"
                           label="Crescente"/>
                <el-option value="DESC"
                           class="select-default"
                           label="Decrescente"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-1">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in [5,10,25,50]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px;"
                      @click="find">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="60" label="Usuário">
                <template slot-scope="props">
                    <span>
                      <span><b>@{{props.row.username}}</b></span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="E-mail">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.email}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="100" label="Nome">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.name}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="24" label="Doc">
                <template slot-scope="props">
                    <span>
                      <span>{{docStatusToLabel(props.row.documentStatus)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Criação">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.createdDate}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="30" label="Pré Apro.">
                <template slot-scope="props">
                    <span>
                      <i class="fas fa-circle" :style="'color: #'+(props.row.preApproved == null ? 'dfdfdf' : props.row.preApproved ? '6bd098' : 'ef8157')"></i>
                      <span> {{props.row.preApproved == null ? 'Pend.' : props.row.preApproved ? 'Sim' : 'Não'}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="30" label="Disp.">
                <template slot-scope="props">
                    <span>
                      <i class="fas fa-circle" :style="'color: #'+(props.row.hasValidDevice?'6bd098':'ef8157')"></i>
                      <span> {{props.row.hasValidDevice?'Sim':'Não'}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="30" label="Ativo">
                <template slot-scope="props">
                    <span>
                      <i class="fas fa-circle" :style="'color: #'+(props.row.enabled?'6bd098':'ef8157')"></i>
                      <span> {{props.row.enabled?'Sim':'Não'}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="30" label="Desb.">
                <template slot-scope="props">
                    <span>
                      <i class="fas fa-circle" :style="'color: #'+(props.row.nonlocked?'6bd098':'ef8157')"></i>
                      <span> {{props.row.nonlocked?'Sim':'Não'}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :width="240" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="info" size="sm" icon title="Editar" v-if="hasAnyAuthorization(['BO_UPDATE_DOCUMENT_STATUS'])"
                            @click="edit(props.row.username)">
                    <i class="fas fa-edit"></i>
                  </p-button>
                  <p-button type="danger" size="sm" icon title="Excluir Dispositivo" v-if="hasAnyAuthorization(['BO_REMOVE_DEVICE'])"
                            @click="removeDevice(props.row)">
                    <i class="fas fa-mobile-alt"></i>
                  </p-button>
                  <p-button type="warning" size="sm" icon title="Ativar/Desativar" v-if="hasAnyAuthorization(['BO_UPDATE_AUTH_ENABLED'])"
                            @click="enableOrDisable(props.row)">
                    <i class="fas fa-check-circle"></i>
                  </p-button>
                  <p-button type="warning" size="sm" icon title="Bloquear/Desbloquear" v-if="hasAnyAuthorization(['BO_UPDATE_AUTH_NONLOCKED'])"
                            @click="lockOrUnlock(props.row)">
                    <i class="fas fa-unlock"></i>
                  </p-button>
                  <p-button type="danger" v-if="hasAnyAuthorization(['BO_SET_USER_OP_LIMIT','BO_DELETE_USER_OP_LIMIT']) && hasAuthorization('BO_LIST_USER_CUSTOM_OP_LIMITS')" size="sm" icon title="Alterar Limites Individuais" @click="setUserOperationLimits(props.row.username)">
                    <i class="fas fa-asterisk"></i>
                  </p-button>
                  <p-button type="default" size="sm" icon title="Comercial" v-if="(props.row.documentStatus != null && props.row.preApproved === null && hasAnyAuthorization(['BO_COMMERCIAL_EDIT_CLIENT']))
                                                                                    || (props.row.documentStatus != null && (hasAnyAuthorization(['BO_COMMERCIAL_SALES_PERSON']) && hasAnyAuthorization(['BO_UPDATE_DOCUMENT_STATUS'])))"
                            @click="salesClientEdit(props.row.username)">
                    <i class="fas fa-person-circle-check"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
    <modal :show.sync="showModalAdicionalDescription">
      <h6 style="text-align: center"><i style="color: red !important;" class="fas fa-ban text-primary"></i></h6>
      <h6 style="text-align: center"><b>Descrição de Bloqueio</b></h6>
      <hr>
      <span>
        <card>
          <p v-if="showNotNullFields"> <span style="font-weight: lighter; color: red !important; font-size: small" class="text-muted">*obrigatório*</span></p>
          <p> <i class="fa-solid fa-envelope text-primary"></i> <span style="font-weight: bold"  class="text-muted"> Descrição: </span></p>
          <fg-input>
                  <el-select class="select-primary"
                             size="large"
                             placeholder="Selecione uma descrição"
                             v-model="statusDescription">
                    <el-option v-for="option in descriptions.list"
                               class="select-danger"
                               :value="option.status"
                               :label="option.description"
                               :key="option.status">
                    </el-option>
                  </el-select>
                </fg-input>
          <span>
            <p v-if="showNotNullFields"> <span style="font-weight: lighter; color: orange !important; font-size: small" class="text-muted">*obrigatório quando Descrição for "Outros"*</span></p>
            <p><i class="fa-solid fa-plus text-primary"></i> <span class="text-muted"> Informações adicionais: </span></p>
            <textarea v-model="description" class="form-control"  rows="3"></textarea>
          </span>
        </card>
      </span>
      <div style="text-align: center">
        <p-button type="danger" @click="saveNewBlockedHistory">
          <i slot="label" class="nc-icon nc-simple-remove"></i>
          Bloquear
        </p-button>
      </div>
    </modal>
  </div>
</template>
<script>
import Vue from 'vue'
import {Option, Select, Table, TableColumn} from 'element-ui'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import {failWs, getWs, postWs} from "src/ws.service"
import swal from 'sweetalert2'
import {isAuthorized, isSomeAuthorization} from "src/util/role.utils"
import Modal from "@/components/UIComponents/Modal.vue";
import checkbox from "@/components/UIComponents/Inputs/Checkbox.vue";

Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    computed: {
      checkbox() {
        return checkbox
      }
    },
    components: {
      Modal,
      PPagination
    },
    data() {
      return {
        search: {
          key: '',
          documentStatus: 'PENDING_VALIDATION',
          dateOrder: 'ASC',
          preApproved: null
        },
        tableData: [],
        descriptions: [],
        statusDescription: null,
        description: null,
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        showModalAdicionalDescription: false,
        showNotNullFields: false,
        account: null,
      }
    },
    watch:{
      '$route': 'resetDefaultValues'
    },

    mounted() {
      if(!this.hasAnyAuthorization(['BO_UPDATE_DOCUMENT_STATUS'])){
        this.search.documentStatus = "PENDING_VALIDATION"
      }
      this.loadDescriptionList()
      this.loadData()
    },
    methods: {
      resetDefaultValues(){
        this.search.key = ''
        this.search.documentStatus = 'PENDING_VALIDATION',
        this.search.dateOrder = 'ASC'
        this.pagination.currentPage = 1
        this.pagination.perPage = 5
        this.pagination.pageMaxNumber = 1
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
        this.pagination.totalNumber = 0
        this.tableData = [];
        this.descriptions = []
        this.loadDescriptionList()
        this.loadData()
      },
      find() {
        this.pagination.currentPage = 1
        this.loadDescriptionList()
        this.loadData()
      },
      loadData() {
        postWs("/bo/acc/list-account",
          true, null,
          {
              key: this.search.key,
              documentStatus: this.search.documentStatus,
              dateOrder: this.search.dateOrder,
              preApproved: this.search.preApproved,
              pageNumber: (this.pagination.currentPage - 1),
              pageSize: this.pagination.perPage
          }, this.successLoadData,
          failWs)
        return this.pagination.currentPage
      },
      loadDescriptionList(){
        if(this.hasAnyAuthorization(["BO_UPDATE_AUTH_NONLOCKED"])){
          getWs("/bo/auth/blocked-description-list",
            true, null,
            this.successLoadDescriptionList,
            (error) => console.log("Falha ao carregar lista de descrições de bloqueio"))
        }
      },
      successLoadDescriptionList(response) {
        this.descriptions = response.data
      },
      successLoadData(response) {
        this.tableData = response.data.accounts
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      salesClientEdit(username){
        this.openWindow('/bo/commercial/clientDetail/' + username)
      },
      edit(username) {
        this.openWindow('/bo/account/edit/' + username)
      },
      restoreDefaultValuesBlockedUser(){
        this.statusDescription = null
        this.description = null
      },
      saveNewBlockedHistory(){
       if(this.account !== null && this.statusDescription !== null && this.statusDescription !== "OUTROS"){
          this.callNonlockedWs(this.account, false , this.statusDescription, this.description)
         this.showModalAdicionalDescription = false
         this.restoreDefaultValuesBlockedUser()
       }else if(this.description !== null && this.statusDescription === "OUTROS") {
          this.callNonlockedWs(this.account, false , this.statusDescription, this.description)
         this.showModalAdicionalDescription = false
         this.restoreDefaultValuesBlockedUser()
       }else{
         swal({
           type: "error",
           title: "Os campos obrigatórios devem ser preenchidos"
         })
         this.showNotNullFields = true
       }
      },
      removeDevice(account) {
        if (!account.hasValidDevice) {
          swal({
            title: 'Alerta!',
            text: 'Esta conta não possui dispositivo autorizado.',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        swal({
          title: 'Remover Dispositivo',
          text: "Você deseja realmente remover todos dispositivos dessa conta?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'sim',
          confirmButtonColor: '#00c390',
          cancelButtonText: 'CANCELAR',
          cancelButtonColor: '#ef8157'
        }).then(() => {
          this.callRemoveDeviceWs(account)
        })
      },
      callRemoveDeviceWs(account) {
        postWs("/bo/device/remove-device", true,
          {username: account.username}, null,
          () => {
            account.hasValidDevice = false
          }, failWs)
      },
      enableOrDisable(account) {
        swal({
          title: 'Ativar / Desativar',
          text: "Você deseja ativar ou desativar a conta?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'ativar',
          confirmButtonColor: '#00c390',
          cancelButtonText: 'DESATIVAR',
          cancelButtonColor: '#ef8157'
        }).then(() => {
          this.callEnabledWs(account, true)
        }, (dismiss) => {
          if (dismiss === 'cancel') {
            this.callEnabledWs(account, false)
          }
        })
      },
      callEnabledWs(account, status) {
        postWs("/bo/auth/update-enabled",
          true, null,
          {username: account.username, status: status},
          function () {
            account.enabled = status
          }, failWs)
      },
      lockOrUnlock(account) {
        this.account = null
        this.description = null
        swal({
          title: 'Bloquear / Desbloquear',
          text: 'Você deseja bloquear ou desbloquear a conta?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'desbloquear',
          confirmButtonColor: '#00c390',
          cancelButtonText: 'BLOQUEAR',
          cancelButtonColor: '#ef8157',
        }).then((result) => {
          if(result) {
            this.callNonlockedWs(account, true)
          }
        }, (dismiss) => {
          if (dismiss === 'cancel') {
            this.showNotNullFields = false
            this.showModalAdicionalDescription = true
            this.account = account
          }
        })
      },
      callNonlockedWs(account, status, statusDescription, description) {
        postWs("/bo/auth/update-nonlocked",
          true, null,
          {username: account.username, status: status},
          function () {
            account.nonlocked = status
          }, failWs)
        if(statusDescription){
          postWs("/bo/auth/new-history-blocked-user" , true, null,
            {username: account.username, status: statusDescription, description: description},
            function () {}
            ,failWs)
          }
      },
      setUserOperationLimits(username) {
        this.openWindow('/bo/user-operation-limit/edit/' + username)
      },
      docStatusToLabel(documentStatus) {
        if (documentStatus == null) {
          return 'PE*'
        } else if (documentStatus == 'PENDING_DOCUMENT') {
          return 'PE'
        } else if (documentStatus == 'PENDING_VALIDATION') {
          return 'AV'
        } else if (documentStatus == 'APPROVED') {
          return 'OK'
        } else if (documentStatus == 'REJECTED_AT_PRE_APPROVAL') {
          return 'RP'
        }
        return '??'
      },
      openWindow(url) {
        try {
          window.open(url)
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do conteúdo, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      hasAuthorization(role){
        return isAuthorized(role);
      },
      hasAnyAuthorization(roles){
        return isSomeAuthorization(roles);
      }
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }
</style>

<style lang="scss" scoped>
  .dzs-acc-search .el-table .td-actions button.btn {
    margin-right: 0px;
  }
</style>
